import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

  return Yup.object({
    name: Yup.string(),
    active: Yup.string(),
  })
}

export default makeValidationSchema
