import {
  components,
  useTranslation
} from 'cng-web-lib'

import UserSubscribedUserGroupFormProperties from './UserSubscribedUserGroupFormProperties'
import UserSubscribedUserGroupApiUrls from 'src/apiUrls/UserSubscribedUserGroupApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'


const {
  table: {
    CngServerModeDialogFormTable,
    useFetchCustomLookup,
  }
} = components


function UserSubscribedUserGroupCrudTable({
  showNotification,
  userForUserGroupId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.USER_FOR_USER_GROUP])

  const fetchCustomLookup = useFetchCustomLookup();

  const translatedTextsObject = makeTranslatedTextsObject()

  let create = { url: UserSubscribedUserGroupApiUrls.POST }
  let update = { url: UserSubscribedUserGroupApiUrls.PUT }
  let del = { url: UserSubscribedUserGroupApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let userGroupMasterId = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_MASTER_ID
    )
    let orgCode = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.ORG_CODE
    )
    let userGroupCode = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_CODE
    )
    let userGroupName = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_NAME
    )
    let companyType = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.COMPANY_TYPE
    )

    return {
      userGroupMasterId,
      orgCode,
      userGroupCode,
      userGroupName,
      companyType
    }
  }

  const columns = [
    {
      field: 'userGroupMasterId',
      title: translatedTextsObject.userGroupMasterId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/get`,
          { },
          'content',
          'customerGroupName',
          'id',
          (error) => console.error(error)
        )
      },
    },
    /*{
      field: 'orgCode',
      title: translatedTextsObject.orgCode,
    },
    {
      field: 'userGroupCode',
      title: translatedTextsObject.userGroupCode,
    },
    {
      field: 'userGroupName',
      title: translatedTextsObject.userGroupName,
    },
    {
      field: 'companyType',
      title: translatedTextsObject.companyType,
    }*/
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...UserSubscribedUserGroupFormProperties,
        formikProps: {
          ...UserSubscribedUserGroupFormProperties.formikProps,
          initialValues: {
            ...UserSubscribedUserGroupFormProperties.formikProps.initialValues,
            userForUserGroupId: userForUserGroupId
          }
        },
      }}
      toClientDataFormat={(serverData) => {
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum;
      }}
      fetch={{
        url: UserSubscribedUserGroupApiUrls.GET,
        body: {
          userForUserGroupId: userForUserGroupId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default UserSubscribedUserGroupCrudTable
