import CompanyForShipperRequestFormProperties from './CompanyForShipperRequestFormProperties'
import CompanyForShipperRequestApiUrls from 'src/apiUrls/CompanyForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function CompanyForShipperRequestViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("CompanyForShipperRequest View Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <CompanyForShipperRequestFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={CompanyForShipperRequestFormProperties.formikProps}
      toClientDataFormat={CompanyForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyForShipperRequestFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: CompanyForShipperRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default CompanyForShipperRequestViewForm
