import { CircularProgress } from '@material-ui/core'
import React from 'react'

const Loading = ({ loading, children }) => {
  return (
    <div className={'vs-noresult'} style={{ position: 'relative' }}>
      {loading && (
        <div className={'cfc-loading'}>
          <CircularProgress />
        </div>
      )}

      {children}
    </div>
  )
}

export default Loading
