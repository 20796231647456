import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react';

const {
    CngGridItem,
    form: { field: { CngCodeMasterAutocompleteField } },
} = components

const SpecialisationsComponent = ({ fieldsTranslatedTextObject, disabled }) => {
    return (
        <CngGridItem xs={12}><Card>
            <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.companySpecialisationsHeader}</Typography>} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={12}>
                        <CngCodeMasterAutocompleteField name='presentInIndustryList'
                            label={fieldsTranslatedTextObject.specialisedIndustries}
                            disabled={disabled}
                            codeType='CAL_INDUSTRY'
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngCodeMasterAutocompleteField name='presentInServiceList'
                            label={fieldsTranslatedTextObject.specialisedServices}
                            disabled={disabled}
                            codeType='CAL_SERVICE'
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>
                </Grid></CardContent>
        </Card></CngGridItem>
    );
}

export default SpecialisationsComponent
