import { Radio } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const RadioComponent = ({ icon, label, ...rest }) => {
  const { register } = useFormContext()
  return (
    <div className={`radio-component fl fl-sb`}>
      <div className='fl'>
        {icon}
        <div>{label}</div>
      </div>
      <Radio 
        {...rest}
        {...register('transportOption', {
          required: 'Transport mode is required'})} />
    </div>
  )
}
export default RadioComponent
