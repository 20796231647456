import AccessLevelUserFormProperties from './AccessLevelUserFormProperties'
import AccessLevelUserApiUrls from 'src/apiUrls/AccessLevelUserApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function AccessLevelUserEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("AccessLevelUser Edit Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelUserFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelUserFormProperties.formikProps}
      toClientDataFormat={AccessLevelUserFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelUserFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: AccessLevelUserApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: AccessLevelUserApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default AccessLevelUserEditForm
