import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardHeader, CardContent, Divider, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import PartySummaryViewForm from './CalistaPartyConfig_PartySummaryViewForm'
import CalistaPartyConfigEditForm from './CalistaPartyConfig_EditForm'
import CalistaPartyEditCompanyInfoForm from '../party-company-info/CompanyInfoEditForm'
import CalistaPartyEditCompanyMicrositeForm from '../party-company-info/CompanyMicrositeEditForm'
import pathMap from 'src/paths/PathMap_CalistaAdmin'
const { CngTabs } = components

function EditPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.CALISTA_PARTY_CONFIG])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TITLE)
		let partySummarySectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.PARTY_SUMMARY_SECTION_HEADER)
		let moduleSettingsTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_MODULE_SETTINGS)
		let companyInfoTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_COMPANY_INFO)
		let micrositeTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_MICROSITE)

		return {
			title,
			partySummarySectionHeader,
			moduleSettingsTabTitle,
			companyInfoTabTitle,
			micrositeTabTitle
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextsObject.moduleSettingsTabTitle,
							tabContent: (
								<Grid container spacing={3}>

									<Grid item xs={12}>
										<Card>
											<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.partySummarySectionHeader}</Typography>} />
											<Divider />

											<CardContent>
												<PartySummaryViewForm
													showNotification={showNotification}
													id={id}
												/>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12}>
										<CalistaPartyConfigEditForm
											showNotification={showNotification}
											id={id}
										/>
									</Grid>
								</Grid>
							)
						},
						{
							tabName: translatedTextsObject.companyInfoTabTitle,
							tabContent: (
								<>
									<CalistaPartyEditCompanyInfoForm
										showNotification={showNotification}
										id={id} />
								</>
							)
						},
						{
							tabName: translatedTextsObject.micrositeTabTitle,
							tabContent: (
								<>
									<CalistaPartyEditCompanyMicrositeForm
										micrositePreviewUrl={pathMap.CALISTA_PARTY_CONFIG_MICROSITE_PREVIEW}
										showNotification={showNotification}
										id={id} />
								</>
							)
						}
					]}
				/>
			</Grid>

		</Grid>
		//* End JSX ------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default EditPage