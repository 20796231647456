import { Grid, FormControl, TextField } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { getError } from './PortSelectAutoComplete'

function checkView(value, chip) {
  if (chip) {
    return '>0 t'
  }
  if (value) {
    return `0-${value}%`
  }
}

const InputComponent = ({
  namefield,
  label,
  chip,
  xs,
  t,
  rules,
  typeInput
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <Grid item xs={xs}>
      <FormControl fullWidth error={!!getError(errors, namefield)}>
        <TextField
          {...register(namefield, {
            pattern: {
              value: /^\d*\.?\d*$/,
              message: t('label_imput_invalid', {
                errorText: `${checkView(100, chip)}`
              })
            },
            validate: !typeInput
              ? (value) => {
                  if (value === '0' || value === '0.') {
                    return t('label_imput_invalid', {
                      errorText: `${checkView(100, chip)}`
                    })
                  } else {
                    if (
                      value &&
                      (value.charAt('0') == '.' ||
                        value.split('.')[1]?.length > 1)
                    ) {
                      return t('label_format_number')
                    } else {
                      return null
                    }
                  }
                }
              : (value) => {
                  if (value && value.includes('.')) {
                    return 'The input is invalid, try an integer value.'
                  } else return null
                },
            min: typeInput
              ? {
                  value: rules?.min,
                  message: t('label_imput_invalid', {
                    errorText: `${checkView(100, chip)}`
                  })
                }
              : null,
            max: rules?.max
              ? {
                  value: 100,
                  message: t('label_imput_invalid', { errorText: '0-100%' })
                }
              : null
          })}
          error={getError(errors, namefield)}
          type={typeInput ?? 'text'}
          label={label}
          fullWidth
          variant='filled'
          InputProps={{
            endAdornment: <div className='cfc-percent'>{chip ?? '%'}</div>
          }}
          helperText={getError(errors, namefield)?.message ?? ''}
        />
      </FormControl>
    </Grid>
  )
}
export default InputComponent
