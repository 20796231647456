import { Card, Grid, Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import CargoInformationComponents from '../components/CargoInformationComponents'
import TonPerTEUComponent from '../components/TonPerTEUComponent'
import TransportMode from '../components/EcoCalculator_TransportMode'
import IconBox from '../components/IconBox'
import WordBox from '../components/WordBox'
import SelectComponent from '../components/select-component'
import { LocationOnOutlined } from '@material-ui/icons'
import PortSelectAutoComplete from '../components/PortSelectAutoComplete'
import { useServices, constants, useTranslation } from 'cng-web-lib'
import carbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'
import { useForm, FormProvider } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from '../components/Loading'
import { chekkeyCode } from '../ultls'
import { OPTION_LOCATION } from '../constants'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import ConfirmDialog from '../../../../components/dialog/ConfirmDialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Namespace from 'src/constants/locale/Namespace'
const BtnSubmit = ({ t }) => {
  return (
    <Button
      fullWidth
      style={{
        height: '56px',
        background: '#5E81F4',
        borderRadius: '8px',
        color: '#ffffff'
      }}
      startIcon={<FontAwesomeIcon icon={['fas', 'calculator']} />}
      type='submit'
      variant='contained'
    >
      {t('label_calculate')}
    </Button>
  )
}
function CalculatePage(props) {
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])
  const { history } = props
  const { fetchRecords } = useServices()
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState([])
  const { CodeMaintenanceType } = constants
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      (content) => {
        setCountry(content)
        setLoading(false)
      }
    )
  }, [])

  const formData = history?.location?.state?.dataLocationForm ?? {}

  const methods = useForm({
    defaultValues: formData
  })
  const onSubmit = (values) => {
    if (values?.locationType == 'uicCode' && !values?.departure?.station) {
      methods.setError('departure', {
        message: t('mgs_code_mapping')
      })
    } else {
      if (
        values?.locationTypePOD == 'uicCode' &&
        !values?.destination?.station
      ) {
        methods.setError('destination', {
          message: t('mgs_code_destination')
        })
      } else {
        if (values?.departure?.code === values?.destination?.code) {
          methods.setError('destination', {
            message: t('mgs_same_origin')
          })
          methods.setError('departure', {
            message: t('mgs_same_origin')
          })
        } else {
          setLoading(true)

          const returnValue = {
            cargoRequest: {
              weight: +values?.cargoVolume
            },
            routeRequest: {
              departure: chekkeyCode(values?.locationType, values?.departure),
              destination: chekkeyCode(
                values?.locationTypePOD,
                values?.destination
              )
            },
            transportModeRequests: values?.transportMode
          }

          fetchRecords.execute(
            carbonFootprintApiUrls.CALCULATE_STANDARD,
            { customData: returnValue },
            (data) => {
              history.push({
                pathname: '/cal-cfc/eco-calculator/result',
                state: {
                  dataLocationForm: values,
                  data,
                  country: country,
                  locations: {
                    startLocation: {
                      code: values?.departure?.code,
                      description: values?.departure?.descriptionEn,
                      flag: values?.departure?.countryCode,
                      locationType: values?.locationType
                    },
                    endLocation: {
                      code: values?.destination?.code,
                      description: values?.destination?.descriptionEn,
                      flag: values?.destination?.countryCode,
                      locactionType: values?.locationTypePOD
                    }
                  }
                }
              })
            },
            (error) => {
              console.log(error)
              setLoading(true)
            }
          )
        }
      }
    }
  }
  const [modalData, setModalData] = useState({})
  const modalAction = () => {
    setConfirmDialogOpen(false)
    methods.reset()
    history.push('/cal-cfc/eco-calculator/advan')
  }

  return (
    <Loading loading={loading}>
      <FormProvider {...methods}>
        <ConfirmDialog
          isConfirmDialogOpen={confirmDialogOpen}
          closeDialog={() => setConfirmDialogOpen(false)}
          confirmDialog={modalAction}
          content={modalData.content}
          title={modalData.title}
          okMsg={modalData.okMsg}
          cancelMsg={modalData.cancelMsg}
        />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card style={{ backgroundColor: '#fafbfc' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className='cfc-cargo-infomation p-20'>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <div style={{ fontWeight: 600, fontSize: '20px' }}>
                      {t('label_cargo')}
                      <div
                        style={{ fontSize: '11px', color: '#8996AF' }}
                        display='block'
                      >
                        {t('label_fields_mandatory')}
                      </div>
                    </div>
                    {/* text box 1 */}
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      value='advanced-mode'
                      control={
                        <Switch
                          color='primary'
                          checked={false}
                          onClick={() => {
                            setModalData({
                              content: t('label_modal_content'),
                              title: t('label_switch_mode'),
                              okMsg: t('label_ok'),
                              cancelMsg: t('label_cancel'),
                              type: 'switch-mode'
                            })
                            setConfirmDialogOpen(true)
                          }}
                        />
                      }
                      label={t('label_advanced')}
                      labelPlacement='start'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <WordBox
                          label={t('label_amount')}
                          description={t('label_teu')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CargoInformationComponents />
                      </Grid>
                      <Grid item xs={3}>
                        <TonPerTEUComponent />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*box 2*/}
              <Grid item xs={12} className='bg-w p-20'>
                <Grid item xs={12}>
                  <div style={{ fontWeight: 600, fontSize: '20px' }}>
                    {t('label_schedule_scenario')}
                  </div>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <IconBox
                          icon={<LocationOnOutlined />}
                          label={t('label_origin')}
                        />
                      </Grid>
                      {/* <Grid item xs={3}> */}
                      <SelectComponent
                        xs={3}
                        label={t('label_location_type')}
                        name={'locationType'}
                        options={OPTION_LOCATION}
                      />

                      {/* </Grid> */}
                      <Grid item xs={3}>
                        <PortSelectAutoComplete
                          name='departure'
                          label={t('label_location')}
                          fName='locationType'
                          country={country}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*line 2 2.1 */}
                  <Grid item xs={12} className='bd-b pb-30'>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <WordBox
                          label={t('label_transport_mode')}
                          description={t('label_transport_select')}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <TransportMode />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/**line 2.2 */}
                  <Grid item xs={12} className='mt-20'>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <IconBox
                          icon={<LocationOnOutlined />}
                          label={t('label_destination')}
                        />
                      </Grid>
                      <SelectComponent
                        xs={3}
                        label={t('label_location_type')}
                        name={'locationTypePOD'}
                        options={OPTION_LOCATION}
                      />

                      <Grid item xs={3}>
                        <PortSelectAutoComplete
                          name='destination'
                          label={t('label_location')}
                          fName='locationTypePOD'
                          country={country}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <BtnSubmit t={t} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </form>
      </FormProvider>
    </Loading>
  )
}

export default CalculatePage
