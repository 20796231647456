import AccessLevelRuleFormProperties from './AccessLevelRuleFormProperties'
import AccessLevelRuleApiUrls from 'src/apiUrls/AccessLevelRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function AccessLevelRuleEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("AccessLevelRule Edit Form")}
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelRuleFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelRuleFormProperties.toServerDataFormat}
      fetch={{
        url: AccessLevelRuleApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: AccessLevelRuleApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default AccessLevelRuleEditForm
