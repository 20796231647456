import React, {useEffect} from 'react'
import {Grid} from '@material-ui/core'
import { components, useTranslation, DataFlattener, DateTimeFormatter } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import NBSFileKeys from "../../../../constants/locale/key/NBSFile";
import {useFormContext} from "react-hook-form";
import Moment from "moment";

const {
    form: { field: { CngCodeMasterAutocompleteField, CngTextField, CngDateField, CngSelectField }},
    CngGridItem
}=components

//* Field default values
const DEFAULT_INITIAL_VALUES = Object.freeze({
    chargeCode:"",
    partyId:"",
    postingDate:"",
    createdDate: "",
    status: "",
    submittedFile: "",
    submittedFileName: "",
    updatedDate: "",
    returnFile: "",
    returnFileName: "",

})

const FORMIK_PROPS = {
    initialValues:{...DEFAULT_INITIAL_VALUES }
}

function Fields({disabled, shouldHideMap, setValue}){

    const { translate } = useTranslation(Namespace.NBS_FILE)
    const translatedTextsObject = makeTranslatedTextsObject()

    const { getValues } = useFormContext();
    const values = getValues();

    useEffect(() => {
        setValue(values)
    }, [values.submittedFile]);

    function makeTranslatedTextsObject(){

        let chargeCode = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.CHARGE_CODE)
        let partyId = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.PARTY_ID)
        let postingDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.POSTING_DATE)
        let createdDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.TIMESTAMP_GEN)
        let status = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.STATUS)
        let submittedFileName = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.SUBMITTED_FILE_NAME)
        let submittedFile = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.SUBMITTED_FILE)
        let updatedDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.UPDATED_DATE)
        let returnFileName = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.RETURN_FILE_NAME)
        let returnFile = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.RETURN_FILE)


        return{
            chargeCode,
            partyId,
            postingDate,
            createdDate,
            status,
            submittedFileName,
            submittedFile,
            updatedDate,
            returnFileName,
            returnFile
        }
    }

    //* return JSX
    return(
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCode}>
                <CngCodeMasterAutocompleteField name="chargeCode"
                                                label={translatedTextsObject.chargeCode}
                                                disabled={disabled}
                                                codeType='CAL_NBS_CHARGECODE'
                                                required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
                <CngSelectField name="partyId"
                              label={translatedTextsObject.partyId}
                              disabled={disabled}
                              fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`, textAccessor: "name"}}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postingDate}>
                <CngDateField name="postingDate"
                              label={translatedTextsObject.postingDate}
                              format='YYYY-MM-DD'
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
                <CngDateField name="createdDate"
                              label={translatedTextsObject.createdDate}
                              format="YYYY-MM-DD HH:mm:ss"
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
                <CngTextField name="status"
                              label={translatedTextsObject.status}
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.submittedFileName}>
                <CngTextField name="submittedFileName"
                              label={translatedTextsObject.submittedFileName}
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.submittedFile}>
                <CngTextField name="submittedFile"
                              label={translatedTextsObject.submittedFile}
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
                <CngDateField name="updatedDate"
                              label={translatedTextsObject.updatedDate}
                              format='YYYY-MM-DD HH:mm:ss'
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.returnFileName}>
                <CngTextField name="returnFileName"
                              label={translatedTextsObject.returnFileName}
                              disabled={disabled}
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.returnFile}>
                <CngTextField name="returnFile"
                              label={translatedTextsObject.returnFile}
                              disabled={disabled}
                />
            </CngGridItem>

        </Grid>
        //* End JSX
    )
    //* End of function
}

function toClientDataFormat(serverData) {

    serverData.postingDate = DateTimeFormatter.toClientDateTime(serverData.postingDate)
    serverData.createdDate = DateTimeFormatter.toClientDateTime(serverData.createdDate, 'YYYY-MM-DD HH:mm:ss')
    serverData.updatedDate = DateTimeFormatter.toClientDateTime(serverData.updatedDate, 'YYYY-MM-DD HH:mm:ss')
    return serverData
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties