import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import UserForShipperRequestViewForm from './UserForShipperRequestViewForm'
import UserForShipperRequestAddForm from './UserForShipperRequestAddForm'
import UserForShipperRequestEditForm from './UserForShipperRequestEditForm'
import UserShipperRequestTypeCrudTable from './UserShipperRequestTypeCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [userForShipperRequestId, setUserForShipperRequestId] = useState('')
  const { translate } = useTranslation([
    Namespace.USER_FOR_SHIPPER_REQUEST,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userForShipperRequest = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.TITLE
    )
    let userShipperRequestType = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.UserShipperRequestType.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: userForShipperRequest
      }
    )

    return {
      userForShipperRequest,
      userShipperRequestType,
      tabLockedMessage
    }
  }

  function makeUserForShipperRequestForm() {
    if (userForShipperRequestId === '') {
      return (
        <UserForShipperRequestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setUserForShipperRequestId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <UserForShipperRequestEditForm
          showNotification={showNotification}
          id={userForShipperRequestId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <UserForShipperRequestViewForm
              showNotification={showNotification}
              id={userForShipperRequestId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.userForShipperRequest,
              tabContent: makeUserForShipperRequestForm()
            },
            {
              tabName: translatedTextsObject.userShipperRequestType,
              tabContent: (
                <UserShipperRequestTypeCrudTable
                  showNotification={showNotification}
                  userForShipperRequestId={userForShipperRequestId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage