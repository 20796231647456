import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import Namespace from 'src/constants/locale/Namespace'
import makeValidationSchema from './CalistaPartyConfig_MakeValidationSchema'

const {
	form: { field: { CngTextField, CngSwitchField, CngCodeMasterAutocompleteField, CngSelectField, CngDateField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	sysIntegrationUserId: "",
	tasIsTasParty: false,
	tasIsSearchRestricted: false,
	tasSubscriptionTypeCode: "",
	tasRestrictedCountryCodes: "",
	tasRestrictedCountryCodeList: [],
	tasOutofRestrictedCountrySearchAllowed: false,
	tasTransactionLimitType: "",
	tasTransactionLimitValue: "",
	tasHsClassificationLimitValue: "",
	tasComplianceDefaultLimitValue: "",
	tasLandedCostLimitValue: "",
	tasTransLimitFromDate: "",
	tasTransLimitToDate: "",
	tasIsComplianceRestricted: false,
	tasCiaSubscriptionTypeCode: "",
	tasCiaSubscriptionStatusCode: "",
	cecIsCecParty: false,
	cecSubscriptionTypeCode: "",
	cecSubscriptionEndDate: "",
	flexiSmsNotificationSub: false
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {

	const { translate } = useTranslation(Namespace.CALISTA_PARTY_CONFIG)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		// fields
		let sysIntegrationSectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.SYS_INTEGRATION_SECTION_HEADER)
		let sysIntegrationUserId = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.SYS_INTEGRATION_USER_ID)

		let tasSectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_SECTION_HEADER)
		let tasIsTasParty = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_IS_TAS_PARTY)
		let tasIsSearchRestricted = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_IS_SEARCH_RESTRICTED)
		let tasSubscriptionTypeCode = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_SUBSCRIPTION_TYPE_CODE)
		let tasRestrictedCountryCodes = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_RESTRICTED_COUNTRY_CODES)
		let tasRestrictedCountryCodeList = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_RESTRICTED_COUNTRY_CODES)
		let tasOutofRestrictedCountrySearchAllowed = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_OUTOF_RESTRICTED_COUNTRY_SEARCH_ALLOWED)
		let tasTransactionLimitType = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_TRANSACTION_LIMIT_TYPE)
		let tasTransactionLimitValue = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_TRANSACTION_LIMIT_VALUE)
		let tasHsClassificationLimitValue = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_HS_CLASSIFICATION_LIMIT_VALUE)
		let tasComplianceDefaultLimitValue = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_COMPLIANCE_DEFAULT_LIMIT_VALUE)
		let tasLandedCostLimitValue = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_LANDED_COST_LIMIT_VALUE)
		let tasTransLimitFromDate = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_TRANS_LIMIT_FROM_DATE)
		let tasTransLimitToDate = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_TRANS_LIMIT_TO_DATE)
		let tasIsComplianceRestricted = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_IS_COMPLIANCE_RESTRICTED)
		let tasCiaSubscriptionTypeCode = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_CIA_SUBSCRIPTION_TYPE_CODE)
		let tasCiaSubscriptionStatusCode = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAS_CIA_SUBSCRIPTION_STATUS_CODE)

		let cecSectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.CEC_SECTION_HEADER)
		let cecIsCecParty = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.CEC_IS_CEC_PARTY)
		let cecSubscriptionTypeCode = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.CEC_SUBSCRIPTION_TYPE_CODE)
		let cecSubscriptionEndDate = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.CEC_SUBSCRIPTION_END_DATE)

		let flexiSectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.FLEXI_SECTION_HEADER)
		let flexiSmsNotificationSub = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.FLEXI_SMS_NOTIFICATION_SUB)

		return {
			sysIntegrationSectionHeader,
			sysIntegrationUserId,
			tasSectionHeader,
			tasIsTasParty,
			tasIsSearchRestricted,
			tasSubscriptionTypeCode,
			tasRestrictedCountryCodes,
			tasRestrictedCountryCodeList,
			tasOutofRestrictedCountrySearchAllowed,
			tasTransactionLimitType,
			tasTransactionLimitValue,
			tasHsClassificationLimitValue,
			tasComplianceDefaultLimitValue,
			tasLandedCostLimitValue,
			tasTransLimitFromDate,
			tasTransLimitToDate,
			tasIsComplianceRestricted,
			tasCiaSubscriptionTypeCode,
			tasCiaSubscriptionStatusCode,
			cecSectionHeader,
			cecIsCecParty,
			cecSubscriptionTypeCode,
			cecSubscriptionEndDate,
			flexiSectionHeader,
			flexiSmsNotificationSub
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>

			{/* 
				----------------------------------------------------------------
				System Integration Settings 
				---------------------------------------------------------------- 
			*/}
			<CngGridItem xs={12}><Card>
				<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.sysIntegrationSectionHeader}</Typography>} />
				<Divider />

				<CardContent><Grid container spacing={3}>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sysIntegrationUserId}>
						<CngSelectField name='sysIntegrationUserId'
							label={translatedTextsObject.sysIntegrationUserId}
							disabled={disabled}
							fetch={{
								url: CalistaPartyConfigApiUrls.GET_DROPDOWN_OPTIONS_SYS_INTEGRATION_USER_ID,
								body: {
									partyId: id
								},
								valueAccessor: "userId",
								textAccessor: "loginId"
							}}
						/>
					</CngGridItem>
				</Grid></CardContent>
			</Card></CngGridItem>

			{/*
				----------------------------------------------------------------
				TAS Settings
				----------------------------------------------------------------
			*/}
			<CngGridItem xs={12}><Card>
				<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.tasSectionHeader}</Typography>} />
				<Divider />

				<CardContent><Grid container spacing={3}>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasIsTasParty}>
						<CngSwitchField name='tasIsTasParty'
							label={translatedTextsObject.tasIsTasParty}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasIsSearchRestricted}>
						<CngSwitchField name='tasIsSearchRestricted'
							label={translatedTextsObject.tasIsSearchRestricted}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasSubscriptionTypeCode}>
						<CngCodeMasterAutocompleteField name='tasSubscriptionTypeCode'
							label={translatedTextsObject.tasSubscriptionTypeCode}
							disabled={disabled}
							codeType='TAS_SUBSCRIPTION_TYPE'
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasRestrictedCountryCodes}>
						<CngCodeMasterAutocompleteField name='tasRestrictedCountryCodeList'
							label={translatedTextsObject.tasRestrictedCountryCodeList}
							disabled={disabled}
							codeType='TAS_COUNTRY'
							multiple disableCloseOnSelect
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasOutofRestrictedCountrySearchAllowed}>
						<CngSwitchField name='tasOutofRestrictedCountrySearchAllowed'
							label={translatedTextsObject.tasOutofRestrictedCountrySearchAllowed}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasTransactionLimitType}>
						<CngSelectField name='tasTransactionLimitType'
							label={translatedTextsObject.tasTransactionLimitType}
							disabled={disabled}
							items={[
								{ text: 'No Limit', value: 'No Limit' },
								{ text: 'Total Transaction', value: 'Total Transaction' },
								{ text: 'Monthly Transaction', value: 'Monthly Transaction' }
							]}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasTransactionLimitValue}>
						<CngTextField name="tasTransactionLimitValue"
							label={translatedTextsObject.tasTransactionLimitValue}
							disabled={disabled}
							type="number"
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasHsClassificationLimitValue}>
						<CngTextField name="tasHsClassificationLimitValue"
							label={translatedTextsObject.tasHsClassificationLimitValue}
							disabled={disabled}
							type="number"
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasComplianceDefaultLimitValue}>
						<CngTextField name="tasComplianceDefaultLimitValue"
							label={translatedTextsObject.tasComplianceDefaultLimitValue}
							disabled={disabled}
							type="number"
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasLandedCostLimitValue}>
						<CngTextField name="tasLandedCostLimitValue"
							label={translatedTextsObject.tasLandedCostLimitValue}
							disabled={disabled}
							type="number"
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasTransLimitFromDate}>
						<CngDateField name='tasTransLimitFromDate'
							label={translatedTextsObject.tasTransLimitFromDate}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasTransLimitToDate}>
						<CngDateField name='tasTransLimitToDate'
							label={translatedTextsObject.tasTransLimitToDate}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasIsComplianceRestricted}>
						<CngSwitchField name='tasIsComplianceRestricted'
							label={translatedTextsObject.tasIsComplianceRestricted}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasCiaSubscriptionTypeCode}>
						<CngCodeMasterAutocompleteField name='tasCiaSubscriptionTypeCode'
							label={translatedTextsObject.tasCiaSubscriptionTypeCode}
							disabled={disabled}
							codeType='TAS_CIA_SUBSCRIPTION_TYPE'
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tasCiaSubscriptionStatusCode}>
						<CngCodeMasterAutocompleteField name='tasCiaSubscriptionStatusCode'
							label={translatedTextsObject.tasCiaSubscriptionStatusCode}
							disabled={disabled}
							codeType='TAS_CIA_SUBSCRIPTION_STATUS'
						/>
					</CngGridItem>
				</Grid></CardContent>
			</Card></CngGridItem>


			{/*
				----------------------------------------------------------------
				CeC Settings
				----------------------------------------------------------------
			*/}
			<CngGridItem xs={12}><Card>
				<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.cecSectionHeader}</Typography>} />
				<Divider />

				<CardContent><Grid container spacing={3}>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cecIsCecParty}>
						<CngSwitchField name='cecIsCecParty'
							label={translatedTextsObject.cecIsCecParty}
							disabled={disabled}
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cecSubscriptionTypeCode}>
						<CngCodeMasterAutocompleteField name='cecSubscriptionTypeCode'
							label={translatedTextsObject.cecSubscriptionTypeCode}
							disabled={disabled}
							codeType='CEC_SUBSCRIPTION_TYPE'
						/>
					</CngGridItem>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cecSubscriptionEndDate}>
						<CngDateField name='cecSubscriptionEndDate'
							label={translatedTextsObject.cecSubscriptionEndDate}
							disabled={disabled}
						/>
					</CngGridItem>
				</Grid></CardContent>
			</Card></CngGridItem>

			{/*
				----------------------------------------------------------------
				FlexiAlert Settings
				----------------------------------------------------------------
			*/}
			<CngGridItem xs={12}><Card>
				<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.flexiSectionHeader}</Typography>} />
				<Divider />

				<CardContent><Grid container spacing={3}>
					<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.flexiSmsNotificationSub}>
						<CngSwitchField name='flexiSmsNotificationSub'
							label={translatedTextsObject.flexiSmsNotificationSub}
							disabled={disabled}
						/>
					</CngGridItem>
				</Grid></CardContent>
			</Card></CngGridItem>
		</Grid>
		//* End JSX ----------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
