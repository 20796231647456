import React from 'react'
import { Grid, Checkbox, Tooltip } from '@material-ui/core'
import RadioComponent from '../components/RadioComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { OPTION_LOCATION, RADIO_CHECKED, RADIO_CONFIG } from '../constants'
import IconBox from '../components/IconBox'
import { LocationOnOutlined } from '@material-ui/icons'
import { useFieldArray, useFormContext, Controller } from 'react-hook-form'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined'
import IconButton from '@material-ui/core/IconButton'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { SelectAdvanComponent } from './select-component'
import PortSelectAutoComplete from './PortSelectAutoComplete'
import SelectAutoComplete from './SelectApiComponent'
import InputComponent from './InputComponent'
import { generateKey } from '../ultls'
import FormControl from '@mui/material/FormControl'

function renderForm({
  formConfix = [],
  name,
  xs,
  nameTranport,
  t,
  country,
  control,
  index
}) {
  return formConfix.map((elm) => {
    const namefield = `${name}.${elm?.name}`
    const key = generateKey()
    switch (elm?.type) {
      case 'input':
        return (
          <InputComponent
            namefield={namefield}
            rules={elm?.rules}
            xs={xs ?? elm?.xs}
            label={elm?.label}
            chip={elm?.chip}
            t={t}
            disabled={false}
            key={key}
            typeInput={elm?.typeInput}
          />
        )
      case 'select':
        return (
          <SelectAdvanComponent
            key={key}
            name={namefield}
            xs={xs ?? elm?.xs}
            label={elm?.label}
            options={elm?.options}
            rules={elm?.rules ?? {}}
          />
        )
      case 'select-api':
        return (
          <Grid item xs={xs ?? elm?.xs} key={key}>
            <SelectAutoComplete
              url={elm?.url}
              bodyRequest={elm?.bodyRequest}
              label={elm?.label}
              name={namefield}
              dataOptions={elm.dataOptions}
              showChip={elm?.showChip}
            />
          </Grid>
        )
      case 'port':
        return (
          <Grid item xs={xs ?? elm?.xs}>
            <PortSelectAutoComplete
              name={namefield}
              label={elm?.label}
              fName={`${name}.${elm?.fName}`}
              country={country}
              nameTranport={nameTranport}
              index={index}
            />
          </Grid>
        )
      case 'checkbox':
        return (
          <Grid item xs={xs ?? elm?.xs} key={key}>
            <FormControlLabel
              className='cfc-checkbox'
              control={
                <Controller
                  name={namefield}
                  control={control}
                  render={(props) => {
                    return (
                      <Checkbox
                        {...props}
                        checked={props?.field?.value}
                        onChange={(e) =>
                          props?.field?.onChange(e?.target?.checked)
                        }
                      />
                    )
                  }}
                />
              }
              label={elm?.label}
              labelPlacement='top'
            />
          </Grid>
        )

      default:
        return <Grid item xs={xs ?? elm?.xs}></Grid>
    }
  })
}
const ViaComponent = ({
  addItem,
  index,
  name,
  deleteItem,
  moveItem,
  t,
  country
}) => {
  const isFirst = index === 0
  const { getValues, setValue, control } = useFormContext()
  const [location, setLocation] = React.useState(null)
  const [option, setOption] = React.useState(
    getValues(`${name}.transport`) ?? null
  )
  const onChange = (val) => {
    setOption(val)
    clearValue(val)
  }
  const clearValue = (checkValue) => {
    const resultFilter = RADIO_CONFIG.filter((item) => item.name != checkValue)
    resultFilter.forEach((elm) => {
      setValue(
        `${name}.${
          elm.value === 'ocean'
            ? 'sea'
            : elm.value === 'rail'
            ? 'train'
            : elm.value
        }ParametersRequest`,
        null
      )
    })
  }
  const onChangeLocation = (val) => {
    setLocation(val)
  }
  return (
    <Grid item xs={12} className='via-component'>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <IconBox icon={<LocationOnOutlined />} label={'Via'} />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <SelectAdvanComponent
              name={`${name}.viaLocationType`}
              xs={6}
              label={t('label_location_type')}
              options={OPTION_LOCATION}
              rules={{ required: 'Please select value' }}
              onChangeSelect={onChangeLocation}
            />
            <Grid item xs={6}>
              <PortSelectAutoComplete
                name={`${name}.transportRequest`}
                label={t('label_location')}
                fName={`${name}.viaLocationType`}
                country={country}
                nameTranport={`${name}.transport`}
                index={index}
                location={location}
              />
            </Grid>
            <SelectAdvanComponent
              name={`${name}.transport`}
              xs={6}
              label={t('label_transport_mode')}
              options={RADIO_CONFIG.map((item) => {
                return { ...item, value: item?.name }
              })}
              rules={{ required: 'Please select value' }}
              onChangeSelect={onChange}
            />
            <Grid item xs={6} />

            {renderForm({
              formConfix: RADIO_CHECKED[option],
              name: `${name}.${
                option === 'ocean'
                  ? 'sea'
                  : option === 'rail'
                  ? 'train'
                  : option
              }ParametersRequest`,
              xs: 6,
              option: option,
              nameTranport: `${name}.transport`,
              t: t,
              country: country,
              control: control
            })}
          </Grid>
        </Grid>
      </Grid>
      <div className='via-footer'>
        <div>
          <Tooltip title={t('label_duplicate')} placement='bottom'>
            <IconButton onClick={addItem}>
              <FileCopyOutlinedIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('label_delete')} placement='bottom'>
            <IconButton onClick={deleteItem}>
              <DeleteOutlineIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`${isFirst ? t('label_move_down') : t('label_move_up')}`}
            placement='bottom'
          >
            <IconButton
              onClick={moveItem}
              style={{
                transform: `${isFirst ? 'rotate(90deg)' : 'rotate(-90deg)'}`
              }}
            >
              <ArrowRightAltOutlinedIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Grid>
  )
}
const CheckboxOptionComponent = ({ index = 0, t, country }) => {
  const { register, control, setValue, getValues, formState: { errors } } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `optionRequests.${index}.viaRequestList`
  })
  const [option, setOption] = React.useState(
    getValues(`optionRequests.${index}.transport`) ?? []
  )

  setValue(`optionRequests.${index}.transport`, option)

  const onChange = (ev) => {
    setOption(ev.target.value)
    setValue(`optionRequests.${index}.transport`, ev.target.value)
    clearValue(ev.target.value)
  }
  const clearValue = (checkValue) => {
    const resultFilter = RADIO_CONFIG.filter((item) => item.name != checkValue)
    resultFilter.forEach((elm) => {
      setValue(
        `optionRequests.${index}.${
          elm?.name === 'ocean'
            ? 'sea'
            : elm?.name === 'rail'
            ? 'train'
            : elm?.name
        }ParametersRequest`,
        null
      )
    })
  }
  const addItem = (name) => {
    return () => {
      if (fields.length < 100) append(name ? getValues(name) : {})
    }
  }
  const deleteItem = (i) => () => {
    remove(i)
  }
  const moveItem = (i, isUp) => () => {
    if (fields.length > 1)
      if (isUp) {
        move(i, i + 1)
      } else {
        move(i, i - 1)
      }
  }
  return (
    <>
      <Grid item xs={12}>
        <FormControl error={errors['transportOption']}>
          <RadioGroup
            onChange={onChange}
            className='fl fl-sb fl-r'
            aria-label='gender'
            name='gender1'
            value={option}
          >
            {RADIO_CONFIG.map((item) => {
              return (
                <FormControlLabel
                  value={item?.name}
                  control={
                    <RadioComponent
                      error={errors['transportOption']}
                      label={item.label}
                      icon={
                        typeof item?.icon === 'string' ? (
                          <img
                            className='mr-10'
                            style={{ width: '20px' }}
                            src={item.icon}
                          />
                        ) : (
                          <FontAwesomeIcon className='mr-10' icon={item.icon} />
                        )
                      }
                    />
                  }
                />
              )
            })}
          </RadioGroup>
          <div className='error'>{errors['transportOption']?.message}</div>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {option &&
            renderForm({
              formConfix: RADIO_CHECKED[option],
              name: `optionRequests.${index}.${
                option === 'ocean'
                  ? 'sea'
                  : option === 'rail'
                  ? 'train'
                  : option
              }ParametersRequest`,
              xs: null,
              option: option,
              t: t,
              country: country,
              control: control
            })}
        </Grid>
        <Grid container spacing={2}>
          {fields.map((field, i) => {
            return (
              <ViaComponent
                key={field.id}
                name={`optionRequests.${index}.viaRequestList.${i}`}
                index={i}
                addItem={addItem(`optionRequests.${index}.viaRequestList.${i}`)}
                register={register}
                deleteItem={deleteItem(i)}
                moveItem={moveItem(i, i === 0)}
                t={t}
                country={country}
              />
            )
          })}
          <Grid item xs={12}>
            <div className='via-add fl' onClick={addItem()}>
              <div className='icon-add'>
                <AddOutlinedIcon fontSize='inherit' />
              </div>
              <span className='fl'>
                <span className='add-leg'>{t('label_add')}</span>
                <span className='show-detail'>{t('label_clone_previous')}</span>
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default CheckboxOptionComponent
