import AccessLevelUserFormProperties from './AccessLevelUserFormProperties'
import AccessLevelUserApiUrls from 'src/apiUrls/AccessLevelUserApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function AccessLevelUserAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AccessLevelUserFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelUserFormProperties.formikProps}
      toClientDataFormat={AccessLevelUserFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelUserFormProperties.toServerDataFormat}
      create={{
        url: AccessLevelUserApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default AccessLevelUserAddForm
