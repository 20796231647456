import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo'
import { useTranslation } from 'cng-web-lib'


const PartyCompanyInfoTranslationText = () => {
    const { translate } = useTranslation(Namespace.CALISTA_PARTY_COMPANY_INFO);
    //Company's Contact Person Details
    const companyContactPersonDetailsHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_CONTACTPERSONDETAILS_HEADER)
    const contactEmail = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_CONTACTPERSONDETAILS_CONTACTEMAIL)
    const contactPhone = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_CONTACTPERSONDETAILS_CONTACTPHONE)
    const invalidEmail = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.INVALID_EMAIL)
    const formError = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.FORM_ERROR)

    //Company's Specialisations
    const companySpecialisationsHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_SPECIALISATIONS_HEADER)
    const specialisedIndustries = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_SPECIALISATIONS_INDUSTRIES)
    const specialisedServices = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_SPECIALISATIONS_SERVICES)

    //Company's Business Interests
    const companyBusinessInterestsHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_BUSINESSINTERESTS_HEADER)
    const interestedIndustries = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_BUSINESSINTERESTS_INDUSTRIES)
    const interestedServices = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_BUSINESSINTERESTS_SERVICES)
    const interestedCountries = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_BUSINESSINTERESTS_COUNTRIES)

    //More About The Company
    const companyMoreAboutHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MOREABOUT_HEADER)
    const introduction = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MOREABOUT_INTRO)
    const vision = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MOREABOUT_VISION)
    const presenceInCountries = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MOREABOUT_COUNTRIES)

    //Company Media
    const companyMediaHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MEDIA_HEADER)
    const companyMediaCurrent = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MEDIA_CURRENT)

    //Management Team
    const managementTeamHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_HEADER)
    const addManagementUser = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_ADD)
    const fullName = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_FULLNAME)
    const role = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_ROLE)
    const profileLink = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_PROFILELINK)
    const profileLinkPlaceholder = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_PROFILELINKPLACEHOLDER)
    const currentProfilePhoto = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_CURRENTPROFILEPHOTO)
    const noneProfilePic = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_NONE)
    const noteForFileUploadProfilePic = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MANAGEMENT_UPLOAD)

    //Awards and Accreditations
    const awardsHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_HEADER)
    const addAward = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_ADD)
    const awardTitle = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_TITLE)
    const awardDescription = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_DESCRIPTION)
    const currentSuppDoc = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_CURRENTSUPPDOC)
    const noneSuppDoc = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_NONE)
    const noteForFileUploadSuppDoc = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_AWARDS_UPLOAD)
    const remove = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.REMOVE)

    //Company's Microsite
    const micrositeHeader = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_HEADER)
    const micrositeTextPart1 = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_TEXT_PART1)
    const micrositeTextPart2 = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_TEXT_PART2)
    const micrositeTextPart3 = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_TEXT_PART3)
    const micrositeLookLikeText = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_TEXT_LOOKLIKE)
    const micrositeNotActivatedText = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_TEXT_NOTACTIVATED)
    const uniquePath = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_UNIQUEPATH)
    const uniquePathPlaceholder = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_UNIQUEPATH_PLACEHOLDER)
    const seeDemo = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_SEE_DEMO)
    const getCreationGuide = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_GETCREATIONGUIDE)
    const agreeToText = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_AGREETOTEXT)
    const getsTC = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.COMPANY_MICROSITE_GETSTC)
    const micrositeView = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.MICROSITE_VIEW)
    const create = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.CREATE)
    const pathExistsMessage = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.MICROSITE_PATH_EXISTS)
    const update = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.UPDATE)
    const backendSuccessMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.BACKEND_SUCCESS_MSG)
    const backendErrorMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.BACKEND_ERROR_MSG)
    const maxLengthMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.MAXLENGTH)
    const characters = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.CHARACTERS)
    const exceedCharLimitMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.EXCEED_CHARLIMIT_MSG)
    const ofMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.OF_MSG)

    return {
        companyContactPersonDetailsHeader,
        contactEmail,
        contactPhone,
        invalidEmail,
        formError,
        companySpecialisationsHeader,
        specialisedIndustries,
        specialisedServices,
        companyBusinessInterestsHeader,
        interestedIndustries,
        interestedServices,
        interestedCountries,
        companyMoreAboutHeader,
        introduction,
        vision,
        presenceInCountries,
        companyMediaHeader,
        companyMediaCurrent,
        managementTeamHeader,
        addManagementUser,
        fullName,
        role,
        profileLink,
        profileLinkPlaceholder,
        currentProfilePhoto,
        noneProfilePic,
        noteForFileUploadProfilePic,
        awardsHeader,
        addAward,
        awardTitle,
        awardDescription,
        currentSuppDoc,
        noneSuppDoc,
        noteForFileUploadSuppDoc,
        remove,
        micrositeHeader,
        micrositeTextPart1,
        micrositeTextPart2,
        micrositeTextPart3,
        micrositeLookLikeText,
        micrositeNotActivatedText,
        uniquePath,
        uniquePathPlaceholder,
        seeDemo,
        getCreationGuide,
        agreeToText,
        getsTC,
        micrositeView,
        create,
        pathExistsMessage,
        update,
        backendSuccessMsg,
        backendErrorMsg,
        maxLengthMsg,
        characters,
        exceedCharLimitMsg,
        ofMsg
    }
}

export default PartyCompanyInfoTranslationText
