import { components, useServices } from 'cng-web-lib'
import { default as React, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'
import EditCompanyMicrositeFormProperties from './CompanyMicrosite_FormProperties'
import PartyCompanyInfoTranslationText from './PartyCompanyInfoTranslationText'

const {
  form: {
    CngEditForm
  },
  table: { useDefaultNotification }
} = components

function EditForm({ micrositePreviewUrl, showNotification, id, onFetchPreSuccess = () => {
  //do nothing
} }) {
  const [loading, setLoading] = useState(false);
  const { createRecord } = useServices();
  const history = useHistory();
  const fieldsTranslatedTextObject = PartyCompanyInfoTranslationText()
  const {
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const onSubmit = (dataForm, { setErrors }) => {
    setLoading(true);
    const onSuccess = (response) => {    
      setLoading(false);
      if (response.errorMessages) {
        showErrorNotification(fieldsTranslatedTextObject.pathExistsMessage);
        setErrors({ micrositePath: fieldsTranslatedTextObject.pathExistsMessage });
      } else {
        history.push({
          pathname: micrositePreviewUrl,
          state: { id: dataForm.id }
        })
      }
    }
    const onError = (error) => { 
      setLoading(false);
      console.log(`Error:["  ${JSON.stringify(error)} "]`)
    }
    createRecord.execute(
      CalistaPartyCompanyInfoApiUrls.CREATE_MICROSITE,
      dataForm,
      onSuccess,
      onError
    )
  }

  return (
    <>
      <CngEditForm
        id={id}
        formikProps={{
          initialValues: EditCompanyMicrositeFormProperties.formikProps.initialValues,
          makeValidationSchema: EditCompanyMicrositeFormProperties.formikProps.makeValidationSchema,
          onSubmit: onSubmit
        }}
        toClientDataFormat={EditCompanyMicrositeFormProperties.toClientDataFormat}
        toServerDataFormat={EditCompanyMicrositeFormProperties.toServerDataFormat}
        fetch={{
          id: id,
          url: CalistaPartyCompanyInfoApiUrls.GET_MICROSITE,
          onPreSuccess: onFetchPreSuccess
        }}
        fieldLevel='editForm'
        //for button section to appear with form fields
        renderButtonSection={() => (
          <></>
        )}
        bodySection={
          <EditCompanyMicrositeFormProperties.Fields
            loading={loading}
            showNotification={showNotification}
            id={id}
          />
        }
      />
    </>
  );
}

export default EditForm
