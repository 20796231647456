import { Card, CardContent, Grid } from '@material-ui/core'
import {
    components,
    constants,
    useTranslation,
    useServices
} from 'cng-web-lib'

import AccessLevelUserApiUrls from 'src/apiUrls/AccessLevelUserApiUrls'
import React, {useEffect, useState} from 'react'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'
import Namespace from 'src/constants/locale/Namespace'

const {
    card: { CngSimpleCardHeader },
    table: {
        CngCrudTable,
        useDefaultNotification,
        useFetchCustomLookup,
    }
} = components

const {
    locale: {
        key: { UiComponentKeys }
    },
    filter: {
        EQUAL
    }
} = constants

function AdminTablePage(props) {
    const {
        location: { pathname },
        showNotification
    } = props

    const notification = useDefaultNotification(showNotification);
    const fetchCustomLookup = useFetchCustomLookup();

    const { translate } = useTranslation([
        Namespace.UI_COMPONENT,
        Namespace.ACCESS_LEVEL_USER
    ])
    const translatedTextsObject = makeTranslatedTextsObject()
    const { fetchRecords } = useServices();
    const [partyId, setPartyId] = useState();

    useEffect(
        () => {
            getCurrentUser();
        },
        []
    );


    function getCurrentUser() {
        const onSuccess = (response) => {
            const userDTO = response.content[0];
            setPartyId(userDTO.partyId);
        }
        const onError = (error) => { console.log("Error in fetch party:[" + JSON.stringify(error) + "]") }
        const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }
        fetchRecords.execute(
            `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/ttfb-tpr-party/hdr/getCustom`,
            { customData: {} },
            onSuccess,
            onError,
            onComplete
        );
    }

    function makeTranslatedTextsObject() {
        let accessLevelUser = translate(
            Namespace.ACCESS_LEVEL_USER,
            AccessLevelUserKeys.TITLE
        )
        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            {
                nameTitleised: accessLevelUser
            }
        )
        let userProfileId = translate(
            Namespace.ACCESS_LEVEL_USER,
            AccessLevelUserKeys.USER_PROFILE_ID
        )
        let loginId = translate(
            Namespace.ACCESS_LEVEL_USER,
            AccessLevelUserKeys.LOGIN_ID
        )
        let partyIdLbl = translate(
            Namespace.ACCESS_LEVEL_USER,
            AccessLevelUserKeys.PARTY_ID
        )

        return {
            accessLevelUser,
            tableTitle,
            userProfileId,
            loginId,
            partyIdLbl
        }
    }

    const columns = [
        {
            field: 'userProfileId',
            title: translatedTextsObject.userProfileId,
            customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
                    { },
                    'content',
                    'loginId',
                    'id',
                    (error) => console.error(error)
                )
            }
        },
        {
            field: 'partyId',
            title: translatedTextsObject.partyIdLbl,
            customLookup: () => {
                return fetchCustomLookup(
                    `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                    { },
                    'content',
                    'name',
                    'id',
                    (error) => console.error(error)
                )
            }
        }
    ];

    return (
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CngCrudTable
                            {...props}
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            addRoute={`${pathname}/add`}
                            columns={columns}
                            del={{ url: AccessLevelUserApiUrls.DELETE }}
                            editRoute={`/cal-common/account-setup/access-level-user/edit`}
                            exportData={{ url: AccessLevelUserApiUrls.EXPORT }}
                            fetch={{ url: AccessLevelUserApiUrls.GET_USER_DETAIL }}
                            idAccessor='id'
                            notification={notification}
                            tableConfigurationCode="USER_GROUP_USER"
                            viewRoute={`${pathname}/view`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default AdminTablePage;
