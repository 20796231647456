import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TcalPartyCarrierCodeKeys from 'src/constants/locale/key/TcalPartyCarrierCode'
import {Card, CardContent, Grid} from '@material-ui/core'
import {
    components,
    DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import {useTranslation} from 'cng-web-lib'
import TcalPartyCarrierCodeApiUrls from "../../../apiUrls/TcalPartyCarrierCodeApiUrls";

const {
    card: {
        CngSimpleCardHeader,
    },
    form: {
        field: {
            CngTextField,
            CngSelectField
        },
    },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    carrierCode2: "",
    carrierCode3: "",
    partyId: "",
})

const FORMIK_PROPS = {
    initialValues: {...DEFAULT_INITIAL_VALUES},
    makeValidationSchema: makeValidationSchema
}

function Fields({disabled, showNotification, shouldHideMap}) {
    const {translate} = useTranslation(Namespace.TCAL_PARTY_CARRIER_CODE)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let tcalPartyCarrierCode = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.TITLE
        )
        let createdBy = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.CREATED_BY
        )
        let createdDate = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.CREATED_DATE
        )
        let updatedBy = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.UPDATED_BY
        )
        let updatedDate = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.UPDATED_DATE
        )
        let partyCcId = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.PARTY_CC_ID
        )
        let carrierCode2 = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.CARRIER_CODE2
        )
        let carrierCode3 = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.CARRIER_CODE3
        )
        let partyId = translate(
            Namespace.TCAL_PARTY_CARRIER_CODE,
            TcalPartyCarrierCodeKeys.PARTY_ID
        )

        return {
            tcalPartyCarrierCode,
            createdBy,
            createdDate,
            updatedBy,
            updatedDate,
            partyCcId,
            carrierCode2,
            carrierCode3,
            partyId
        }
    }

    return (
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tcalPartyCarrierCode}/>
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode2}>
                        <CngTextField
                            name="carrierCode2"
                            label={translatedTextsObject.carrierCode2}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode3}>
                        <CngTextField
                            name="carrierCode3"
                            label={translatedTextsObject.carrierCode3}
                            disabled={disabled}
                        />
                    </CngGridItem>

                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
                        <CngSelectField
                            name="partyId"
                            label={translatedTextsObject.partyId + " *"}
                            disabled={disabled}
                            fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`, textAccessor: "name"}}
                        />
                    </CngGridItem>

                </Grid>
            </CardContent>
        </Card>
    )
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    return localData;
}

function toServerDataFormat(localData) {
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
