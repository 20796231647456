import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import TtfbTprPartyApiUrls from 'src/apiUrls/TtfbTprPartyApiUrls'
import React from 'react'
import TtfbTprPartyKeys from 'src/constants/locale/key/TtfbTprParty'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TTFB_TPR_PARTY
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let ttfbTprParty = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ttfbTprParty
      }
    )
    let partyId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.PARTY_ID
    )
    let partyName = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.PARTY_NAME
    )

    return {
      ttfbTprParty,
      tableTitle,
      partyId,
      partyName
    }
  }

  const columns = [
    /*{
      field: "partyId",
      title: translatedTextsObject.partyId,
      type: "numeric",
    },*/
    {
      field: "name",
      title: translatedTextsObject.partyName,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: TtfbTprPartyApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: TtfbTprPartyApiUrls.EXPORT }}
              fetch={{ url: TtfbTprPartyApiUrls.GET_BY_PARTY_SERVICE }}
              idAccessor="id"
              notification={notification}
              tableConfigurationCode="USER_GROUP_COMPANY"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
