import {
  components,
  useTranslation
} from 'cng-web-lib'

import CompanyUserGroupFormProperties from './CompanyUserGroupFormProperties'
import CompanyUserGroupApiUrls from 'src/apiUrls/CompanyUserGroupApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'


const {
  table: {
    CngServerModeDialogFormTable,
    useFetchCustomLookup
  }
} = components


function CompanyUserGroupCrudTable({
  showNotification,
  companyForUserGroupId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.COMPANY_FOR_USER_GROUP])
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCustomLookup = useFetchCustomLookup();

  let create = { url: CompanyUserGroupApiUrls.POST }
  let update = { url: CompanyUserGroupApiUrls.PUT }
  let del = { url: CompanyUserGroupApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let userGroupMasterId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.USER_GROUP_MASTER_ID
    )
    let shipperRequestTypeId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.SHIPPER_REQUEST_TYPE_ID
    )

    return {
      userGroupMasterId,
      shipperRequestTypeId
    }
  }

  const columns = [
    {
      field: 'userGroupMasterId',
      title: translatedTextsObject.userGroupMasterId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/get`,
          { },
          'content',
          'customerGroupName',
          'id',
          (error) => console.error(error)
        )
      }
    }/*,
    {
      field: 'shipperRequestTypeCodeList',
      title: translatedTextsObject.shipperRequestTypeId
    }*/
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...CompanyUserGroupFormProperties,
        formikProps: {
          ...CompanyUserGroupFormProperties.formikProps,
          initialValues: {
            ...CompanyUserGroupFormProperties.formikProps.initialValues,
            companyForUserGroupId: companyForUserGroupId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum;
      }}
      fetch={{
        url: CompanyUserGroupApiUrls.GET,
        body: {
          companyForUserGroupId: companyForUserGroupId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default CompanyUserGroupCrudTable
