import CarbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'
import { transformData } from '../ultls'
export const OPTION_LOCATION = [
  { label: 'UN Locode', value: 'unLocode' },
  { label: 'IATA code', value: 'iataCode' },
  { label: 'Railway Station', value: 'uicCode' }
]
export const NUMBER_FORMAT_FIX = 3
export const FLAG_NS = ['TW', 'TWTPE', 'TWNTC']
export const FLAG_TW = 'TW'
export const SCREEN_VIEW = 1700
export const SCROLL_WIDTH = '1700px'

export const CHART_OPTION = {
  offsetGridLines: true,
  drawTicks: true,
  layout: {
    padding: {
      top: 30,
      right: 40,
      bottom: 40
    }
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, { datasets }) => {
        const { value, datasetIndex } = tooltipItem
        return `${datasets[datasetIndex]?.label}: ${(+value).toLocaleString(
          'en-US'
        )}`
      }
    }
  },
  legend: {
    display: false,
    position: 'right',
    alight: 'start',
    labels: {
      usePointStyle: true
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false
        },
        ticks: {
          fontFamily: 'FontAwesome',
          padding: 5
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
          padding: 20,
          callback(n) {
            if (n < 1e2) return n + '    '
            if (n < 1e3) return n
            if (n >= 1e3 && n < 1e6) return (n / 1e3).toFixed(1) + 'K'
            if (n >= 1e6) return (n / 1e6).toFixed(2) + 'M'
          }
        }
      }
    ]
  }
}
export const EMISSION_DETAIL = {
  co2Equivalents: 'CO2 equivalents t',
  distances: 'Distances KM',
  energyConsumption: 'Energy consumption MJ',
  carbonDioxide: 'CO2 emissions t',
  nitrogenOxides: 'Nitrogen oxides KG',
  nonMethanHydroCarbons: 'Non-methane hydrocarbons KG',
  sulfurDioxides: 'Sulfur dioxides KG',
  particles: 'Particular matter KG'
}

export const EMISSION_TITLE = {
  co2Equivalents: 'CO2 equivalents',
  distances: 'Distances',
  energyConsumption: 'Energy consumption',
  carbonDioxide: 'CO2 emissions',
  nitrogenOxides: 'Nitrogen oxides',
  nonMethanHydroCarbons: 'Non-methane hydrocarbons',
  sulfurDioxides: 'Sulfur dioxides',
  particles: 'Particular matter'
}
export const EMISSION_DESCRIPTION = {
  co2Equivalents: 'Green house gas emissions as COe2',
  distances: 'Distances per transport mode',
  energyConsumption: 'Energy resource consumption',
  carbonDioxide: 'Climate impact',
  nitrogenOxides: 'Acidification, overfertilisation, smog',
  nonMethanHydroCarbons: 'Smog, damage caused to health',
  sulfurDioxides: 'Acidification, damage caused to health',
  particles: 'Combustion related'
}

export const TRANSPORTATION = {
  truck: '#d0d5df',
  air: '#FAC575',
  sea: '#637EAD',
  less: '#007a33',
  train: '#00b5b2',
  barge: '#03804e',
  ocean: '#637EAD',
  airValueWtT: '#FAC575',
  airValueTtW: '#FB9700',
  bargeValueWtT: '#03804e',
  bargeValueTtW: '#015936',
  oceanValueWtT: '#637EAD',
  oceanValueTtW: '#2555a8',
  trainValueWtT: '#00b5b2',
  trainValueTtW: '#03807d',
  truckValueWtT: '#d0d5df',
  truckValueTtW: '#8996af'
}
export const TRANSPORTATION_LABEL = {
  truck: 'Truck transport',
  air: 'Air transport',
  sea: 'Sea transport',
  less: 'Less esmissions',
  train: 'Train transport ',
  barge: 'Barge transport',
  ocean: 'Sea transport',
  airValueTtW: 'TtW - Air',
  airValueWtT: ' WtT - Air',
  bargeValueTtW: 'TtW - barge',
  bargeValueWtT: 'WtT - barge',
  oceanValueTtW: 'TtW - Sea',
  oceanValueWtT: 'WtT - Sea',
  trainValueTtW: 'TtW - Train',
  trainValueWtT: 'WtT - Train ',
  truckValueTtW: 'TtW - Truck',
  truckValueWtT: 'WtT - Truck'
}
export const RADIO_CONFIG = [
  { icon: ['fal', 'ship'], name: 'ocean', label: 'Ocean' },
  { icon: ['fal', 'plane'], name: 'air', label: 'Air' },
  { icon: ['fal', 'train'], name: 'rail', label: 'Rail' },
  { icon: ['fal', 'truck'], name: 'truck', label: 'Truck' },
  {
    icon: ['fal', 'conveyor-belt'],
    name: 'barge',
    label: 'Barge'
  }
]

export const SEA_CHECKED = [
  {
    type: 'select',
    options: [
      { label: 'Agregated', value: 'Agregated' },
      { label: 'Dry', value: 'Dry' },
      { label: 'Liquid', value: 'Liquid' },
      { label: 'Container', value: 'Container' },
      { label: 'Aggregated container', value: 'ContainerAgregated' }
    ],
    name: 'shipClass',
    label: 'Cargo type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      {
        label: 'BC Great Lakes Ship',
        value: 'bc-gl-30',
        rightLabel: '<30k dwt'
      },
      {
        label: 'BC Intra-continental',
        value: 'bc-ic-a-35',
        rightLabel: '<35k dwt'
      },
      {
        label: 'BC Panama trade',
        value: 'bc-pa-a-80',
        rightLabel: '35-80k dwt'
      },
      {
        label: 'BC Transatlantic trade',
        value: 'bc-ta-a-80',
        rightLabel: '35-80k dwt'
      },
      {
        label: 'BC Transpacific trade',
        value: 'bc-tp-a-200',
        rightLabel: '35-200k dwt'
      },
      { label: 'BC Suez trade', value: 'bc-sz-200', rightLabel: '80-200k dwt' },
      {
        label: 'BC Other global trade',
        value: 'bc-og-a-120',
        rightLabel: '35-120k dwt'
      },
      {
        label: 'BC Global Average World',
        value: 'bc-world-avg',
        rightLabel: ''
      },
      { label: 'Roro small', value: 'roro-lt-5k', rightLabel: '< 5k dwt' },
      { label: 'Roro large', value: 'roro-gt-5k', rightLabel: '>= 5k dwt' },
      { label: 'GC Coastal', value: 'gc-co-d-5', rightLabel: '<5k dwt' },
      { label: 'GC/BC Feeder', value: 'bc-fe-d-15', rightLabel: '5-15k dwt' },
      {
        label: 'GC/BC Handysize',
        value: 'bc-hs-d-30',
        rightLabel: '15-30k dwt'
      },
      { label: 'BC Handymax', value: 'bc-hm-d-60', rightLabel: '35-60k dwt' },
      { label: 'BC Panamax', value: 'bc-pm-d-80', rightLabel: '60-80k dwt' },
      { label: 'BC Adramax', value: 'bc-am-d-120', rightLabel: '80-120k dwt' },
      { label: 'BC Suezmax', value: 'bc-sm-d-200', rightLabel: '120-200k dwt' },
      { label: 'T Feeder', value: 'bc-fe-l-15', rightLabel: '5-15k dwt' },
      { label: 'T Handysize ', value: 'bc-hs-l-30', rightLabel: '15-30k dwt' },
      { label: 'T Handymax', value: 'bc-hm-l-60', rightLabel: '35-60k dwt' },
      { label: 'T Panamax', value: 'bc-pm-l-80', rightLabel: '60-80k dwt' },
      { label: 'T Adramax', value: 'bc-am-l-120', rightLabel: '80-120k dwt' },
      { label: 'T Suezmax', value: 'bc-sm-l-200', rightLabel: '120-200k dwt' },
      { label: 'T VLCC', value: 'bc-vl-l-200', rightLabel: '>200k dwt' },
      {
        label: 'CC Intra-continental EU',
        value: 'cc-ic-eu-a-20',
        rightLabel: '0,5-2k TEU'
      },
      {
        label: 'CC Intra-continental non-EU',
        value: 'cc-ic-a-35',
        rightLabel: '0,5-3,5k TEU'
      },
      {
        label: 'CC Panama Trade',
        value: 'cc-pa-a-47',
        rightLabel: '2-4,7k TEU'
      },
      {
        label: 'CC Transatlantic trade',
        value: 'cc-ta-a-47',
        rightLabel: '2-4,7k TEU'
      },
      {
        label: 'CC Transspacific trade',
        value: 'cc-tp-a-70',
        rightLabel: '1-7k TEU'
      },
      { label: 'CC Suez trad', value: 'cc-sz-a-70', rightLabel: '4,7-7k TEU' },
      {
        label: 'CC Other global trade',
        value: 'cc-og-a-47 ',
        rightLabel: '1-4,7k TEU'
      },
      { label: 'CC Feeder', value: 'cc-fe-10', rightLabel: '<1k TEU' },
      { label: 'CC Handysize ', value: 'cc-hs-20', rightLabel: '1-2k TEU' },
      { label: 'CC Handymax', value: 'cc-hm-35', rightLabel: '2-3,5k TEU' },
      { label: 'CC Panamax', value: 'cc-pm-47', rightLabel: '3,5-4,7k TEU' },
      { label: 'CC Aframax', value: 'cc-am-70', rightLabel: '4,7-7k TEU' },
      { label: 'CC Suezmax', value: 'cc-sm-145', rightLabel: '>7k TEU' },
      {
        label: 'CC Ultra Large Vehicle Container (ULVC)',
        value: 'cc-sm-ulcv-145',
        rightLabel: '>14,5k TEU'
      },
      {
        label: 'CC Global Average World',
        value: 'cc-world-avg',
        rightLabel: ''
      }
    ],
    name: 'shipType',
    label: 'Ship type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      { label: '<=3.5', value: 'Class3_5', rightLabel: 'tonnes' },
      { label: '6.5-7.5', value: 'Class7_5', rightLabel: 'tonnes' },
      { label: '7.5-12', value: 'Class12', rightLabel: 'tonnes' },
      { label: '12-20', value: 'Class20', rightLabel: 'tonnes' },
      { label: '20-26', value: 'Class26', rightLabel: 'tonnes' },
      { label: '26-40', value: 'Class40', rightLabel: 'tonnes' },
      { label: '40-60', value: 'Class60', rightLabel: 'tonnes' },
      { label: '-', value: 'Class40', rightLabel: '' },
      { label: 'Standard wagon', value: 'trainStandardWagon', rightLabel: '' },
      { label: 'Only trailer', value: 'trailerOnly', rightLabel: '' }
    ],
    name: 'roRoVehicle',
    label: 'RoRo Vehicle',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 0, max: 100 },
    name: 'speedReduction',
    label: 'Speed reduction',
    labelright: '%',
    xs: 4,
    typeInput: 'number'
  },
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'loadFactor',
    label: 'Load factor',
    labelright: '%',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'roRoVehicleLoad',
    label: 'RoRo Vehicle load',
    labelright: '%',
    xs: 4
  }
]
export const AIR_CHECKED = [
  { type: 'checkbox', name: 'bellyFreight', label: 'Belly freight', xs: 3 },
  {
    type: 'select-api',
    url: CarbonFootprintApiUrls.GET_FREIGHT_CODE,
    bodyRequest: [
      {
        value: 'aircraftCodeType',
        field: 'codeType'
      }
    ],
    dataOptions: (data = []) => {
      return data.map((item) => {
        return {
          label: transformData(item.descriptionEn, 0),
          code: item?.code,
          descriptionEn: item?.descriptionEn,
          rightLabel: transformData(item.descriptionEn, 1)
        }
      })
    },
    showChip: true,
    name: 'airplaneType',
    label: 'Aircraft type',
    xs: 5
  },
  {
    type: 'input',
    rules: { min: 0, max: 100 },
    options: [],
    name: 'passageUtilization',
    label: 'Passage utilization',
    xs: 4,
    typeInput: 'number'
  },
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'loadFactor',
    label: 'Load factor',
    xs: 3
  },
  {
    type: 'select',
    options: [
      { label: 'EN 16258', value: 'None' },
      { label: 'ICAO/IATA', value: 'Icao' }
    ],
    name: 'allocationMethod',
    label: 'Allocation method',
    xs: 5
  }
]
export const RAIL_CHECKED = [
  {
    type: 'select',
    options: [
      { label: 'Default train', value: 'none', rightLabel: '1000 t' },
      { label: 'Light train', value: 'light', rightLabel: '500 t' },
      {
        label: 'Average train',
        value: 'average',
        rightLabel: '1000 t'
      },
      { label: 'Large train', value: 'large', rightLabel: '1500 t' },
      { label: 'Extra-large train', value: 'extraLarge', rightLabel: '2000 t' },
      { label: 'Heavy train', value: 'heavy', rightLabel: '5000 t' },
      { label: 'Car train', value: 'car', rightLabel: '700 t' },
      { label: 'Chemistry train', value: 'chemistry', rightLabel: '1200 t' },
      { label: 'Container train', value: 'container', rightLabel: '1000 t' },
      {
        label: 'Coal and steel train',
        value: 'coalAndSteel',
        rightLabel: '1700 t'
      },
      {
        label: 'Building materials train',
        value: 'buildingMaterials',
        rightLabel: '1200 t'
      },
      {
        label: 'Manufactured products train',
        value: 'manufacturedProducts',
        rightLabel: '1200 t'
      },
      { label: 'Cereals train', value: 'cereals', rightLabel: '1300 t' },
      {
        label: 'Freight on a highspeed train',
        value: 'highspeed',
        rightLabel: '500 t'
      },
      {
        label: 'Single waggon train',
        value: 'singleWaggon',
        rightLabel: '1000 t'
      },
      { label: 'Feeder train', value: 'feeder', rightLabel: '600 t' },
      {
        label: 'Double stack container train',
        value: 'doubleStackContainer',
        rightLabel: '1000 t'
      },
      {
        label: 'Rolling Road train',
        value: 'rollingRoad',
        rightLabel: '1000 t'
      },
      {
        label: 'Manufactured Products train',
        value: '10',
        rightLabel: '1200 t'
      },
      { label: 'Cereals train', value: '11', rightLabel: '1300 t' }
    ],
    name: 'trainType',
    label: 'Train type',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 1 },
    options: [],
    name: 'trainWeight',
    label: 'Train weight',
    chip: 't',
    xs: 4,
    typeInput: 'number'
  },
  {
    type: 'select',
    options: [
      { label: 'EU UIC 1', value: 'EU_UIC_1' },
      { label: 'EU UIC 2', value: 'EU_UIC_2' },
      { label: 'EU Stage 3a ', value: 'EU_STAGE_3_A' },
      { label: 'EU Stage 3bv', value: 'EU_STAGE_3_BV' },
      { label: 'DB 2016', value: 'DB_2016' },
      { label: 'EPA NR', value: 'EPA_NR' },
      { label: 'EPA Tier 0', value: 'EPA_TIER_0' },
      { label: 'EPA Tier 1', value: 'EPA_TIER_1' },
      { label: 'EPA Tier 2', value: 'EPA_TIER_2' },
      { label: 'EPA Tier 3', value: 'EPA_TIER_3' },
      { label: 'EPA Tier 4', value: 'EPA_TIER_4' }
    ],
    name: 'emissionStandard',
    label: 'Emission standard',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'loadFactor',
    label: 'Load factor',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 0 },
    name: 'emptyRunFactor',
    label: 'Empty run factor',
    xs: 4,
    typeInput: 'number'
  },
  {
    type: 'select',
    options: [
      { label: 'Diesel', value: 'diesel' },
      { label: 'Electrified', value: 'electrical' },
      { label: 'Diesel As Electric', value: 'dieselAsElectric' }
    ],
    name: 'traction',
    label: 'Traction',
    xs: 4
  },
  { type: 'checkbox', name: 'particleFilter', label: 'Particle filter', xs: 4 },
  { type: 'checkbox', name: 'shunting', label: 'Shunting', xs: 4 }
]
export const TRUCK_CHECKED = [
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'loadFactor',
    label: 'Load factor',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 0 },
    name: 'emptyTripFactor',
    label: 'Empty trip factor',
    xs: 4,
    typeInput: 'number'
  },
  {
    type: 'select',
    options: [
      { label: '<= 3.5 t (LCV)', value: 'Class3_5' },
      { label: '3.5 - 7.5 t', value: 'Class7_5' },
      { label: '7.5 – 12 t', value: 'Class12' },
      { label: '12 – 20 t', value: 'Class20' },
      { label: '20 – 26 t', value: 'Class26' },
      { label: '26 – 40 t', value: 'Class40' },
      { label: '40 – 50 t', value: 'Class50' },
      { label: '50 – 60 t', value: 'Class60' },
      { label: '> 60 t', value: 'ClassGt60' },
      { label: '< 16 klbs', value: 'Class16Klbs' },
      { label: '16 – 26 klbs', value: 'Class26Klbs' },
      { label: '26 – 60 klbs', value: 'Class60Klbs' },
      { label: '60 – 80 klbs', value: 'Class80Klbs' },
      { label: '80 – 100 klbs', value: 'Class100Klbs' },
      { label: '100 – 130 klbs', value: 'Class130Klbs' },
      { label: '> 130 klbs', value: 'ClassGt130Klbs' }
    ],
    name: 'vehicleType',
    label: 'Vehicle type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      { label: 'Diesel', value: 'diesel' },
      { label: 'CNG', value: 'cng' },
      { label: 'LNG', value: 'lng' },
      { label: 'BEV', value: 'bev' }
    ],
    name: 'fuelType',
    label: 'Fuel type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      { label: 'EURO 1', value: 'EuEuro1' },
      { label: 'EURO 2', value: 'EuEuro2' },
      { label: 'EURO 3', value: 'EuEuro3' },
      { label: 'EURO 4', value: 'EuEuro4' },
      { label: 'EURO 5', value: 'EuEuro5' },
      { label: 'EURO 6', value: 'EuEuro6' },
      { label: 'EPA 1994', value: 'UsEpa1994' },
      { label: 'EPA 1998', value: 'UsEpa1998' },
      { label: 'EPA 2004', value: 'UsEpa2004' },
      { label: 'EPA 2007', value: 'UsEpa2007' },
      { label: 'EPA 2010', value: 'UsEpa2010' },
      { label: 'JP 1994', value: 'Jp1994' },
      { label: 'JP 1997', value: 'Jp1997' },
      { label: 'JP 2003', value: 'Jp2003' },
      { label: 'JP 2005', value: 'Jp2005' },
      { label: 'JP 2009', value: 'Jp2009' },
      { label: 'JP 2016', value: 'Jp2016' }
    ],
    name: 'emissionStandard',
    label: 'Emission standard',
    xs: 4
  },
  {
    type: 'select',
    options: [
      { label: 'Cooled Box', value: 'CooledBox' },
      { label: 'Reefer Regulated', value: 'ReeferRegulated' },
      { label: 'Reefer Unregulated', value: 'ReeferUnregulated' },
      { label: '-', value: 'Auto' }
    ],
    name: 'coolingUnit',
    label: 'Cooling unit',
    xs: 4
  }
]
export const FEEDER_CHECKED = [
  {
    type: 'select',
    options: [
      { label: 'Container based', value: 'Container' },
      { label: 'Bulk based', value: 'NonContainer' }
    ],
    name: 'CargoType',
    label: 'Cargo Type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      {
        label: 'Euro ship bulk I-IV',
        value: 'ib-eu-bu',
        rightLabel: '0-1500t CAPACITY'
      },
      {
        label: 'Large inland freight vessel bulk V',
        value: 'ib-gr-bu',
        rightLabel: '(1500-3000t CAPACITY'
      },
      {
        label: 'Jowi class bulk VI+',
        value: 'ib-jo-bu',
        rightLabel: '>3000t CAPACITY'
      },
      {
        label: 'Euro ship container I-IV',
        value: 'ib-eu-ct',
        rightLabel: '0-1500t CAPACITY'
      },
      {
        label: 'Large inland freight vessel container V',
        value: 'ib-gr-ct',
        rightLabel: '1500-3000t CAPACITY'
      },
      {
        label: 'Jowi class container VI+',
        value: 'ib-jo-ct',
        rightLabel: '>3000t CAPACITY'
      },
      {
        label: 'PushConvoi 2x2 bulk',
        value: 'ib-pc-2x2-bu',
        rightLabel: 'approx. 11200t CAPACITY'
      },
      {
        label: 'PushConvoi 2x2 container',
        value: 'ib-pc-2x2-ct',
        rightLabel: 'approx. 11200t CAPACITY'
      }
    ],
    name: 'shipType',
    label: 'Ship type',
    xs: 4
  },
  {
    type: 'select',
    options: [
      {
        label: 'Conventional (1970-2002) / US Tier 1',
        value: 'Conv_1970_2002_USTier1'
      },
      { label: 'CCNR I (2002-2006)', value: 'CCNR1_2002_2006' },
      {
        label: 'CCNR II/ EU IIIA / US Tier 2 (>2006)',
        value: 'CCNR2_EU3A_USTier2_2006'
      },
      { label: 'US Tier 3', value: 'EPATier3' },
      { label: 'US Tier 4', value: 'EPATier4' }
    ],
    name: 'emissionStandard',
    label: 'Emission standard',
    xs: 4
  },
  {
    type: 'input',
    rules: { min: 1, max: 100 },
    name: 'loadFactor',
    label: 'Load factor',
    xs: 4
  }
]
export const RADIO_CHECKED = {
  ocean: SEA_CHECKED,
  air: AIR_CHECKED,
  rail: RAIL_CHECKED,
  truck: TRUCK_CHECKED,
  barge: FEEDER_CHECKED
}
export const DEFEAULT_VALUE = {
  ocean: {
    shipClass: '',
    shipType: '',
    roRoVehicle: '',
    speedReduction: '',
    loadFactor: '',
    roRoVehicleLoad: ''
  },
  air: {
    bellyFreight: false,
    passageUtilisation: '',
    airplaneType: '',
    loadFactor: '',
    allocationMethod: ''
  },
  rail: {
    trainType: '',
    trainWeight: '',
    loadFactor: '',
    emptyTripFactor: '',
    traction: '',
    emissionStandard: '',
    particleFilter: false,
    shunting: false
  },
  truck: {
    vehicleType: '',
    fuelType: '',
    emissionStandard: '',
    loadFactor: '',
    emptyTripFactor: '',
    coolingUnit: ''
  },
  barge: {
    shipType: '',
    emissionStandard: '',
    loadFactor: ''
  }
}
export const VIA_CONFIX = [
  {
    type: 'select',
    options: OPTION_LOCATION,
    name: 'viaLocationType',
    label: 'Location type*',
    rules: { required: 'Please select value' },
    xs: 6
  },
  {
    type: 'port',
    options: [],
    name: 'transportRequest',
    fName: 'viaLocationType',
    label: 'Location*',
    xs: 6
  }
]
