import React from 'react'
import { Grid, Button } from '@material-ui/core'
import moment from 'moment'
import { components, useServices } from 'cng-web-lib'
import { useForm, FormProvider } from 'react-hook-form'
import Loading from '../components/Loading'
import CarbonFootprintApiUrls from 'src/apiUrls/CarbonFootprintApiUrls'
import Utils from 'src/views/common/utils/Utils'

const {
  form: {
    field: { CngDateTimeField }
  }
} = components
const AuditLog = () => {
  const SG_DATE_TIME_FORMAT = Utils.UI_FORMAT_DATE_TIME
  const SG_DATE_FORMAT = Utils.UI_FORMAT_DATE
  const { securedSendRequest } = useServices()
  const [loading, setLoading] = React.useState(false)

  const methods = useForm({
    defaultValues: {}
  })
  const onSubmit = (values) => {
    setLoading(true)
    const { startDate, endDate } = values
    securedSendRequest.execute(
      'POST',
      CarbonFootprintApiUrls.EXPORT_AUDIT_LOG,
      {
        startDate: moment(startDate, SG_DATE_TIME_FORMAT).toDate().getTime(),
        endDate: moment(endDate, SG_DATE_TIME_FORMAT).toDate().getTime()
      },
      (exportData) => {
        console.log('success')
        console.log(exportData)
        let blob = exportData.data
        let timeStamp = new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        let fileName = `AuditLog_${timeStamp}.csv`
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        document.body.appendChild(link)
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      (error) => {
        console.log(error)
      },
      () => {
        setLoading(false)
      },
      { responseType: 'blob' }
    )
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Loading loading={loading}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CngDateTimeField
                label={'Start Date'}
                name='startDate'
                format={SG_DATE_TIME_FORMAT}
                shouldDisableDate={(date) => {
                  return moment(moment().format(SG_DATE_FORMAT)).isBefore(
                    moment(date)
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CngDateTimeField
                label={'End Date'}
                name='endDate'
                format={SG_DATE_TIME_FORMAT}
                defaultValue={moment()}
                shouldDisableDate={(date) => {
                  return moment(moment().format(SG_DATE_FORMAT)).isBefore(
                    moment(date)
                  )
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                type='submit'
                style={{
                  height: '56px',
                  background: '#5E81F4',
                  borderRadius: '8px',
                  color: '#ffffff',
                  width: '121px'
                }}
                variant='contained'
              >
                Generate Log
              </Button>
            </Grid>
          </Grid>
        </Loading>
      </form>
    </FormProvider>
  )
}
export default AuditLog
