import AccessLevelCompanyCustomRuleFormProperties from './AccessLevelCompanyCustomRuleFormProperties'
import AccessLevelCompanyCustomRuleApiUrls from 'src/apiUrls/AccessLevelCompanyCustomRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function AccessLevelCompanyCustomRuleAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AccessLevelCompanyCustomRuleFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelCompanyCustomRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelCompanyCustomRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelCompanyCustomRuleFormProperties.toServerDataFormat}
      create={{
        url: AccessLevelCompanyCustomRuleApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default AccessLevelCompanyCustomRuleAddForm
