import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { FlightOutlined, TrainOutlined } from '@material-ui/icons'

export function mappingIcon(iconName, index) {
  console.log('iconName', iconName)
  switch (iconName) {
    case 'truck':
      return (
        <Avatar key={index} className='avatar'>
          <FontAwesomeIcon icon={['far', 'truck']} />
        </Avatar>
      )
    case 'train':
    case 'rail':
      return (
        <Avatar key={index} className='avatar'>
          <TrainOutlined />
        </Avatar>
      )
    case 'air':
      return (
        <Avatar key={index} className='avatar'>
          <FlightOutlined
            style={{
              transform: 'rotate(90deg)'
            }}
          />
        </Avatar>
      )
    case 'ocean':
    case 'sea':
      return (
        <Avatar key={index} className='avatar'>
          <FontAwesomeIcon icon={['fal', 'ship']} />
        </Avatar>
      )
    default:
      return (
        <Avatar key={index} className='avatar'>
          <FontAwesomeIcon icon={['fal', 'conveyor-belt']} />
        </Avatar>
      )
  }
}

const AvatarsComponent = ({ listAvartar = [] }) => {
  return (
    <AvatarGroup
      max={window.screen.width < 1450 ? 1 : 3}
      className='avatar-component'
      spacing={'mediumee'}
    >
      {listAvartar?.map((element, index) => {
        return mappingIcon(element?.toLocaleLowerCase(), index)
      })}
    </AvatarGroup>
  )
}
export default AvatarsComponent
