import AccessLevelCompanyCustomRuleFormProperties from './AccessLevelCompanyCustomRuleFormProperties'
import AccessLevelCompanyCustomRuleApiUrls from 'src/apiUrls/AccessLevelCompanyCustomRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function AccessLevelCompanyCustomRuleEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("access level company custom rule edit form")}
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelCompanyCustomRuleFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelCompanyCustomRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelCompanyCustomRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelCompanyCustomRuleFormProperties.toServerDataFormat}
      fetch={{
        url: AccessLevelCompanyCustomRuleApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: AccessLevelCompanyCustomRuleApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default AccessLevelCompanyCustomRuleEditForm
