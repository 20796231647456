import React from 'react'
import {Grid} from '@material-ui/core'
import { components, useTranslation, DataFlattener } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import makeValidationSchema from './MakeValidationSchema'
import NBSBillingConfigKeys from "../../../../constants/locale/key/NBSBillingConfig";

const {
    form: { field: { CngCodeMasterAutocompleteField, CngTextField, CngDateField, CngSwitchField, CngSelectField }},
    CngGridItem
}=components

//* Field default values
const DEFAULT_INITIAL_VALUES = Object.freeze({
    partyId:"",
    nbsAcctId:"",
    chargeCode:"",
    firstPosting: "",
    lastPosting: new Date("2050-01-01"),
    active:true
})

const FORMIK_PROPS = {
    initialValues:{...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({disabled, shouldHideMap}){

    const { translate } = useTranslation(Namespace.NBS_BILLING_CONFIG)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject(){

        let partyId = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.PARTY_ID)
        let nbsAcctId = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.ACCT_ID)
        let chargeCode = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.CHARGE_CODE)
        let firstPosting = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.FIRST_POSTING)
        let lastPosting = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.LAST_POSTING)
        let active = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.ACTIVE)

        return{
            partyId,
            nbsAcctId,
            chargeCode,
            firstPosting,
            lastPosting,
            active
        }
    }

    //* return JSX
    return(
        <Grid container spacing={3}>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
                <CngSelectField name="partyId"
                              label={translatedTextsObject.partyId}
                              disabled={disabled}
                              fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`, textAccessor: "name"}}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nbsAcctId}>
                <CngTextField name="nbsAcctId"
                              label={translatedTextsObject.nbsAcctId}
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCode}>
                <CngCodeMasterAutocompleteField name="chargeCode"
                              label={translatedTextsObject.chargeCode}
                              disabled={disabled}
                              codeType='CAL_NBS_CHARGECODE'
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstPosting}>
                <CngDateField name="firstPosting"
                              label={translatedTextsObject.firstPosting}
                              format='YYYY-MM-DD'
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastPosting}>
                <CngDateField name="lastPosting"
                              label={translatedTextsObject.lastPosting}
                              format='YYYY-MM-DD'
                              disabled={disabled}
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
                <CngSwitchField name="active"
                                label={translatedTextsObject.active}
                                disabled={disabled}
                                required
                />
            </CngGridItem>

        </Grid>
        //* End JSX
    )
    //* End of function
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData)
    return localData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties