import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TtfbTprPartyKeys from 'src/constants/locale/key/TtfbTprParty'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import TcalAsUserGroupCompanyFormProperties from './TcalAsUserGroupCompanyFormProperties'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  table: {
    CngLocalModeDialogFormTable,
    useFetchCustomLookup
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
  id: 0,
  partyName: "",
  tcalAsUserGroupCompany: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, id }) {
  const { translate } = useTranslation(Namespace.TTFB_TPR_PARTY)
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCustomLookup = useFetchCustomLookup()

  function makeTranslatedTextsObject() {
    let ttfbTprParty = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TITLE
    )
    let partyId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.PARTY_ID
    )
    let idLbl = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.PARTY_ID
    )
    let partyName = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.PARTY_NAME
    )
    let tcalAsUserGroupCompany = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.TITLE
    )
    let userGroupCompanyId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.USER_GROUP_COMPANY_ID
    )
    let userGroupMasterId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.USER_GROUP_MASTER_ID
    )
    let createdBy = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.UPDATED_DATE
    )

    return {
      ttfbTprParty,
      partyId,
      idLbl,
      partyName,
      tcalAsUserGroupCompany,
      userGroupCompanyId,
      userGroupMasterId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.ttfbTprParty} />
          <CardContent>
            <Grid container spacing={3}>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
                <CngTextField
                  name="partyId"
                  type="number"
                  label={translatedTextsObject.partyId + " *"}
                  disabled={disabled}
                />
              </CngGridItem>*/}
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
                <CngSelectField
                  name="id"
                  label={translatedTextsObject.partyName}
                  disabled={true}
                  fetch={
                    {
                      url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                      textAccessor: "name",
                      body: {
                        id: id
                      }
                    }
                  }
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.tcalAsUserGroupCompany} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="tcalAsUserGroupCompany"
              tableColumns={[
                /*{
                  title: translatedTextsObject.userGroupCompanyId,
                  field: "userGroupCompanyId",
                  type: "numeric",
                },*/

                /*{
                title: translatedTextsObject.partyId,
                field: "ttfbTprPartyId",
                type: "numeric",
              },*/
                {
                  field: "ttfbTprPartyId",
                  title: 'Party Name',
                  customLookup: () => {
                    return fetchCustomLookup(
                      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                      { },
                      'content',
                      'name',
                      'id',
                      (error) => console.error(error)
                    )
                  }
                },

                /*{
                  title: translatedTextsObject.userGroupMasterId,
                  field: "userGroupMasterId",
                  type: "numeric",
                },*/
                {
                  field: "userGroupMasterId",
                  title: 'User Group Name',
                  customLookup: () => {
                    return fetchCustomLookup(
                      `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/get`,
                      { },
                      'content',
                      'customerGroupName',
                      'id',
                      (error) => console.error(error)
                    )
                  }
                },

                /*{
                  title: translatedTextsObject.createdBy,
                  field: "createdBy",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.createdDate,
                  field: "createdDate",
                },
                {
                  title: translatedTextsObject.updatedBy,
                  field: "updatedBy",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.updatedDate,
                  field: "updatedDate",
                },*/
              ]}
              formProperties={{
                ...TcalAsUserGroupCompanyFormProperties,
                formikProps: {
                  ...TcalAsUserGroupCompanyFormProperties.formikProps,
                  initialValues: {
                    ...TcalAsUserGroupCompanyFormProperties.formikProps.initialValues,
                    ttfbTprPartyId: id
                  }
                },
                id: id
              }}
              initialData={DEFAULT_INITIAL_VALUES.tcalAsUserGroupCompany}
              isViewOnly={disabled}
            //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
