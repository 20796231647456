import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {
	
	return Yup.object({
		sysIntegrationUserId: Yup.number().integer(),
		
		pingContLifecycleStartBuffer: Yup.number().integer().min(0).max(2147483647),
		pingContLifecycleEndBuffer: Yup.number().integer().min(0).max(2147483647),			
		pingContainerMilestoneCount: Yup.number().integer().min(0).max(2147483647),
		pingShipmentDepDelayEnabled: Yup.boolean(),
		pingVesselEtaDelayEnabled: Yup.boolean(),
		tasIsTasParty: Yup.boolean(),
		tasIsSearchRestricted: Yup.boolean(),
		tasSubscriptionTypeCode: Yup.string().nullable(),
		tasRestrictedCountryCodes: Yup.string(),
		tasOutofRestrictedCountrySearchAllowed: Yup.boolean(),
		tasTransactionLimitType: Yup.string().nullable(),
		tasTransactionLimitValue: Yup.number().integer().min(1).max(99999),
		tasHsClassificationLimitValue: Yup.number().integer().min(-1).max(99999),
		tasComplianceDefaultLimitValue: Yup.number().integer().min(-1).max(99999),
		tasLandedCostLimitValue: Yup.number().integer().min(-1).max(99999),
		tasTransLimitFromDate: Yup.date().nullable(),
		tasTransLimitToDate: Yup.date().nullable(),
		tasIsComplianceRestricted: Yup.boolean(),
		tasCiaSubscriptionTypeCode: Yup.string().nullable(),
		tasCiaSubscriptionStatusCode: Yup.string().nullable(),
		cecIsCecParty: Yup.boolean(),
		cecSubscriptionTypeCode: Yup.string().nullable(),
		cecSubscriptionEndDate: Yup.date().nullable(),
		flexiSmsNotificationSub: Yup.boolean()
	})
}

export default makeValidationSchema
