import { Yup, constants } from 'cng-web-lib'
import Namespace from "../../../constants/locale/Namespace";

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  let requiredMessage = translate(
      Namespace.COMMON_VALIDATION_MSG,
      CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    carrierCode2: Yup.string().required(requiredMessage),
    carrierCode3: Yup.string().required(requiredMessage),
    partyId: Yup.string().required(requiredMessage),
  })
}

export default makeValidationSchema
