import NBSBillingConfigApiUrls from "../../../../apiUrls/NBSBillingConfigApiUrls";
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'

const {
  form: { CngEditForm }
} = components

function EditPage({ showNotification }) {
  const { id } = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: NBSBillingConfigApiUrls.GET
          }}
          update={{
            url: NBSBillingConfigApiUrls.PUT
          }}
          id={id}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage
