import { Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import AccessLevelRuleViewForm from './AccessLevelRuleViewForm'
import AccessLevelRuleEditForm from './AccessLevelRuleEditForm'
import AccessLevelRulePermissionCrudTable from './AccessLevelRulePermissionCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'

const { CngTabs } = components


function EditPage({ showNotification }) {
	const { id } = useParams()
    console.log("EditPage Rule id:[" + id + "]");
  const { translate } = useTranslation([Namespace.ACCESS_LEVEL_RULE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelRule = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.TITLE
    )
    let accessLevelRulePermission = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.TITLE
    )

    return {
      accessLevelRule,
      accessLevelRulePermission
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <AccessLevelRuleViewForm
							showNotification={showNotification}
							id={id}
							onFetchPreSuccess={() => { console.log("AccessLevelRule fetch success..") }}
						/>
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.accessLevelRule,
              tabContent: (
                <AccessLevelRuleEditForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.accessLevelRulePermission,
              tabContent: (
                <AccessLevelRulePermissionCrudTable
                  showNotification={showNotification}
                  accessLevelRuleId={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage