import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ShipperRequestTypeKeys from 'src/constants/locale/key/ShipperRequestType'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  requestType: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SHIPPER_REQUEST_TYPE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let shipperRequestType = translate(
      Namespace.SHIPPER_REQUEST_TYPE,
      ShipperRequestTypeKeys.TITLE
    )
    let requestType = translate(
      Namespace.SHIPPER_REQUEST_TYPE,
      ShipperRequestTypeKeys.REQUEST_TYPE
    )

    return {
      shipperRequestType,
      requestType
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.shipperRequestType} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.requestType}>
            <CngTextField
              name="requestType"
              label={translatedTextsObject.requestType + " *"}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
