import UserForUserGroupFormProperties from './UserForUserGroupFormProperties'
import UserForUserGroupApiUrls from 'src/apiUrls/UserForUserGroupApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function UserForUserGroupEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("UserForUserGroup Edit Form")}
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <UserForUserGroupFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={UserForUserGroupFormProperties.formikProps}
      toClientDataFormat={UserForUserGroupFormProperties.toClientDataFormat}
      toServerDataFormat={UserForUserGroupFormProperties.toServerDataFormat}
      fetch={{
        url: UserForUserGroupApiUrls.GETBYID,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: UserForUserGroupApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default UserForUserGroupEditForm
