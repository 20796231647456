import AccessLevelRuleFormProperties from './AccessLevelRuleFormProperties'
import AccessLevelRuleApiUrls from 'src/apiUrls/AccessLevelRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function AccessLevelRuleViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("AccessLevelRule View Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelRuleFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelRuleFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: AccessLevelRuleApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default AccessLevelRuleViewForm
