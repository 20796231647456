import { Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import CompanyForUserGroupViewForm from './CompanyForUserGroupViewForm'
import CompanyForUserGroupEditForm from './CompanyForUserGroupEditForm'
import CompanyUserGroupCrudTable from './CompanyUserGroupCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'

const { CngTabs } = components


function EditPage({ showNotification }) {
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.COMPANY_FOR_USER_GROUP])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.TITLE
    )
    let companyUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.TITLE
    )

    return {
      companyForUserGroup,
      companyUserGroup
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CompanyForUserGroupViewForm
							showNotification={showNotification}
							id={id}
							onFetchPreSuccess={(datum) => {console.log("CompanyForUserGroup View Form")}}
						/>
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.companyForUserGroup,
              tabContent: (
                <CompanyForUserGroupEditForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.companyUserGroup,
              tabContent: (
                <CompanyUserGroupCrudTable
                  showNotification={showNotification}
                  companyForUserGroupId={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage