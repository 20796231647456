import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaPartyCompanyAdmin'
import CalistaPartyAdminTablePage from './TablePage.js'
import CalistaPartyAdminViewPage from './ViewPage.js'
import CalistaPartyAdminEditPage from './EditPage.js'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
	const { translate } = useTranslation(Namespace.CALISTA_PARTY_COMPANY_INFO)
	let title = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.TITLE)

	return (title);
}

//* Wrapped Table Page ---------------------------------------------------------
function WrappedTablePage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyAdminTablePage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyAdminViewPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

// * Wrapped Edit Page ----------------------------------------------------------
function WrappedEditPage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyAdminEditPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

export {
	WrappedTablePage as TablePage,
    WrappedEditPage as EditPage,
    WrappedViewPage as ViewPage
}
