import React from 'react'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import makeValidationSchema from './CalistaPartyConfig_MakeValidationSchema'

const { 
	form: { field: { CngTextField } },
	CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
	name: ""
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
	initialValues: { ...DEFAULT_INITIAL_VALUES },
	makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, shouldHideMap, id }) {
	
	const { translate } = useTranslation(Namespace.CALISTA_PARTY_CONFIG)
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {
		
		// fields
		let partyName = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.PARTY_NAME)

		return {
			partyName
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

	//* Start JSX --------------------------------------------------------------
	<Grid container spacing={3}>

		<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
			<CngTextField name="name"
				label={translatedTextsObject.partyName}
				disabled={disabled}
			/>
    	</CngGridItem>
    </Grid>
	//* End JSX ----------------------------------------------------------------

	)
	//* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
	let localData = serverData;
	return localData;
}

function toServerDataFormat(localData) {
	return localData;
}

const FormProperties = Object.freeze({
	formikProps: FORMIK_PROPS,
	Fields: Fields,
	toClientDataFormat: toClientDataFormat,
	toServerDataFormat: toServerDataFormat
})

export default FormProperties
