import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { FIELDS_MAX_LENGTH } from 'src/views/calista-admin/party-company-info/CompanyInfo_MakeValidationSchema'
import React from 'react';

const {
    CngGridItem,
    form: { field: { CngTextField, CngCountryAutocompleteField } },
} = components

const MoreAboutComponent = ({ fieldsTranslatedTextObject, disabled }) => {
    return (
        <CngGridItem xs={12}><Card>
            <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.companyMoreAboutHeader}</Typography>} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={12}>

                        <CngTextField
                            name='partyDetails.partyIntroduction'
                            label={fieldsTranslatedTextObject.introduction}
                            inputProps={{ maxLength: FIELDS_MAX_LENGTH.INTRO }}
                            helperText={`${fieldsTranslatedTextObject.maxLengthMsg} ${FIELDS_MAX_LENGTH.INTRO} ${fieldsTranslatedTextObject.characters}`}
                            disabled={disabled}
                            multiline
                            rows={4}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngTextField
                            name='partyDetails.partyVision'
                            label={fieldsTranslatedTextObject.vision}
                            inputProps={{ maxLength: FIELDS_MAX_LENGTH.VISION }}
                            helperText={`${fieldsTranslatedTextObject.maxLengthMsg} ${FIELDS_MAX_LENGTH.VISION} ${fieldsTranslatedTextObject.characters}`}
                            disabled={disabled}
                            multiline
                            rows={1}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngCountryAutocompleteField name='presentInCountryList'
                            label={fieldsTranslatedTextObject.presenceInCountries}
                            disabled={disabled}
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>

                </Grid></CardContent>
        </Card></CngGridItem>
    );
}

export default MoreAboutComponent
