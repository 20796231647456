import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants } from 'cng-web-lib'
import { useFieldArray } from 'react-hook-form'
import { FIELDS_MAX_LENGTH } from 'src/views/calista-admin/party-company-info/CompanyInfo_MakeValidationSchema'
import React from 'react';

const {
    dropzone: { CngFileUpload, HelperTextArea },
    form: { field: { CngTextField } },
    CngGridItem,
} = components
const { NotificationType } = constants

const AwardsComponent = ({ fieldsTranslatedTextObject, disabled, setFieldValue, showNotification, id }) => {
    const { fields: partyAwardDTOList } = useFieldArray({ name: "partyAwardDTOList" });

    const handleRemoveAward = (index) => {
        const list = partyAwardDTOList;
        list.splice(index, 1);
        setFieldValue("partyAwardDTOList", list);
    }

    const handleAddAward = () => {
        const awardItem = {
            title: "",
            description: "",
            supportingDocument: "",
            supportingDocumentFileObj: [],
            partyId: id
        }
        const newList = [...partyAwardDTOList, awardItem];
        setFieldValue("partyAwardDTOList", newList);
    };

    const updateAwardSuppDoc = (index, img, fileObj) => {
        partyAwardDTOList[index].supportingDocument = img;
        partyAwardDTOList[index].supportingDocumentFileObj = fileObj;
        if (img) {
            partyAwardDTOList[index].doNotShowDeletePhotoIcon = true;
        }
        setFieldValue("partyAwardDTOList", partyAwardDTOList);
    }

    return (
        <CngGridItem xs={12}>
            <Card>
                <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.awardsHeader}</Typography>} />
                <Divider />
                <CardContent>
                    <Grid container xs={12} sm={12} spacing={2}>
                        {partyAwardDTOList.map((award, index) => {
                            return (
                                <CngGridItem xs={12} sm={12} key={award.id}>

                                    <Grid xs={12} sm={12}>
                                        <Card className="inner-card-group">
                                            <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveAward(index)}>
                                                <CloseIcon />
                                            </IconButton>
                                            <Grid container xs={12} sm={12} spacing={2}>
                                                <CngGridItem xs={12} sm={12}>
                                                    <CngTextField
                                                        name={`partyAwardDTOList[${index}].title`}
                                                        label={`${fieldsTranslatedTextObject.awardTitle}*`}
                                                        inputProps={{ maxLength: FIELDS_MAX_LENGTH.AWARD_TITLE }}
                                                        helperText={`${fieldsTranslatedTextObject.maxLengthMsg} ${FIELDS_MAX_LENGTH.AWARD_TITLE} ${fieldsTranslatedTextObject.characters}`}
                                                        disabled={disabled}
                                                    />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={12}>
                                                    <CngTextField
                                                        name={`partyAwardDTOList[${index}].description`}
                                                        label={fieldsTranslatedTextObject.awardDescription}
                                                        inputProps={{ maxLength: FIELDS_MAX_LENGTH.AWARD_DESCR }}
                                                        helperText={`${fieldsTranslatedTextObject.maxLengthMsg} ${FIELDS_MAX_LENGTH.AWARD_DESCR} ${fieldsTranslatedTextObject.characters}`}
                                                        disabled={disabled}
                                                        multiline
                                                        rows={4}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={12}>
                                                    <CngGridItem xs={12} lg={9}>
                                                        {fieldsTranslatedTextObject.currentSuppDoc}:
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} lg={3}>
                                                        {award.supportingDocument ?
                                                            <>
                                                                <img src={award.supportingDocument} style={{ width: "64px", height: "64px" }} />
                                                                {!award.doNotShowDeletePhotoIcon && !disabled &&
                                                                    <IconButton aria-label="remove" disabled={disabled} onClick={() => updateAwardSuppDoc(index, null, null)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                }
                                                            </>
                                                            :
                                                            <p>{fieldsTranslatedTextObject.noneSuppDoc}</p>
                                                        }
                                                    </CngGridItem>
                                                </CngGridItem>
                                                <CngGridItem xs={4} sm={4}>
                                                </CngGridItem>

                                                {!disabled &&
                                                    <CngGridItem xs={12} sm={12}>
                                                        {fieldsTranslatedTextObject.noteForFileUploadSuppDoc}
                                                        <CngFileUpload
                                                            accept={['.jpg', '.jpeg', '.png']}
                                                            maxSize={1048576}
                                                            maxFiles={1}
                                                            files={partyAwardDTOList[index].supportingDocumentFileObj}
                                                            onFileSelect={(files) => {
                                                                if (files.length > 0) {
                                                                    const file = files[0].file;
                                                                    if (file) {
                                                                        const reader = new FileReader();
                                                                        reader.readAsDataURL(file);
                                                                        reader.onload = (event) => {
                                                                            updateAwardSuppDoc(index, event.target.result, files)
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            showFormFields={false}
                                                            moreActions={[
                                                                {
                                                                    action: 'remove',
                                                                    name: <div onClick={() => updateAwardSuppDoc(index, null, null)}>
                                                                        <DeleteIcon />
                                                                        {fieldsTranslatedTextObject.remove}
                                                                    </div>
                                                                }
                                                            ]}
                                                            onDropRejected={(errorMessages) => {
                                                                showNotification(NotificationType.ERROR, errorMessages[0])
                                                            }}
                                                            renderHelperText={() => {
                                                                return (
                                                                    <HelperTextArea
                                                                        accept={['.jpg', '.jpeg', '.png']}
                                                                        maxFiles={1}
                                                                        maxSize={1048576}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </CngGridItem>
                                                }
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </CngGridItem>
                            );
                        })}

                    </Grid>
                </CardContent>

                <Button
                    variant="contained"
                    color="default"
                    fullWidth
                    disabled={disabled}
                    startIcon={<AddBoxIcon />}
                    className={'accordion-footer-add-component'}
                    onClick={() => handleAddAward()}
                >
                    <Box style={{ textAlign: 'left' }}>
                        <Box >
                            {fieldsTranslatedTextObject.addAward}
                        </Box>
                    </Box>
                </Button>

            </Card >
        </CngGridItem>
    );
}

export default AwardsComponent
