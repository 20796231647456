import makeValidationSchema from './UserSubscribedUserGroupMakeValidationSchema'
import {components, useTranslation} from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'
import {Card, CardContent, Grid} from '@material-ui/core'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userGroupMasterId: 0,
  orgCode: "",
  userGroupCode: "",
  userGroupName: "",
  companyType: "",
  userForUserGroupId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  userForUserGroupId
}) {
  const { translate } = useTranslation(Namespace.USER_FOR_USER_GROUP)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [userIdField] = useField('userForUserGroupId')

  function makeTranslatedTextsObject() {
    let userSubscribedUserGroup = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.TITLE
    )
    let userGroupMasterId = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_MASTER_ID
    )
    let orgCode = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.ORG_CODE
    )
    let userGroupCode = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_CODE
    )
    let userGroupName = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.USER_GROUP_NAME
    )
    let companyType = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.COMPANY_TYPE
    )

    return {
      userSubscribedUserGroup,
      userGroupMasterId,
      orgCode,
      userGroupCode,
      userGroupName,
      companyType
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.userSubscribedUserGroup} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupMasterId}>
            <CngSelectField
              name="userGroupMasterId"
              type="number"
              label={translatedTextsObject.userGroupMasterId + " *"}
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/getCustom`,
                  textAccessor: "customerGroupName",
                  body: {
                    id: userIdField.value
                  }
                }
              }
            />
          </CngGridItem>
          {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orgCode}>
            <CngTextField
              name="orgCode"
              label={translatedTextsObject.orgCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupCode}>
            <CngTextField
              name="userGroupCode"
              label={translatedTextsObject.userGroupCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupName}>
            <CngTextField
              name="userGroupName"
              label={translatedTextsObject.userGroupName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyType}>
            <CngTextField
              name="companyType"
              label={translatedTextsObject.companyType}
              disabled={disabled}
            />
          </CngGridItem>*/}
        </Grid>
      </CardContent>
    </Card>
  )
}

const UserSubscribedUserGroupFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UserSubscribedUserGroupFormProperties
