import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import ShipperRequestTypeApiUrls from 'src/apiUrls/ShipperRequestTypeApiUrls'
import React from 'react'
import ShipperRequestTypeKeys from 'src/constants/locale/key/ShipperRequestType'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SHIPPER_REQUEST_TYPE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let shipperRequestType = translate(
      Namespace.SHIPPER_REQUEST_TYPE,
      ShipperRequestTypeKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: shipperRequestType
      }
    )
    let requestType = translate(
      Namespace.SHIPPER_REQUEST_TYPE,
      ShipperRequestTypeKeys.REQUEST_TYPE
    )

    return {
      shipperRequestType,
      tableTitle,
      requestType
    }
  }

  const columns = [
    {
      field: "requestType",
      title: translatedTextsObject.requestType,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ShipperRequestTypeApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ShipperRequestTypeApiUrls.EXPORT }}
              fetch={{ url: ShipperRequestTypeApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
