import { Yup } from 'cng-web-lib'

function makeValidationSchema(translate) {

  return Yup.object({
    moduleCode: Yup.string(),
    menuCode: Yup.string(),
    accessLevelMasterId: Yup.number()
  })
}

export default makeValidationSchema
