import makeValidationSchema from './CompanyCustomRulePermissionMakeValidationSchema'
import { useTranslation, useServices, components } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'
import { Card, CardContent, Grid } from '@material-ui/core'
import AccessLevelRulePermissionApiUrls from "../../../apiUrls/AccessLevelRulePermissionApiUrls";

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  moduleCode: "",
  menuCode: "",
  accessLevelMasterId: 0,
  accessLevelMasterChildList: [],
  accessLevelMasterChildCodeList: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  accessLevelCompanyCustomRuleId
}) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [moduleCode, setModuleCode] = useState();
  const [menuValue, setMenuValue] = useState();
  const [menuCodeField, , { setValue: setMenuCodeValue }] = useField('menuCode')
  const [moduleCodeField] = useField('moduleCode')
  const [v2Fetch, setV2Fetch] = useState()
  const [accessLevelMasterIdKey, setAccessLevelMasterIdKey] = useState()
  const [accessLevelMasterParentValue, setAccessLevelMasterParentValue] = useState(0);
  const [accessLevelMasterParentField] = useField('accessLevelMasterId')
  const [accessLevelMasterChildListIdKey, setAccessLevelMasterChildListIdKey] = useState();
  const { fetchRecords } = useServices();
  const [accessLevelChildOptions, setAccessLevelChildOptions] = useState();

  useEffect(() => {
    updateFetchOperationForALC(accessLevelMasterParentValue)
  }, [accessLevelMasterParentValue])

  function updateFetchOperationForALC(accessLevelMasterParentValueUpdated) {

    const onSuccess = (response) => {
      const options = response.content.map((record) => {
        return { text: record.name, value: '' + record.id };
      });
      setAccessLevelChildOptions(options);
    }

    const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

    const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

    fetchRecords.execute(
        `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/access-level-rule-permission/getCustom`,
        { customData: { accessLevelMasterId: accessLevelMasterParentValueUpdated } },
        onSuccess,
        onError,
        onComplete
    );

    setAccessLevelMasterChildListIdKey(`accessLevelMasterIdKey-${accessLevelMasterParentValueUpdated}`)
  }


  useEffect(() => {
    console.log('setModuleCode', moduleCodeField.value)
    setModuleCode(moduleCodeField.value)
  }, [moduleCodeField.value])

  useEffect(() => {
    setMenuValue(menuCodeField.value)
  }, [menuCodeField.value])

  useEffect(() => {
    updateFetch(moduleCode, menuValue)
  }, [moduleCode, menuValue])

  useEffect(() => {
    setAccessLevelMasterParentValue(accessLevelMasterParentField.value);
  }, [])

  function updateFetch(moduleCodeUpdated, menuValueUpdated) {
    if (moduleCodeUpdated && menuValueUpdated) {
      console.log('setV2Fetch')
      setV2Fetch(f => {
        return {
          url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/access-level-rule-permission/getCustom-v3`,
          textAccessor: "name",
          valueAccessor: "id",
          body: {
            moduleCode: moduleCodeUpdated,
            menuCode: menuValueUpdated,
            ruleId: accessLevelCompanyCustomRuleId,
            accessLevelMasterId: accessLevelMasterParentField.value
          }
        }
      })
      setAccessLevelMasterIdKey(`accessLevelMasterId-${moduleCodeUpdated}-${menuValueUpdated}`)
    } else {
      setV2Fetch(null)
    }
    console.log('updateFetch', moduleCodeUpdated, menuValueUpdated)
  }

  function makeTranslatedTextsObject() {
    let companyCustomRulePermission = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.TITLE
    )
    let moduleCodeLbl = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.MODULE_CODE
    )
    let menuCodeLbl = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.MENU_CODE
    )
    let accessLevelMasterId = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.ACCESS_LEVEL_MASTER_ID
    )
    let accessLevelMasterChildList = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.ACCESS_LEVEL_MASTER_CHILD_LIST
    )

    return {
      companyCustomRulePermission,
      moduleCodeLbl,
      menuCodeLbl,
      accessLevelMasterId,
      accessLevelMasterChildList
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.companyCustomRulePermission} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={false}>
            <CngSelectField
                name="moduleCode"
                label={translatedTextsObject.moduleCodeLbl}
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value !== moduleCode) {
                    setMenuCodeValue('')
                  }
                  setModuleCode(e.target.value)
                }}
                fetch={
                  {
                    url: `${AccessLevelRulePermissionApiUrls.GET_MODULES_FOR_USER}`,
                    textAccessor: "descriptionEn",
                    valueAccessor: "code"
                  }
                }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={false}>
            <CngSelectField
                name="menuCode"
                label={translatedTextsObject.menuCodeLbl}
                key={`menu-${moduleCode}`}
                disabled={disabled}
                onChange={(e) => {
                  setMenuValue(e.target.value)
                }}
                fetch={
                  {
                    url: `${AccessLevelRulePermissionApiUrls.GET_MENUS_FOR_USER}`,
                    textAccessor: "descriptionEn",
                    valueAccessor: "code",
                    body: {
                      moduleCode: moduleCode
                    }
                  }
                }
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.accessLevelMasterId}>
            <CngSelectField
              name="accessLevelMasterId"
              type="number"
              label={translatedTextsObject.accessLevelMasterId}
              disabled={disabled}
              onChange={(e) => {
                console.log("########change:["+e.target.value+"]");
                setAccessLevelMasterParentValue(e.target.value);
              }}
              fetch={v2Fetch}
              key={accessLevelMasterIdKey}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={false}>
            <CngSelectField
              name="accessLevelMasterChildList"
              label={translatedTextsObject.accessLevelMasterChildList}
              disabled={disabled}
              multiple={true}
              items={accessLevelChildOptions}
              key={accessLevelMasterChildListIdKey}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const CompanyCustomRulePermissionFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CompanyCustomRulePermissionFormProperties
