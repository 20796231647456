import { Yup, constants } from 'cng-web-lib'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {


  return Yup.object({
    nbsAcctId: Yup.string().max(35),
    firstPosting: Yup.date(),
    lastPosting: Yup.date(),
    active: Yup.boolean()
  })
}

export default makeValidationSchema
