import UserForUserGroupFormProperties from './UserForUserGroupFormProperties'
import UserForUserGroupApiUrls from 'src/apiUrls/UserForUserGroupApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function UserForUserGroupAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <UserForUserGroupFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={UserForUserGroupFormProperties.formikProps}
      toClientDataFormat={UserForUserGroupFormProperties.toClientDataFormat}
      toServerDataFormat={UserForUserGroupFormProperties.toServerDataFormat}
      create={{
        url: UserForUserGroupApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default UserForUserGroupAddForm
