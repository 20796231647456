import React from 'react'
import { components } from 'cng-web-lib'

import CalistaPartyConfigFormProperties from './CalistaPartyConfig_FormProperties'
import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'

const { form: { CngEditForm }} = components

function EditForm({showNotification, id}) {

	return (

	//* Start JSX --------------------------------------------------------------
	<CngEditForm
		fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
		showNotification={showNotification}
		bodySection={
			<CalistaPartyConfigFormProperties.Fields
				showNotification={showNotification}
				id={id}
			/>
		}
		formikProps={CalistaPartyConfigFormProperties.formikProps}
		toClientDataFormat={CalistaPartyConfigFormProperties.toClientDataFormat}
		toServerDataFormat={CalistaPartyConfigFormProperties.toServerDataFormat}
		fetch={{
			url: CalistaPartyConfigApiUrls.GET
		}}
		update={{
			url: CalistaPartyConfigApiUrls.PUT
		}}
		id={id}
	/>
	//* End JSX ----------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default EditForm
