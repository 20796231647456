import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardHeader, CardContent, Divider, Typography } from '@material-ui/core'
import { useTranslation, components } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import PartySummaryViewForm from './CalistaPartyConfig_PartySummaryViewForm'
import CalistaPartyConfigViewForm from './CalistaPartyConfig_ViewForm'
import CalistaPartyCompanyInfoViewForm from '../party-company-info/CompanyInfoViewForm'
import CalistaPartyCompanyMicrositeViewForm from '../party-company-info/CompanyMicrositeViewForm'

const { CngTabs } = components

function ViewPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.CALSITA_PARTY_CONFIG])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		let title = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TITLE)
		let partySummarySectionHeader = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.PARTY_SUMMARY_SECTION_HEADER)
		let moduleSettingsTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_MODULE_SETTINGS)
		let companyInfoTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_COMPANY_INFO)
		let micrositeTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_MICROSITE)

		return {
			title,
			partySummarySectionHeader,
			moduleSettingsTabTitle,
			companyInfoTabTitle,
			micrositeTabTitle
		}
	}

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Grid container spacing={3}>
			<Grid item xs={12}>

				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextsObject.moduleSettingsTabTitle,
							tabContent: (
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<Card>
											<CardHeader title={<Typography variant="h5" className='font-bolder'>{translatedTextsObject.partySummarySectionHeader}</Typography>} />
											<Divider />

											<CardContent>
												<PartySummaryViewForm
													showNotification={showNotification}
													id={id}
												/>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12}>
										<CalistaPartyConfigViewForm
											showNotification={showNotification}
											id={id}
										/>
									</Grid>
								</Grid>
							)
						},
						{
							tabName: translatedTextsObject.companyInfoTabTitle,
							tabContent: (
								<>
									<CalistaPartyCompanyInfoViewForm
										showNotification={showNotification}
										id={id} />
								</>
							)
						},
						{
							tabName: translatedTextsObject.micrositeTabTitle,
							tabContent: (
								<>
									<CalistaPartyCompanyMicrositeViewForm
										showNotification={showNotification}
										id={id} />
								</>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
		//* End JSX ------------------------------------------------------------

	);
	//* End of function --------------------------------------------------------
}

export default ViewPage