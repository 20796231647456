import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation, constants } from 'cng-web-lib'

import AccessLevelUserViewForm from './AccessLevelUserViewForm'
import AccessLevelUserAddForm from './AccessLevelUserAddForm'
import AccessLevelUserEditForm from './AccessLevelUserEditForm'
import AccessLevelUserRuleCrudTable from './AccessLevelUserRuleCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [accessLevelUserId, setAccessLevelUserId] = useState('')
  const { translate } = useTranslation([
    Namespace.ACCESS_LEVEL_USER,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelUser = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.TITLE
    )
    let accessLevelUserRule = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.AccessLevelUserRule.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: accessLevelUser
      }
    )

    return {
      accessLevelUser,
      accessLevelUserRule,
      tabLockedMessage
    }
  }

  function makeAccessLevelUserForm() {
    if (accessLevelUserId === '') {
      return (
        <AccessLevelUserAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setAccessLevelUserId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <AccessLevelUserEditForm
          showNotification={showNotification}
          id={accessLevelUserId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <AccessLevelUserViewForm
              showNotification={showNotification}
              id={accessLevelUserId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.accessLevelUser,
              tabContent: makeAccessLevelUserForm()
            },
            {
              tabName: translatedTextsObject.accessLevelUserRule,
              tabContent: (
                <AccessLevelUserRuleCrudTable
                  showNotification={showNotification}
                  accessLevelUserId={accessLevelUserId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage