import makeValidationSchema from './AccessLevelRulePermissionMakeValidationSchema'
import { components, useServices, useTranslation } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'
import { Card, CardContent, Grid } from '@material-ui/core'
import {useParams} from "react-router-dom";

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngSelectField,
      CngModuleAutocompleteField,
      CngMenuAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  accessLevelMasterId: 0,
  accessLevelMasterChildList: [],
  accessLevelMasterChild: "",
  moduleCode: "",
  menuCode: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  accessLevelRuleId
}) {

  console.log("AccessLevelRulePermissionFormProperties Rule id:[" + accessLevelRuleId + "]");

  const { id } = useParams()
  if( !accessLevelRuleId ) {
    accessLevelRuleId = id;
    console.log("Rule id was null. fallback to useParam:[" + accessLevelRuleId + "]");
  }

  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_RULE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [moduleCode, setModuleCode] = useState();
  const [menuValue, setMenuValue] = useState();
  const [menuCodeField, , { setValue: setMenuCodeValue }] = useField('menuCode')
  const [moduleCodeField] = useField('moduleCode')

  const [v2Fetch, setV2Fetch] = useState()
  const [accessLevelMasterIdKey, setAccessLevelMasterIdKey] = useState()

  const [accessLevelMasterParentValue, setAccessLevelMasterParentValue] = useState(0);
  const [accessLevelMasterParentField] = useField('accessLevelMasterId')
  const [accessLevelMasterChildListIdKey, setAccessLevelMasterChildListIdKey] = useState();
  const [accessLevelMasterChildOptions, setAccessLevelMasterChildOptions] = useState([]);

  const { fetchRecords } = useServices();

  useEffect(() => {
    updateFetchOperationForChild(accessLevelMasterParentValue)
  }, [accessLevelMasterParentValue])

  function updateFetchOperationForChild(accessLevelMasterParentValueUpdated) {

    console.log("AccessLevelMasterParentValue:[" + accessLevelMasterParentValueUpdated + "]");

    const onSuccess = (response) => {
      const options = response.content.map((record) => {
        return { text: record.name, value: '' + record.id };
      });
      setAccessLevelMasterChildOptions(options);
    }

    const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

    const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

    fetchRecords.execute(
      `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/access-level-rule-permission/getCustom`,
      { customData: { accessLevelMasterId: accessLevelMasterParentValueUpdated } },
      onSuccess,
      onError,
      onComplete
    );

    setAccessLevelMasterChildListIdKey(`accessLevelMasterIdKey-${accessLevelMasterParentValueUpdated}`)
  }

  useEffect(() => {
    console.log('setModuleCode', moduleCodeField.value)
    setModuleCode(moduleCodeField.value)
  }, [moduleCodeField.value])

  useEffect(() => {
    setMenuValue(menuCodeField.value)
  }, [menuCodeField.value])

  useEffect(() => {
    updateFetch(moduleCode, menuValue)
  }, [moduleCode, menuValue])

  useEffect(() => {
    setAccessLevelMasterParentValue(accessLevelMasterParentField.value);
  }, [])

  function updateFetch(moduleCodeUpdated, menuValueUpdated) {
    if (moduleCodeUpdated && menuValueUpdated) {
      console.log('setV2Fetch')
      setV2Fetch(f => {
        return {
          url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/access-level-rule-permission/getCustom-v2`,
          textAccessor: "name",
          valueAccessor: "id",
          body: {
            moduleCode: moduleCodeUpdated,
            menuCode: menuValueUpdated,
            ruleId: accessLevelRuleId,
            accessLevelMasterId: accessLevelMasterParentField.value
          }
        }
      })
      setAccessLevelMasterIdKey(`accessLevelMasterId-${moduleCodeUpdated}-${menuValueUpdated}`)
    } else {
      setV2Fetch(null)
    }
    console.log('updateFetch', moduleCodeUpdated, menuValueUpdated)
  }

  function makeTranslatedTextsObject() {
    let accessLevelRulePermission = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.TITLE
    )
    let accessLevelMasterId = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.ACCESS_LEVEL_MASTER_ID
    )

    let moduleCodeLbl = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.MODULE_CODE
    )

    let menuCode = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.MENU_CODE
    )

    let accessLevelMasterChildList = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.ACCESS_LEVEL_MASTER_CHILD_LIST
    )

    return {
      accessLevelRulePermission,
      accessLevelMasterId,
      moduleCodeLbl,
      menuCode,
      accessLevelMasterChildList
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.accessLevelRulePermission} />
      <CardContent>
        <Grid container spacing={3}>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.moduleId}>
            <CngModuleAutocompleteField
              name='moduleCode'
              label={translatedTextsObject.moduleCodeLbl + " *"}
              disabled={false}
              onChange={(value) => {
                if (value !== moduleCode) {
                  setMenuCodeValue('')
                }
                setModuleCode(value)
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.menuId}>
            <CngMenuAutocompleteField
              name='menuCode'
              label={translatedTextsObject.menuCode + " *"}
              key={`menu-${moduleCode}`}
              disabled={false}
              onChange={(value) => {
                setMenuValue(value)
              }}
              lookupProps={{
                filters: [
                  {
                    field: 'moduleCode',
                    operator: 'EQUAL',
                    value: moduleCode
                  }
                ]
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.accessLevelMasterId}>
            <CngSelectField
              name="accessLevelMasterId"
              type="number"
              label={translatedTextsObject.accessLevelMasterId}
              disabled={false}
              onChange={(e) => {
                setAccessLevelMasterParentValue(e.target.value);
              }}
              fetch={v2Fetch}
              key={accessLevelMasterIdKey}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={false}>
            <CngSelectField
              name="accessLevelMasterChildList"
              label={translatedTextsObject.accessLevelMasterChildList}
              disabled={disabled}
              multiple
              //fetch={accessLevelChildByParentIdFetch}
              key={accessLevelMasterChildListIdKey}
              items={accessLevelMasterChildOptions}
            />
          </CngGridItem>

        </Grid>
      </CardContent>
    </Card>
  )
}

const AccessLevelRulePermissionFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AccessLevelRulePermissionFormProperties
