import UserForShipperRequestFormProperties from './UserForShipperRequestFormProperties'
import UserForShipperRequestApiUrls from 'src/apiUrls/UserForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function UserForShipperRequestViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("UserForShipperRequest View Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <UserForShipperRequestFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={UserForShipperRequestFormProperties.formikProps}
      toClientDataFormat={UserForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={UserForShipperRequestFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: UserForShipperRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default UserForShipperRequestViewForm
