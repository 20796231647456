import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, {useState} from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import NBSFileApiUrls from "../../../../apiUrls/NBSFileApiUrls";


const { form: { CngViewForm },
    button: { CngGridItem, CngPrimaryButton },
} = components

function ViewPage({ showNotification }) {
    const { id } = useParams()

    const [value, setValue] = useState({});

    function downloadFile(type) {
        let fileContent = (type == "submitted" ? value.submittedFile : value.returnFile)
        let blob = new Blob([fileContent]);
        let fileName = (type == "submitted" ? value.submittedFileName : value.returnFileName)

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);

        //console.log(link)
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
              setValue={setValue}
            />
          }
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            id: id,
            url: NBSFileApiUrls.GET
          }}
        />

          <CngPrimaryButton size='large' onClick={()=>downloadFile("submitted")} style={{marginTop: '16px'}} >
              Download Submitted File
          </CngPrimaryButton>
          <CngPrimaryButton size='large' onClick={()=>downloadFile("return")} style={{marginTop: '16px', marginLeft: '8px'}} >
              Download Return File
          </CngPrimaryButton>
      </Grid>
    </Grid>
  )
}

export default ViewPage
