import { components, useServices } from 'cng-web-lib'
import React, { useState } from 'react'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'
import EditCompanyInfoFormProperties from './CompanyInfo_FormProperties'
import PartyCompanyInfoTranslationText from './PartyCompanyInfoTranslationText'

const {
  form: {
    CngEditForm,
  },
  table: { useDefaultNotification }
} = components

function EditForm({ showNotification, id, onFetchPreSuccess = () => {
  //do nothing
} }) {
  const { updateRecord } = useServices();
  const [loading, setLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([])
  const fieldsTranslatedTextObject = PartyCompanyInfoTranslationText()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const onSubmit = (dataForm) => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      showSuccessNotification(fieldsTranslatedTextObject.backendSuccessMsg);
    }
    const onError = () => {
      setLoading(false);
      showErrorNotification(fieldsTranslatedTextObject.backendErrorMsg);
    }
    const previouslyUploadedMedia = dataForm.partyMediaDTOList.filter(media => !media.newlyUploaded)
   
    const newlyUploaded = [];
    mediaFiles.forEach((file, i) => {
      newlyUploaded.push(dataForm.partyMediaDTOList.filter(media => (media.fileObj && media.fileObj == file))[0])
    })
    dataForm.partyMediaDTOList = previouslyUploadedMedia.concat(newlyUploaded);
    dataForm.partyDetails.id = id;
    dataForm.partyManagementUserDTOList.forEach(mgmtUser => mgmtUser.id = null);
    dataForm.partyAwardDTOList.forEach(award => award.id = null);
    updateCompanyInfo(dataForm, onSuccess, onError);
  }

  const updateCompanyInfo = (dataForm, onSuccess, onError) => {
    updateRecord.execute(
      CalistaPartyCompanyInfoApiUrls.CREATE_COMPANY_DETAILS,
      dataForm,
      onSuccess,
      onError
    )
  }

  return (
    <>
      <CngEditForm
        showNotification={showNotification}
        id={id}
        formikProps={{
          initialValues: EditCompanyInfoFormProperties.formikProps.initialValues,
          makeValidationSchema: EditCompanyInfoFormProperties.formikProps.makeValidationSchema,
          onSubmit: onSubmit
        }}
        fetch={{
          id: id,
          url: CalistaPartyCompanyInfoApiUrls.GET_COMPANY_DETAILS,
          onPreSuccess: onFetchPreSuccess
        }}
        fieldLevel='editForm'
        //for button section to appear with form fields
        renderButtonSection={() => (
          <></>
        )}
        bodySection={
          <EditCompanyInfoFormProperties.Fields
            showNotification={showNotification}
            id={id}
            loading={loading}
            setMediaFiles={setMediaFiles}
            mediaFiles={mediaFiles}
          />
        }
      />
    </>
  );
}

export default EditForm
