import React from 'react'
import { components } from 'cng-web-lib'

import PartySummaryFormProperties from './CalistaPartyConfig_PartySummaryFormProperties'
import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'

const { form: { CngViewForm }} = components

function ViewForm({showNotification, id}) {
	
	return (

	//* Start JSX --------------------------------------------------------------
	<CngViewForm
		fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
		showNotification={showNotification}
		bodySection={
			<PartySummaryFormProperties.Fields
				disabled={true}
				showNotification={showNotification}
				id={id}
			/>
		}
		formikProps={PartySummaryFormProperties.formikProps}
		toClientDataFormat={PartySummaryFormProperties.toClientDataFormat}
		toServerDataFormat={PartySummaryFormProperties.toServerDataFormat}
		fetch={{
			id: id,
			url: CalistaPartyConfigApiUrls.GET_BY_PARTY_SERVICE
		}}
    />
	//* End JSX ----------------------------------------------------------------
	)
}

export default ViewForm
