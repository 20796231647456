import { Grid } from '@material-ui/core'
import { Bar } from 'react-chartjs-2'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CHART_OPTION,
  EMISSION_DESCRIPTION,
  EMISSION_DETAIL,
  EMISSION_TITLE,
  NUMBER_FORMAT_FIX,
  TRANSPORTATION,
  TRANSPORTATION_LABEL
} from '../constants'
import { mapIcon } from '../ultls'

function transformData(
  datachart = {},
  key = '',
  labels = [],
  typeShow = false
) {
  const data = !typeShow
    ? datachart[`${key}Total`]
    : datachart[`${key}TotalWtT`]
  const barThickness = 380 / labels.length
  return {
    labels: labels.map((item) => TRANSPORTATION_LABEL[item] ?? item),
    responsive: true,
    offset: true,
    datasets: Object.keys(data).map((keyValue) => {
      const keyColor = keyValue.split('V')[0]
      return {
        label: !typeShow
          ? TRANSPORTATION_LABEL[keyColor]
          : TRANSPORTATION_LABEL[keyValue],
        pointStyle: 'rectRounded',
        backgroundColor: !typeShow
          ? TRANSPORTATION[keyColor]
          : TRANSPORTATION[keyValue],
        barThickness: barThickness,
        categoryPercentage: 1,
        data: data[keyValue].map(
          (item) => item?.toFixed(NUMBER_FORMAT_FIX) ?? 0
        )
      }
    })
  }
}

const ChartPage = ({ data = {}, typeShow = false, lessEmissionIndex, t }) => {
  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(!show)
  }
  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '24px' }} className='cfc-chart'>
        <Grid container spacing={2} className=''>
          <div className='cfc-chart-legend-box fl fl-c text-c mr-10 pl-20'>
            <div className='bold'>{t('label_chart_legend')}</div>
          </div>
          <div className='fl'>
            <div className='fl fl-c'>
              {data?.listMarkTransport?.map((item) => {
                return typeShow ? (
                  <>
                    <div className='fl fl-c mr-10'>
                      <div
                        className='cfc-chart-legend'
                        style={{
                          backgroundColor: TRANSPORTATION[`${item}ValueTtW`]
                        }}
                      />
                      {TRANSPORTATION_LABEL[`${item}ValueTtW`]}
                    </div>
                    <div className='fl fl-c mr-10'>
                      <div
                        className='cfc-chart-legend'
                        style={{
                          backgroundColor: TRANSPORTATION[`${item}ValueWtT`]
                        }}
                      />
                      {TRANSPORTATION_LABEL[`${item}ValueWtT`]}
                    </div>
                  </>
                ) : (
                  <div className='fl fl-c mr-10'>
                    <div
                      className='cfc-chart-legend'
                      style={{
                        backgroundColor: TRANSPORTATION[item]
                      }}
                    />
                    {TRANSPORTATION_LABEL[item]}
                  </div>
                )
              })}
              <div className='fl fl-c'>
                <FontAwesomeIcon
                  className='cfc-best mr-10'
                  style={{
                    bottom: '15%',
                    left: mapIcon(data?.listLabel.length - 1),
                    color: '#007a33'
                  }}
                  icon={['fal', 'leaf']}
                />
                {t('label_less')}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {Object.keys(EMISSION_DETAIL).map((key, index) => {
          if (show) {
            return (
              <Grid item lg={6} xs={12} className='cfc-card' key={`_${index}`}>
                <div className='c-title fl'>
                  <div className='bold '>{EMISSION_TITLE[key]}</div>
                  <div className='c-label'>{EMISSION_DESCRIPTION[key]}</div>
                </div>
                <div className='fl pl-20 pos-rel'>
                  <div className='pos-rel '>
                    <div class='cfc-label-chart pos-abs '>
                      {EMISSION_DETAIL[key]}
                    </div>
                  </div>

                  <Bar
                    data={transformData(data, key, data?.listLabel, typeShow)}
                    height={313}
                    options={CHART_OPTION}
                    plugins={[
                      {
                        afterDraw: (chart) => {
                          let ctx = chart.chart.ctx
                          let xAxis = chart.scales['x-axis-0']
                          let yAxis = chart.scales['y-axis-0']
                          xAxis.ticks.forEach((value, indexChart) => {
                            if (lessEmissionIndex == indexChart) {
                              let x = xAxis.getPixelForTick(indexChart)
                              let img = new Image()
                              img.src = `${process.env.PUBLIC_URL}/static/images/nextGen/cfc/leaf.png`
                              ctx.drawImage(img, x - 60, yAxis.bottom + 10)
                            }
                          })
                        }
                      }
                    ]}
                  />
                </div>
              </Grid>
            )
          } else {
            if (index < 2) {
              return (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className='cfc-card'
                  key={`_${index}`}
                >
                  <div className='c-title fl'>
                    <div className='bold '>{EMISSION_TITLE[key]}</div>
                    <div className='c-label'>{EMISSION_DESCRIPTION[key]}</div>
                  </div>
                  <div className='fl pos-rel pl-20 '>
                    <div style={{ height: '220px' }} className='pos-rel '>
                      <div className='cfc-label-chart pos-abs '>
                        {EMISSION_DETAIL[key]}
                      </div>
                    </div>

                    <Bar
                      data={transformData(data, key, data?.listLabel, typeShow)}
                      height={313}
                      options={CHART_OPTION}
                      plugins={[
                        {
                          afterDraw: (chart) => {
                            let ctx = chart.chart.ctx
                            let xAxis = chart.scales['x-axis-0']
                            let yAxis = chart.scales['y-axis-0']
                            xAxis.ticks.forEach((value, indexChart) => {
                              if (lessEmissionIndex == indexChart) {
                                let x = xAxis.getPixelForTick(indexChart)
                                let img = new Image()
                                img.src = `${process.env.PUBLIC_URL}/static/images/nextGen/cfc/leaf.png`
                                ctx.drawImage(img, x - 60, yAxis.bottom + 10)
                              }
                            })
                          }
                        }
                      ]}
                    ></Bar>
                  </div>
                </Grid>
              )
            } else {
              return null
            }
          }
        })}
      </Grid>
      <div onClick={onClick} className='fl sl-sb cfc-show-more full-w'>
        <Grid container justifyContent='space-between'>
          <Grid item xs={6}>
            <div className='title'>{t('label_additional')}</div>
            <div className='label'>
              {!show ? t('label_show_other') : t('label_hide_other')}
            </div>
          </Grid>
          <Grid item xs={6} className='fl-e'>
            <div className='fl fl-e'>
              <div className='expand-icon'>
                {!show ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ChartPage
