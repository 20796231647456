import React, { useState, useEffect } from 'react'
import { Checkbox } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const CheckBoxComponent = ({ icon, label, value, ...rest }) => {
  const { setValue, getValues, setError, clearErrors } = useFormContext()
  const transValue = getValues('transportMode') ?? []
  const [check, setCheck] = useState(
    !!transValue?.find((item) => item === value)
  )

  const onChange = (_ev) => {
    const { checked } = _ev.target

    setCheck(checked)
    if (checked) {
      setValue('transportMode', [...transValue, value])
    } else {
      setValue('transportMode', [
        ...transValue.filter((item) => item !== value)
      ])
    }
  }
  useEffect(() => {
    if (transValue == '') {
      setError('transportMode', { message: 'Transport mode is required' })
    } else {
      clearErrors('transportMode')
    }
  }, [check])
  const checkBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: check ? '#f6f7f9' : 'white',
    width: '187px',
    height: '56px',
    borderRadius: '11px',
    paddingLeft: '20px',
    paddingRight: '8px'
  }

  return (
    <div style={checkBoxStyle}>
      <div>
        {icon}
        {label}
      </div>
      <Checkbox onChange={onChange} {...rest} checked={check} value={value} />
    </div>
  )
}

export default CheckBoxComponent
