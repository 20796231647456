import makeValidationSchema from './UserShipperRequestTypeMakeValidationSchema'
import {components, useTranslation} from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'
import {Card, CardContent, Grid} from '@material-ui/core'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shipperRequestTypeId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {

  const { translate } = useTranslation(Namespace.USER_FOR_SHIPPER_REQUEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [userForShipperRequestId] = useField('userForShipperRequestId');

  function makeTranslatedTextsObject() {
    let userShipperRequestType = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.UserShipperRequestType.TITLE
    )
    let shipperRequestTypeId = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.UserShipperRequestType.SHIPPER_REQUEST_TYPE_ID
    )

    return {
      userShipperRequestType,
      shipperRequestTypeId
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.userShipperRequestType} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperRequestTypeId}>
            <CngSelectField
              name="shipperRequestTypeId"
              type="number"
              label={translatedTextsObject.shipperRequestTypeId + " *"}
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/user-for-shipper-request/user-shipper-request-type/getCustom`,
                  textAccessor: "requestType",
                  valueAccessor: "id",
                  body: {
                    userId: userForShipperRequestId.value
                  }
                }
              }
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const UserShipperRequestTypeFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UserShipperRequestTypeFormProperties
