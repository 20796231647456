import { Card, CardContent, CardHeader, Divider, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants } from 'cng-web-lib'
import React from 'react'
import { useFieldArray } from 'react-hook-form'

const {
    dropzone: { CngFileUpload, HelperTextArea },
    CngGridItem,
} = components

const { NotificationType } = constants
const moreActions = [
    {
        action: 'remove',
        name: 'Remove',
        icon: ['fal', 'trash']
    }
]

const MediaComponent = ({ fieldsTranslatedTextObject, disabled, setFieldValue, mediaFiles, setMediaFiles, showNotification, id }) => {
    const { fields: partyMediaDTOList } = useFieldArray({ name: "partyMediaDTOList" });

    const handleRemoveExistingMediaFile = (index) => {
        const list = partyMediaDTOList;
        list.splice(index, 1);
        setFieldValue("partyMediaDTOList", list)
    }

    const updateMediaPhotos = (selectedFileList) => {
        let list = partyMediaDTOList;
        selectedFileList.forEach((file, i) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onload = (event) => {
                const mediaItem = {
                    media: event.target.result,
                    newlyUploaded: true,
                    fileObj: file,
                    partyId: id
                }
                list.push(mediaItem);
                setFieldValue("partyMediaDTOList", list)
            }
        })
    }

    return (
        <CngGridItem xs={12}>
            <Card>
                <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.companyMediaHeader} </Typography>} />
                <Divider />
                <CardContent>
                    <CngGridItem xs={12} sm={12}>
                        {partyMediaDTOList.length > 0 && partyMediaDTOList.some(m => !m.newlyUploaded) &&
                            <>
                                <p> {fieldsTranslatedTextObject.companyMediaCurrent}: </p>
                                {partyMediaDTOList.map((media, index) => {
                                    if (!media.newlyUploaded) {
                                        return (
                                            <CngGridItem key={media.id} xs={12} sm={12}>
                                                <img src={media.media} style={{ width: "64px", height: "64px" }} />
                                                {!disabled &&
                                                    <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveExistingMediaFile(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            </CngGridItem>
                                        );
                                    }
                                })
                                }
                            </>
                        }

                        {!disabled &&
                            <CngFileUpload
                                accept={['.jpg', '.jpeg', '.png']}
                                maxSize={2097152}
                                maxFiles={5}
                                files={mediaFiles}
                                onFileSelect={selectedFileList => {
                                    setMediaFiles(selectedFileList);
                                    updateMediaPhotos(selectedFileList);
                                }}
                                moreActions={moreActions}
                                onDropRejected={(errorMessages) => {
                                    showNotification(NotificationType.ERROR, errorMessages[0])
                                }}
                                renderHelperText={() => {
                                    return (
                                        <HelperTextArea
                                            accept={['.jpg', '.jpeg', '.png']}
                                            maxFiles={5}
                                            maxSize={2097152}
                                        />
                                    )
                                }}
                            />
                        }

                    </CngGridItem>
                </CardContent>
            </Card>
        </CngGridItem>
    );
}

export default MediaComponent
