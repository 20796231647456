import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import AccessLevelCompanyCustomRuleViewForm from './AccessLevelCompanyCustomRuleViewForm'
import AccessLevelCompanyCustomRuleAddForm from './AccessLevelCompanyCustomRuleAddForm'
import AccessLevelCompanyCustomRuleEditForm from './AccessLevelCompanyCustomRuleEditForm'
import CompanyCustomRulePermissionCrudTable from './CompanyCustomRulePermissionCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [accessLevelCompanyCustomRuleId, setAccessLevelCompanyCustomRuleId] = useState('')
  const { translate } = useTranslation([
    Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelCompanyCustomRule = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.TITLE
    )
    let companyCustomRulePermission = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: accessLevelCompanyCustomRule
      }
    )

    return {
      accessLevelCompanyCustomRule,
      companyCustomRulePermission,
      tabLockedMessage
    }
  }

  function makeAccessLevelCompanyCustomRuleForm() {
    if (accessLevelCompanyCustomRuleId === '') {
      return (
        <AccessLevelCompanyCustomRuleAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setAccessLevelCompanyCustomRuleId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <AccessLevelCompanyCustomRuleEditForm
          showNotification={showNotification}
          id={accessLevelCompanyCustomRuleId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <AccessLevelCompanyCustomRuleViewForm
              showNotification={showNotification}
              id={accessLevelCompanyCustomRuleId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.accessLevelCompanyCustomRule,
              tabContent: makeAccessLevelCompanyCustomRuleForm()
            },
            {
              tabName: translatedTextsObject.companyCustomRulePermission,
              tabContent: (
                <CompanyCustomRulePermissionCrudTable
                  showNotification={showNotification}
                  accessLevelCompanyCustomRuleId={accessLevelCompanyCustomRuleId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage