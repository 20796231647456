import CompanyForShipperRequestFormProperties from './CompanyForShipperRequestFormProperties'
import CompanyForShipperRequestApiUrls from 'src/apiUrls/CompanyForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function CompanyForShipperRequestEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("CompanyForShipperRequest Edit Form")}
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <CompanyForShipperRequestFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={CompanyForShipperRequestFormProperties.formikProps}
      toClientDataFormat={CompanyForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyForShipperRequestFormProperties.toServerDataFormat}
      fetch={{
        url: CompanyForShipperRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: CompanyForShipperRequestApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default CompanyForShipperRequestEditForm
