import makeValidationSchema from './AccessLevelRuleMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'
import { Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngSwitchField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  name: "",
  active: true,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_RULE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let name = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.NAME
    )
    let active = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.ACTIVE
    )

    return {
      name,
      active
    }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
        <CngTextField
          name="name"
          label={translatedTextsObject.name}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
        <CngSwitchField
          name="active"
          label={translatedTextsObject.active}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
