import UserForShipperRequestFormProperties from './UserForShipperRequestFormProperties'
import UserForShipperRequestApiUrls from 'src/apiUrls/UserForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function UserForShipperRequestAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <UserForShipperRequestFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={UserForShipperRequestFormProperties.formikProps}
      toClientDataFormat={UserForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={UserForShipperRequestFormProperties.toServerDataFormat}
      create={{
        url: UserForShipperRequestApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default UserForShipperRequestAddForm
