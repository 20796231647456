import AccessLevelCompanyFormProperties from './AccessLevelCompanyFormProperties'
import AccessLevelCompanyApiUrls from 'src/apiUrls/AccessLevelCompanyApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function AccessLevelCompanyAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AccessLevelCompanyFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelCompanyFormProperties.formikProps}
      toClientDataFormat={AccessLevelCompanyFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelCompanyFormProperties.toServerDataFormat}
      create={{
        url: AccessLevelCompanyApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default AccessLevelCompanyAddForm
