import makeValidationSchema from './UserForUserGroupMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'
import { Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
  loginId: 0,
  userProfileId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.USER_FOR_USER_GROUP)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let partyId = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.PARTY_ID
    )
    let loginId = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.LOGIN_ID
    )

    return {
      partyId,
      loginId
    }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngSelectField
          name="partyId"
          type="number"
          label={translatedTextsObject.partyId + " *"}
          disabled={disabled}
          fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`, textAccessor: "name"}}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loginId}>
        <CngSelectField
          name="userProfileId"
          type="number"
          label={translatedTextsObject.loginId + " *"}
          disabled={disabled}
          fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`, textAccessor: "loginId"}}
        />
      </CngGridItem>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
