import React from 'react'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { displayNumber, ResultData, ResultTotal } from './ResultData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  EMISSION_DETAIL,
  FLAG_NS,
  SCREEN_VIEW,
  SCROLL_WIDTH
} from '../constants'
import AvatarsComponent from '../components/Avatars'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'
import { useHistory } from 'react-router'
import ImageComponent from '../components/ImageComponent'
const CalculationReturnData = ({
  data,
  location,
  index,
  typeShow,
  advanceMode,
  country
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])
  const history = useHistory()

  const handleChange = () => {
    setExpanded(!expanded)
  }
  const checkFlag = (flag) => {
    return !FLAG_NS.includes(flag)
  }
  const findFlag = (code, description, flagName) => {
    const dataLocationForm = history.location?.state?.dataLocationForm
    const optionRequests = dataLocationForm?.optionRequests ?? []
    const viaRequest = optionRequests[index - 1]?.viaRequestList ?? []
    const result = viaRequest?.find(
      (item) => item?.transportRequest?.code == code
    )
    if (result) {
      const { descriptionEn, code: falagCode } = country?.find(
        (item) => item?.code == result?.transportRequest?.countryCode
      )
      let flagDe =
        result?.transportRequest?.descriptionEn ??
        descriptionEn?.toLocaleLowerCase()
      return {
        flag: flagName ?? falagCode,
        nameCountry: description ?? flagDe
      }
    } else {
      return {
        flag: flagName ?? '',
        nameCountry: description ?? ''
      }
    }
  }
  return (
    <Grid
      item
      xs={12}
      className={`${advanceMode ? 'cfc-advance-mode  mt-20' : ''}`}
    >
      {advanceMode && <div>{data?.option}</div>}
      <Accordion
        className={`cfc-accordion ${
          data?.lessEmission ? 'cfc-background-best' : ''
        }`}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='cfc-drop-icon' />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Grid
            container
            justifyContent='space-between'
            className={'pos-rel fl-sb'}
          >
            <Grid item className='fl fl-c '>
              <Grid container spacing={1}>
                <Grid item>
                  {checkFlag(location?.startLocation?.flag) && (
                    <img
                      className='cfc-flag'
                      src={`${process.env.PUBLIC_URL}/static/images/countries/${location?.startLocation?.flag}.svg`}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={location?.startLocation?.description?.toLocaleLowerCase()}
                  >
                    <div className='cfc-location'>
                      {location?.startLocation?.description?.toLocaleLowerCase()}
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <div className='cfc-chip'>{`${location?.startLocation?.code}`}</div>
                </Grid>
                <Grid item className='fl'>
                  <ArrowForward
                    style={{
                      color: '#8996AF'
                    }}
                  />
                  {advanceMode && data?.stopViaAdvance > 0 && (
                    <>
                      <div className='cfc-chip'>
                        {data?.stopViaAdvance} {t('label_stop')}
                      </div>
                      <ArrowForward
                        style={{
                          color: '#8996AF'
                        }}
                      />
                    </>
                  )}
                </Grid>
                <Grid item>
                  {checkFlag(location?.endLocation?.flag) && (
                    <img
                      className='cfc-flag'
                      src={`${process.env.PUBLIC_URL}/static/images/countries/${location?.endLocation?.flag}.svg`}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={location?.endLocation?.description?.toLocaleLowerCase()}
                  >
                    <div className='cfc-location'>
                      {location?.endLocation?.description?.toLocaleLowerCase()}
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <div className='cfc-chip'>{`${location?.endLocation?.code}`}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <AvatarsComponent
                listAvartar={
                  data?.listAdvanceTransport ?? [data?.mainTransport]
                }
              />
            </Grid>
            <Grid item className='fl fl-c'>
              <Grid container spacing={2}>
                {data?.lessEmission && (
                  <Grid
                    item
                    style={{ marginTop: '5px' }}
                    className={`bold  cfc-best-icon fl fl-c`}
                  >
                    <FontAwesomeIcon
                      className='cfc-best  '
                      icon={['fal', 'leaf']}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Grid container spacing={2} justifyContent='space-between'>
                    <Grid
                      item
                      // style={{ width: '180px' }}
                      className={`bold fl-e ${
                        data?.lessEmission ? 'cfc-best' : 'cfc-normal'
                      }`}
                    >
                      {t('label_co2')}
                    </Grid>
                    <Grid item className={`bold fl-e`}>
                      <Tooltip
                        title={`${displayNumber(data?.co2EquivalentsTotal)} t`}
                      >
                        <div className='cfc-location'>
                          {`${displayNumber(data?.co2EquivalentsTotal)} t`}
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                borderTop: '1px solid #8996AF34',
                borderBottom: '1px solid #8996AF34'
              }}
            >
              <Grid container className='cfc-transport-label'>
                <Grid item xs={3}>
                  <div className='cfc-transport-via-mode'>
                    {t('label_tranport_via')}
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className='cfc-transport-via-mode'>
                    {t('label_tranport_upc')}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* end first line*/}
            {data?.transportViaResponses?.map((transportMode, key) => {
              const { flag, nameCountry } = findFlag(
                transportMode?.code,
                transportMode?.description,
                transportMode?.flagName
              )
              return (
                <Grid item xs={12} className='bd-b pl-28'>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      className='fl fl-c ml-28'
                      style={{
                        height: '46px',
                        marginLeft: key !== 0 ? '28px' : '0'
                      }}
                    >
                      {checkFlag(flag) && (
                        <ImageComponent
                          url={`${process.env.PUBLIC_URL}/static/images/countries/${flag}.svg`}
                        />
                      )}
                      <div className='fl fl-c pl-28'>
                        <div className='cfc-location-via fw-600'>
                          <Tooltip title={nameCountry}>
                            <div className='cfc-location'> {nameCountry}</div>
                          </Tooltip>
                        </div>
                        <div className='cfc-chip'>
                          {`${transportMode?.code ?? ''}`}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8} className='fl fl-c pl-10'>
                      <div className='bold'>
                        {`${transportMode?.transportMode ?? ''} transport`}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

            <Grid item xs={12}>
              <div
                style={{
                  height: '46px'
                }}
                className='fl fl-c pl-28 '
              >
                <div className='cfc-location-via fl fl-c fw-600'>
                  {checkFlag(location?.endLocation?.flag) && (
                    <img
                      className='cfc-flag '
                      src={`${process.env.PUBLIC_URL}/static/images/countries/${location?.endLocation?.flag}.svg`}
                    />
                  )}
                  <div className='pl-28 fw-600'>
                    {location?.endLocation?.description?.toLocaleLowerCase()}
                  </div>
                </div>
                <div className='cfc-chip'>{location?.endLocation?.code}</div>
              </div>
            </Grid>
            {/* end third line*/}
            <Grid
              item
              xs={12}
              style={{
                overflowX:
                  window.screen.width < SCREEN_VIEW ? 'auto' : 'hidden',
                overflowY: 'hidden'
              }}
            >
              <div style={{ minWidth: '1000px', position: 'relative' }}>
                {data?.emissionDetailResponses.map((element) => {
                  return (
                    <>
                      <div className='cfc-transport'>
                        <div className='cfc-transport-name'>
                          {`${element?.transportMode} transport`}
                        </div>
                        <div className='cfc-transport-mode fl'>
                          {element?.information?.map((item, key) => (
                            <div key={key} className='cfc-chip'>
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                      <ResultData
                        dataElm={element}
                        label={EMISSION_DETAIL}
                        typeShow={typeShow}
                        t={t}
                      />
                    </>
                  )
                })}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                overflowX:
                  window.screen.width < SCREEN_VIEW ? 'auto' : 'hidden',
                overflowY: 'hidden'
              }}
            >
              <div
                className='cfc-table-total'
                style={{
                  width:
                    window.screen.width < SCREEN_VIEW ? SCROLL_WIDTH : '100%'
                }}
              >
                <div className='cfc-table-total-label'>{t('label_total')}</div>
                <ResultTotal label={EMISSION_DETAIL} dataElm={data} />
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default CalculationReturnData
