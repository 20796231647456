import { Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import UserForUserGroupViewForm from './UserForUserGroupViewForm'
import UserSubscribedUserGroupCrudTable from './UserSubscribedUserGroupCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'

const { CngTabs } = components


function ViewPage({ showNotification }) {
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.USER_FOR_USER_GROUP])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userForUserGroup = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.TITLE
    )
    let userSubscribedUserGroup = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.TITLE
    )

    return {
      userForUserGroup,
      userSubscribedUserGroup
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <UserForUserGroupViewForm
							showNotification={showNotification}
							id={id}
							onFetchPreSuccess={() => {console.log("UserForUserGroup View Form")}}
						/>
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.userForUserGroup,
              tabContent: (
                <UserForUserGroupViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.userSubscribedUserGroup,
              tabContent: (
                <UserSubscribedUserGroupCrudTable
                  showNotification={showNotification}
                  userForUserGroupId={id}
                  isViewOnly={true}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage