import UserForShipperRequestFormProperties from './UserForShipperRequestFormProperties'
import UserForShipperRequestApiUrls from 'src/apiUrls/UserForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function UserForShipperRequestEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("UserForShipperRequest Edit Form")}
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <UserForShipperRequestFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={UserForShipperRequestFormProperties.formikProps}
      toClientDataFormat={UserForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={UserForShipperRequestFormProperties.toServerDataFormat}
      fetch={{
        url: UserForShipperRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: UserForShipperRequestApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default UserForShipperRequestEditForm
