import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import CompanyForShipperRequestApiUrls from 'src/apiUrls/CompanyForShipperRequestApiUrls'
import React from 'react'
import CompanyForShipperRequestKeys from 'src/constants/locale/key/CompanyForShipperRequest'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  CodeMaintenanceType
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.COMPANY_FOR_SHIPPER_REQUEST
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForShipperRequest = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: companyForShipperRequest
      }
    )
    let partyId = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.PARTY_ID
    )

    return {
      companyForShipperRequest,
      tableTitle,
      partyId
    }
  }

  const columns = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'nameOth',
      title: 'Name Other',
    },
    {
      field: 'createdDate',
      title: 'Created Date',
    },
    {
      field: 'status',
      title: 'Status',
      customLookup: () => {
        return fetchCodeMaintenanceLookup(CodeMaintenanceType.STATUS, () => {console.log("code maintenance")}, [], 'descriptionEn', 'id')
      }
    }
  ];

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: CompanyForShipperRequestApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: CompanyForShipperRequestApiUrls.EXPORT }}
              fetch={{ url: CompanyForShipperRequestApiUrls.GET_BY_PARTY_SERVICE }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
