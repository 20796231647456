import makeValidationSchema from './AccessLevelCompanyRuleMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import AccessLevelCompanyApiUrls from 'src/apiUrls/AccessLevelCompanyApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelCompanyKeys from 'src/constants/locale/key/AccessLevelCompany'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  companyRuleId: 0,
  ruleId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_COMPANY)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelCompanyRule = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.AccessLevelCompanyRule.TITLE
    )
    let companyRuleId = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.AccessLevelCompanyRule.COMPANY_RULE_ID
    )
    let ruleId = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.AccessLevelCompanyRule.RULE_ID
    )

    return {
      accessLevelCompanyRule,
      companyRuleId,
      ruleId
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.accessLevelCompanyRule} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="companyRuleId"
              type="number"
              label={translatedTextsObject.companyRuleId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ruleId}>
            <CngSelectField
              name="ruleId"
              type="number"
              label={translatedTextsObject.ruleId + " *"}
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/hdr/get`,
                  textAccessor: "name",
                  valueAccessor: "id",
                  body: {
                  }
                }
              }
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const AccessLevelCompanyRuleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AccessLevelCompanyRuleFormProperties
