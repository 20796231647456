import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants } from 'cng-web-lib'
import { useFieldArray } from 'react-hook-form'
import React from 'react';

const {
    dropzone: { CngFileUpload, HelperTextArea },
    form: { field: { CngTextField } },
    CngGridItem,
} = components
const { NotificationType } = constants

const ManagementTeamComponent = ({ fieldsTranslatedTextObject, disabled, setFieldValue, showNotification, id }) => {
    const { fields: partyManagementUserDTOList } = useFieldArray({ name: "partyManagementUserDTOList" });

    const handleRemoveManagementUser = (index) => {
        const list = partyManagementUserDTOList;
        list.splice(index, 1);
        setFieldValue("partyManagementUserDTOList", list);
    };

    const handleAddManagementUser = () => {
        const managementItem = {
            fullName: "",
            profileLink: "",
            role: "",
            profilePhoto: "",
            profilePhotoFileObj: [],
            partyId: id
        }
        const newList = [...partyManagementUserDTOList, managementItem];
        setFieldValue("partyManagementUserDTOList", newList);
    };

    const updateManagementUserProfilePhoto = (index, profileImg, fileObj) => {
        partyManagementUserDTOList[index].profilePhoto = profileImg;
        partyManagementUserDTOList[index].profilePhotoFileObj = fileObj;

        if (profileImg) {
            partyManagementUserDTOList[index].doNotShowDeletePhotoIcon = true;
        }
        setFieldValue("partyManagementUserDTOList", partyManagementUserDTOList);
    }

    return (
        <CngGridItem xs={12}>
            <Card>
                <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.managementTeamHeader} </Typography>} />

                <Divider />
                <CardContent>

                    <Grid container xs={12} sm={12} spacing={2}>
                        {partyManagementUserDTOList.map((managementUser, index) => {
                            return (
                                <CngGridItem xs={12} sm={12} key={managementUser.id}>
                                    <Grid xs={12} sm={12}>
                                        <Card className="inner-card-group">
                                            <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveManagementUser(index)}>
                                                <CloseIcon />
                                            </IconButton>
                                            <Grid container xs={12} sm={12} spacing={2}>
                                                <CngGridItem xs={6} sm={6}>
                                                    <CngTextField
                                                        name={`partyManagementUserDTOList[${index}].fullName`}
                                                        label={`${fieldsTranslatedTextObject.fullName}*`}
                                                        disabled={disabled}
                                                    />
                                                </CngGridItem>

                                                <CngGridItem xs={6} sm={6}>
                                                    <CngTextField
                                                        name={`partyManagementUserDTOList[${index}].role`}
                                                        label={fieldsTranslatedTextObject.role}
                                                        disabled={disabled}
                                                    />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={12}>
                                                    <CngTextField
                                                        name={`partyManagementUserDTOList[${index}].profileLink`}
                                                        label={fieldsTranslatedTextObject.profileLink}
                                                        placeholder={fieldsTranslatedTextObject.profileLinkPlaceholder}
                                                        disabled={disabled}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={12}>
                                                    <CngGridItem xs={12} lg={9}>
                                                        {fieldsTranslatedTextObject.currentProfilePhoto}
                                                        :
                                                    </CngGridItem>
                                                    <CngGridItem xs={12} lg={3}>
                                                        {managementUser.profilePhoto ?
                                                            <>
                                                                <img src={managementUser.profilePhoto} style={{ width: "64px", height: "64px" }} />
                                                                {!managementUser.doNotShowDeletePhotoIcon && !disabled &&
                                                                    <IconButton aria-label="remove" disabled={disabled} onClick={() => updateManagementUserProfilePhoto(index, null, null)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                }
                                                            </>
                                                            :
                                                            <p>{fieldsTranslatedTextObject.noneProfilePic}</p>
                                                        }
                                                    </CngGridItem>
                                                </CngGridItem>
                                                {!disabled &&
                                                    <CngGridItem xs={12} sm={12}>
                                                        {fieldsTranslatedTextObject.noteForFileUploadProfilePic}
                                                        <CngFileUpload
                                                            accept={['.jpg', '.jpeg', '.png']}
                                                            maxSize={1048576}
                                                            maxFiles={1}
                                                            files={partyManagementUserDTOList[index].profilePhotoFileObj}
                                                            onFileSelect={(files) => {
                                                                if (files.length > 0) {
                                                                    const file = files[0].file;
                                                                    if (file) {
                                                                        const reader = new FileReader();
                                                                        reader.readAsDataURL(file);
                                                                        reader.onload = (event) => {
                                                                            updateManagementUserProfilePhoto(index, event.target.result, files)
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            showFormFields={false}
                                                            moreActions={[
                                                                {
                                                                    action: 'remove',
                                                                    name: <div onClick={() => updateManagementUserProfilePhoto(index, null, null)}>
                                                                        <DeleteIcon />
                                                                        {fieldsTranslatedTextObject.remove}
                                                                    </div>
                                                                }
                                                            ]}
                                                            onDropRejected={(errorMessages) => {
                                                                showNotification(NotificationType.ERROR, errorMessages[0])
                                                            }}
                                                            renderHelperText={() => {
                                                                return (
                                                                    <HelperTextArea
                                                                        accept={['.jpg', '.jpeg', '.png']}
                                                                        maxFiles={1}
                                                                        maxSize={1048576}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </CngGridItem>
                                                }
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </CngGridItem>
                            );
                        })}

                    </Grid>
                </CardContent>

                <Button
                    variant="contained"
                    color="default"
                    fullWidth
                    disabled={disabled}
                    startIcon={<AddBoxIcon />}
                    className={'accordion-footer-add-component'}
                    onClick={() => handleAddManagementUser()}
                >
                    <Box style={{ textAlign: 'left' }}>
                        <Box >
                            {fieldsTranslatedTextObject.addManagementUser}
                        </Box>

                    </Box>
                </Button>

            </Card >
        </CngGridItem>
    );
}

export default ManagementTeamComponent
