import UserForUserGroupFormProperties from './UserForUserGroupFormProperties'
import UserForUserGroupApiUrls from 'src/apiUrls/UserForUserGroupApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function UserForUserGroupViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("UserForUserGroup View Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <UserForUserGroupFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={UserForUserGroupFormProperties.formikProps}
      toClientDataFormat={UserForUserGroupFormProperties.toClientDataFormat}
      toServerDataFormat={UserForUserGroupFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: UserForUserGroupApiUrls.GETBYID,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default UserForUserGroupViewForm
