import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'

const {
	card: { CngSimpleCardHeader },
	table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } } } = constants

function TablePage(props) {

	const { location: { pathname }, showNotification } = props
	const notification = useDefaultNotification(showNotification)
	const { translate } = useTranslation([
		Namespace.UI_COMPONENT, Namespace.CALISTA_PARTY_CONFIG
	])
	const translatedTextsObject = makeTranslatedTextsObject()

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {
		let calistaPartyConfig = translate(
			Namespace.CALISTA_PARTY_CONFIG,
			CalistaPartyConfigKeys.TITLE
		)

		let tableTitle = translate(
			Namespace.UI_COMPONENT,
			UiComponentKeys.Table.TITLE,
			{ nameTitleised: calistaPartyConfig }
		)

		// columns
		let partyName = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.PARTY_NAME)

		return {
			calistaPartyConfig,
			tableTitle,
			partyName
		}
	}

	//* Table Columns ----------------------------------------------------------
	const columns = [
		{ field: 'name', title: translatedTextsObject.partyName }
	];

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX --------------------------------------------------------------
		<Card>
			<CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
			<Divider />

			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CngCrudTable {...props}
							notification={notification}

							// table fetch and data details
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
							fetch={{ url: CalistaPartyConfigApiUrls.GET_BY_PARTY_SERVICE }}
							idAccessor='id'
							columns={columns}

							// view route
							viewRoute={`${pathname}/view`}

							// edit route
							editRoute={`${pathname}/edit`}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
		//* End JSX ----------------------------------------------------------------

	);

	//* End of function --------------------------------------------------------
}

export default TablePage
