import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import NBSFileApiUrls from "../../../../apiUrls/NBSFileApiUrls";
import NBSFileKeys from "../../../../constants/locale/key/NBSFile";

const {
  card: {CngSimpleCardHeader},
  table: { CngCrudTable, DateRangeFilter: CngDateRangeFilter, useDefaultNotification, useFetchCodeMaintenanceLookup, useFetchCustomLookup}
} = components
const { locale: {key: {UiComponentKeys} }, EQUAL } = constants

function TablePage(props){

  const { location: { pathname }, showNotification } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const fetchCustomLookup = useFetchCustomLookup()

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT, Namespace.NBS_FILE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject(){
    let title = translate(Namespace.NBS_FILE, NBSFileKeys.TITLE)

    let tableTitle = translate(
        Namespace.UI_COMPONENT,
        UiComponentKeys.Table.TITLE,
        { nameTitleised: title}
    )

    // columns
    let chargeCode = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.CHARGE_CODE)
    let partyId = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.PARTY_ID)
    let postingDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.POSTING_DATE)
    let createdDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.TIMESTAMP_GEN)
    let status = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.STATUS)
    let submittedFileName = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.SUBMITTED_FILE)
    let updatedDate = translate(Namespace.NBS_FILE, NBSFileKeys.FIELDS.UPDATED_DATE)

    return {
      chargeCode,
      partyId,
      postingDate,
      createdDate,
      status,
      submittedFileName,
      updatedDate
    }
  }

  //* Table Columns
  const columns = [
    {
      field: 'chargeCode',
      title: translatedTextsObject.chargeCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER,
            (error) => console.error(error),
            [{ field: 'codeType', operator: EQUAL, value: 'CAL_NBS_CHARGECODE' }]
        )
      }
    },
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
            `${NBSFileApiUrls.GET_FROM_TPR_PARTY}`,
            { },
            'content',
            'name',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: 'postingDate',
      title: translatedTextsObject.postingDate,
      type: "date",
      filterComponent: CngDateRangeFilter
    },

    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      type: "datetime",
      defaultSort: "desc",
      filterComponent: CngDateRangeFilter
    },
    {
      field: 'status',
      title: translatedTextsObject.status
    },
    {
      field: 'submittedFileName',
      title: translatedTextsObject.submittedFileName
    },
    {
      field: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      type: "datetime",
      filterComponent: CngDateRangeFilter
    },
  ];

  //* return JSX
  return (

      //* Start JSX
      <Card>
        <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/>
        <Divider/>
        <CardContent>
          <Grid container spacing = {3}>
            <Grid item xs={12}>
              <CngCrudTable {...props}
                            notification = {notification}

                  // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: NBSFileApiUrls.GET}}
                            idAccessor='id'
                            columns={columns}

                  // view route
                            viewRoute={`${pathname}/view`}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      //* End JSX
  );
  //* End of function
}

export default TablePage