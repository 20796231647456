import { Box } from '@material-ui/core'
import React from 'react'
const WordBox = ({ label, description }) => {
  return (
    <Box className='cfc-word-box'>
      <Box
        className='bold'
        style={{
          color: '#8996af',
          fontSize: '16px'
        }}
      >
        {label}
      </Box>
      <Box
        style={{
          color: '#8996af',
          fontSize: '11px'
        }}
      >
        {description}
      </Box>
    </Box>
  )
}

export default WordBox
