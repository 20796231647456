import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

export const FIELDS_MAX_LENGTH = {
	EMAIL: 255, 
	PHONE_NUM: 50,
	VISION: 200, 
	INTRO: 1000,
	MANAGEMENT_USER_NAME: 255,
	MANAGEMENT_USER_ROLE: 255, 
	MANAGEMENT_USER_LINK: 255,
	AWARD_TITLE: 255, 
	AWARD_DESCR: 1000
}
export function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let invalidEmail = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.INVALID_EMAIL)
	let exceedCharMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.EXCEED_CHARLIMIT_MSG)
	let ofMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.OF_MSG)
	let exceedCharMsgOf = (characters) => {
		return `${exceedCharMsg} ${ofMsg} ${characters}`
	}

	return Yup.object({
		partyDetails:
			Yup.object().shape({
				contactPhone: Yup.string().nullable().max(FIELDS_MAX_LENGTH.PHONE_NUM, exceedCharMsgOf(FIELDS_MAX_LENGTH.PHONE_NUM)),
				contactEmail: Yup.string().nullable().required(requiredMessage).email(invalidEmail).max(FIELDS_MAX_LENGTH.EMAIL, exceedCharMsgOf(FIELDS_MAX_LENGTH.EMAIL)),
				partyVision: Yup.string().max(FIELDS_MAX_LENGTH.VISION, exceedCharMsg).nullable(),
				partyIntroduction: Yup.string().max(FIELDS_MAX_LENGTH.INTRO, exceedCharMsg).nullable(),
			}),
		partyManagementUserDTOList: Yup.array().of(
			Yup.object().shape({
				fullName: Yup.string().max(FIELDS_MAX_LENGTH.MANAGEMENT_USER_NAME, exceedCharMsgOf(FIELDS_MAX_LENGTH.MANAGEMENT_USER_NAME)).required(requiredMessage),
				role: Yup.string().nullable().max(FIELDS_MAX_LENGTH.MANAGEMENT_USER_ROLE, exceedCharMsgOf(FIELDS_MAX_LENGTH.MANAGEMENT_USER_ROLE)),
				profileLink: Yup.string().nullable().max(FIELDS_MAX_LENGTH.MANAGEMENT_USER_LINK, exceedCharMsgOf(FIELDS_MAX_LENGTH.MANAGEMENT_USER_LINK))
			})
		),
		partyAwardDTOList: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().max(FIELDS_MAX_LENGTH.AWARD_TITLE, exceedCharMsg).required(requiredMessage),
				description: Yup.string().nullable().max(FIELDS_MAX_LENGTH.AWARD_DESCR, exceedCharMsg)
			})
		)
	})
}
