import React from 'react'
import { components } from 'cng-web-lib'

import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'
import CalistaPartyConfigFormProperties from './CalistaPartyConfig_FormProperties'

const { form: { CngViewForm } } = components

function ViewForm({ showNotification, id }) {

	return (

		//* Start JSX --------------------------------------------------------------
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			showNotification={showNotification}
			bodySection={
				<CalistaPartyConfigFormProperties.Fields
					disabled={true}
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={CalistaPartyConfigFormProperties.formikProps}
			toClientDataFormat={CalistaPartyConfigFormProperties.toClientDataFormat}
			toServerDataFormat={CalistaPartyConfigFormProperties.toServerDataFormat}
			fetch={{
				id: id,
				url: CalistaPartyConfigApiUrls.GET
			}}
		/>
		//* End JSX ----------------------------------------------------------------
	)

}

export default ViewForm
