import { Box, Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import { useFormContext } from "react-hook-form"
import { Link } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import { CNG_BASE_URL } from 'src/views/microsite/components/sections/ContactUsConstants'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { FIELDS_MAX_LENGTH, makeValidationSchema } from './CompanyMicrosite_MakeValidationSchema'
import PartyCompanyInfoTranslationText from './PartyCompanyInfoTranslationText'

const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        field: { CngTextField }
    },
    CngGridItem,
    button: { CngPrimaryButton, CngSecondaryButton },
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    micrositePath: "",
    agreeTo: false,
    isMicrositePublished: false
}
//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, loading } = props;
    const fieldsTranslatedTextObject = PartyCompanyInfoTranslationText()
    const { setFieldValue, errors } = useFormikContext()
    const { getValues } = useFormContext();
    const values = getValues();

    const handleChange = (event) => {
        if (event.target.checked) {
            errors.agreeTo = null;
        }
        setFieldValue("agreeTo", event.target.checked)
    }

    const { isMicrositePublished, micrositePath } = values;

    return (
        <>
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>
            <Grid container spacing={3}>
                <CngGridItem xs={12}><Card>
                    <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.micrositeHeader}</Typography>} />
                    <Divider />
                    <CardContent>
                        {values && !isMicrositePublished &&
                            <Grid container spacing={3}>
                                {!disabled ?
                                    <CngGridItem xs={12} sm={12}>
                                        <p>{fieldsTranslatedTextObject.micrositeTextPart1}
                                        </p><strong>{fieldsTranslatedTextObject.micrositeTextPart2}</strong>&nbsp;{fieldsTranslatedTextObject.micrositeTextPart3}
                                    </CngGridItem>
                                    :
                                    <CngGridItem xs={12} sm={12}>
                                        {fieldsTranslatedTextObject.micrositeNotActivatedText}
                                    </CngGridItem>
                                }
                                <CngGridItem xs={12} sm={12}>
                                    <CngTextField
                                        name='micrositePath'
                                        label={`${fieldsTranslatedTextObject.uniquePath}*`}
                                        placeholder={fieldsTranslatedTextObject.uniquePathPlaceholder}
                                        inputProps={{ maxLength: FIELDS_MAX_LENGTH.PATH }}
                                        helperText={`${fieldsTranslatedTextObject.maxLengthMsg} ${FIELDS_MAX_LENGTH.PATH} ${fieldsTranslatedTextObject.characters}`}
                                        disabled={disabled}
                                    />
                                    <p>{fieldsTranslatedTextObject.micrositeLookLikeText} - {`${process.env.REACT_APP_BASE_URL}${process.env.PUBLIC_URL}${pathMap.PARTY_MICROSITE_VIEW_BASE}/`}{micrositePath}</p>
                                </CngGridItem>
                                <Box pl={1}>
                                    <CngSecondaryButton style={{ marginRight: '10px' }} onClick={() =>
                                        window.open(`${CNG_BASE_URL}${pathMap.PARTY_MICROSITE_DEMO}`, '_blank', 'noopener,noreferrer')
                                    }>
                                        {fieldsTranslatedTextObject.seeDemo}

                                    </CngSecondaryButton>
                                    <a
                                        className='download'
                                        href={
                                            process.env.PUBLIC_URL +
                                            '/static/doc/CALISTA - VFMP Personalized Website.pdf'
                                        }
                                        download={'CALISTA - VFMP Personalized Website.pdf'}
                                    >
                                        <CngSecondaryButton name='downloadTemplate'>
                                            {fieldsTranslatedTextObject.getCreationGuide}
                                        </CngSecondaryButton>
                                    </a>
                                </Box>
                                <CngGridItem item xs={12} sm={12}>
                                    <FormControl
                                        error={errors.agreeTo}
                                        component='fieldset'
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="agreeTo"
                                                    onChange={handleChange}
                                                    disabled={disabled}
                                                />
                                            }
                                            label={<>{fieldsTranslatedTextObject.agreeToText} <a href="https://globaletrade.services/services-tc" target="_blank">{fieldsTranslatedTextObject.getsTC}.</a></>}
                                        />
                                        {errors.agreeTo &&
                                            <FormHelperText>{errors.agreeTo}</FormHelperText>
                                        }
                                    </FormControl>
                                </CngGridItem>
                                <CngGridItem item xs={12} sm={12}>
                                    <CngPrimaryButton
                                        disabled={disabled}
                                        type='submit'>
                                        {fieldsTranslatedTextObject.create}
                                    </CngPrimaryButton>
                                </CngGridItem>
                            </Grid>
                        }

                        {values && isMicrositePublished &&
                            <CngGridItem xs={12} sm={12}>
                                <h3>
                                    <Link to={`${pathMap.PARTY_MICROSITE_VIEW_BASE}/${micrositePath}`} target="_blank" rel='noopener noreferrer'>{fieldsTranslatedTextObject.micrositeView}</Link>
                                </h3>
                            </CngGridItem>
                        }
                    </CardContent>
                </Card></CngGridItem>
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
