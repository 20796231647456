import CompanyForShipperRequestFormProperties from './CompanyForShipperRequestFormProperties'
import CompanyForShipperRequestApiUrls from 'src/apiUrls/CompanyForShipperRequestApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function CompanyForShipperRequestAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <CompanyForShipperRequestFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={CompanyForShipperRequestFormProperties.formikProps}
      toClientDataFormat={CompanyForShipperRequestFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyForShipperRequestFormProperties.toServerDataFormat}
      create={{
        url: CompanyForShipperRequestApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default CompanyForShipperRequestAddForm
