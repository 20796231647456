import React, { useState } from 'react'

const ImageComponent = ({ url }) => {
  const [show, setShow] = useState(true)
  const onError = () => {
    setShow(false)
  }
  return show && <img className='cfc-flag' src={url} onError={onError} />
}
export default ImageComponent
