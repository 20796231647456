import makeValidationSchema from './AccessLevelUserRuleMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import AccessLevelUserApiUrls from 'src/apiUrls/AccessLevelUserApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  companyCustomRuleId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_USER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelUserRule = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.AccessLevelUserRule.TITLE
    )
    let companyCustomRuleId = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.AccessLevelUserRule.COMPANY_CUSTOM_RULE_ID
    )

    return {
      accessLevelUserRule,
      companyCustomRuleId
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.accessLevelUserRule} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyCustomRuleId}>
            <CngSelectField
              name="companyCustomRuleId"
              type="number"
              label={translatedTextsObject.companyCustomRuleId}
              disabled={disabled}
              fetch={{
                url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/hdr/getByParty`,
                textAccessor: "name",
                valueAccessor: "id"
              }}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const AccessLevelUserRuleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AccessLevelUserRuleFormProperties
