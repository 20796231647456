import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const CargoInformationComponents = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <div className='cfc-input-number'>
      <FormControl fullWidth error={errors['cargoVolume']}>
        <TextField
          error={errors['cargoVolume']}
          fullWidth
          type='text'
          label={'Cargo volume*'}
          variant='filled'
          {...register('cargoVolume', {
            required: 'Amount is required',
            pattern: {
              value: /^\d*\.?\d*$/,
              message: 'Cargo volume must be a positive value'
            },
            validate: (value) => {
              if (!value) {
                return null
              } else {
                if (value.charAt('0') == '.' || value.split('.')[1]?.length > 1)
                  return 'Incorrect number format. Please try number with up to one decimal place'
              }
            }
          })}
          InputProps={{
            maxLength: 9,
            endAdornment: <div className='cfc-chip'>TEU</div>
          }}
        />
        <div className='error'>{errors['cargoVolume']?.message}</div>
      </FormControl>
    </div>
  )
}

export default CargoInformationComponents
