import AccessLevelCompanyFormProperties from './AccessLevelCompanyFormProperties'
import AccessLevelCompanyApiUrls from 'src/apiUrls/AccessLevelCompanyApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function AccessLevelCompanyViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("Access level company view form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelCompanyFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
          companyId={id}
        />
      }
      formikProps={AccessLevelCompanyFormProperties.formikProps}
      toClientDataFormat={AccessLevelCompanyFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelCompanyFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: AccessLevelCompanyApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default AccessLevelCompanyViewForm
