import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import NBSBillingConfigKeys from "../../../../constants/locale/key/NBSBillingConfig";
import NBSBillingConfigApiUrls from "../../../../apiUrls/NBSBillingConfigApiUrls";

const {
  card: {CngSimpleCardHeader},
  table: { CngCrudTable, useDefaultNotification, DateRangeFilter: CngDateRangeFilter, useFetchCodeMaintenanceLookup, useFetchCustomLookup}
} = components
const { locale: {key: {UiComponentKeys} }, EQUAL } = constants

function TablePage(props){

  const { location: { pathname }, showNotification } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const fetchCustomLookup = useFetchCustomLookup()

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT, Namespace.NBS_BILLING_CONFIG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject(){
    let title = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.TITLE)

    let tableTitle = translate(
        Namespace.UI_COMPONENT,
        UiComponentKeys.Table.TITLE,
        { nameTitleised: title}
    )

    // columns
    let partyId = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.PARTY_ID)
    let nbsAcctId = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.ACCT_ID)
    let chargeCode = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.CHARGE_CODE)
    let firstPosting = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.FIRST_POSTING)
    let lastPosting = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.LAST_POSTING)
    let active = translate(Namespace.NBS_BILLING_CONFIG, NBSBillingConfigKeys.FIELDS.ACTIVE)

    return {
      tableTitle,
      partyId,
      nbsAcctId,
      chargeCode,
      firstPosting,
      lastPosting,
      active
    }
  }

  //* Table Columns
  const columns = [
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
            `${NBSBillingConfigApiUrls.GET_FROM_TPR_PARTY}`,
            { },
            'content',
            'name',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: 'nbsAcctId',
      title: translatedTextsObject.nbsAcctId
    },
    {
      field: 'chargeCode',
      title: translatedTextsObject.chargeCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER,
            (error) => console.error(error),
            [{ field: 'codeType', operator: EQUAL, value: 'CAL_NBS_CHARGECODE' }]
        )
      }
    },
    {
      field: 'firstPosting',
      title: translatedTextsObject.firstPosting,
      type: "date",
      filterComponent: CngDateRangeFilter
    },
    {
      field: 'lastPosting',
      title: translatedTextsObject.lastPosting,
      type: "date",
      filterComponent: CngDateRangeFilter
    },
    {
      field: 'active',
      title: translatedTextsObject.active
    },
  ];

  //* return JSX
  return (

      //* Start JSX
      <Card>
        <CngSimpleCardHeader title={translatedTextsObject.tableTitle}/>
        <Divider/>
        <CardContent>
          <Grid container spacing = {3}>
            <Grid item xs={12}>
              <CngCrudTable {...props}
                            notification = {notification}

                  // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: NBSBillingConfigApiUrls.GET}}
                            idAccessor='id'
                            columns={columns}

                  // view route
                            viewRoute={`${pathname}/view`}

                  // edit route
                            editRoute={`${pathname}/edit`}

                  // add route
                            addRoute={`${pathname}/add`}

                  // delete
                            del={{ url: NBSBillingConfigApiUrls.DELETE}}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      //* End JSX
  );
  //* End of function
}

export default TablePage