import CalculatePage from './calculatepage/CalculatePage'
import ResultPage from './resultpage/ResultPage'
import CalculateAdvanPage from '../eco-calculator/calculatepage/CalculatePageAvan'
import AuditLog from './AuditLog'

import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'
import React from 'react'
import BreadcrumbNameMap from '../../../navigations/breadcrumbs/Breadcrumb_CarbonFootprint'
import Namespace from 'src/constants/locale/Namespace'
import EcoCalculatorKeys from 'src/constants/locale/key/EcoCalculator'

function ModuleTitle() {
  const { translate } = useTranslation(Namespace.ECO_CALCULATOR)
  return translate(Namespace.ECO_CALCULATOR, EcoCalculatorKeys.MODULE_TITLE)
}

function WrappedCalculatePage(props) {
  const breadcrumbNameMap = BreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <CalculatePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
      moduleTitle={ModuleTitle()}
    />
  )
}

function WrappedResultPage(props) {
  const breadcrumbNameMap = BreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <ResultPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
      moduleTitle={ModuleTitle()}
    />
  )
}
function WrappedResultAdvanPage(props) {
  const breadcrumbNameMap = BreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <CalculateAdvanPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
      moduleTitle={ModuleTitle()}
    />
  )
}
function WrappedAuditLog(props) {
  const breadcrumbNameMap = BreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <AuditLog showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
      moduleTitle={'AUDIT LOG '}
    />
  )
}
export {
  WrappedCalculatePage as CalculatePage,
  WrappedResultPage as ResultPage,
  WrappedResultAdvanPage as CalculateAdvanPage,
  WrappedAuditLog as AuditLogPage
}
