import { Yup } from 'cng-web-lib'

function makeValidationSchema() {

  return Yup.object({
    companyCustomRuleId: Yup.number(),
  })
}

export default makeValidationSchema
