import makeValidationSchema from './TcalAsUserGroupCompanyMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TtfbTprPartyKeys from 'src/constants/locale/key/TtfbTprParty'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userGroupCompanyId: 0,
  userGroupMasterId: 0,
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  id
}) {
  const { translate } = useTranslation(Namespace.TTFB_TPR_PARTY)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalAsUserGroupCompany = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.TITLE
    )
    let userGroupCompanyId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.USER_GROUP_COMPANY_ID
    )
    let userGroupMasterId = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.USER_GROUP_MASTER_ID
    )
    let createdBy = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TTFB_TPR_PARTY,
      TtfbTprPartyKeys.TcalAsUserGroupCompany.UPDATED_DATE
    )

    return {
      tcalAsUserGroupCompany,
      userGroupCompanyId,
      userGroupMasterId,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tcalAsUserGroupCompany} />
      <CardContent>
        <Grid container spacing={3}>
          {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupCompanyId}>
            <CngTextField
              name="userGroupCompanyId"
              type="number"
              label={translatedTextsObject.userGroupCompanyId + " *"}
              disabled={disabled}
            />
          </CngGridItem>*/}
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupMasterId}>
            <CngSelectField
              name="ttfbTprPartyId"
              type="number"
              label='Party Name'
              disabled
              fetch={
                {
                  url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                  textAccessor: "name",
                  body: {
                    id: id
                  }
                }
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupMasterId}>
            <CngSelectField
              name="userGroupMasterId"
              type="number"
              label={translatedTextsObject.userGroupMasterId + " *"}
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/getById`,
                  textAccessor: "customerGroupName",
                  body: {
                    id: id
                  }
                }
              }
            />
          </CngGridItem>
          {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdBy}>
            <CngTextField
              name="createdBy"
              type="number"
              label={translatedTextsObject.createdBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.createdDate}>
            <CngTextField
              name="createdDate"
              label={translatedTextsObject.createdDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedBy}>
            <CngTextField
              name="updatedBy"
              type="number"
              label={translatedTextsObject.updatedBy}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.updatedDate}>
            <CngTextField
              name="updatedDate"
              label={translatedTextsObject.updatedDate}
              disabled={disabled}
            />
          </CngGridItem>*/}
        </Grid>
      </CardContent>
    </Card>
  )
}

const TcalAsUserGroupCompanyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TcalAsUserGroupCompanyFormProperties
