import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import AccessLevelCompanyApiUrls from 'src/apiUrls/AccessLevelCompanyApiUrls'
import React from 'react'
import AccessLevelCompanyKeys from 'src/constants/locale/key/AccessLevelCompany'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACCESS_LEVEL_COMPANY
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelCompany = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: accessLevelCompany
      }
    )
    let partyId = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.PARTY_ID
    )

    return {
      accessLevelCompany,
      tableTitle,
      partyId
    }
  }

  const columns = [
    {
      field: 'id',
      title: translatedTextsObject.partyId,
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'nameOth',
      title: 'Name Other',
    },
    {
      field: 'createdDate',
      title: 'Created Date',
    }/*,
    {
      field: 'status',
      title: 'Status',
    }*/
  ];

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: AccessLevelCompanyApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: AccessLevelCompanyApiUrls.EXPORT }}
              fetch={{ url: AccessLevelCompanyApiUrls.GET_BY_PARTY_SERVICE }}
              idAccessor='id'
              notification={notification}
              tableConfigurationCode='ACCESS_LEVEL_COMPANY'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
