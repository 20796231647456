import { components } from 'cng-web-lib'
import React from 'react'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'
import PartyCompanyInfoFormProperties from './CompanyInfo_FormProperties'

const { form: { CngViewForm } } = components

function ViewForm({ showNotification, id,  onFetchPreSuccess = () => {
    //do nothing
   }}) {

	return (
		<CngViewForm
			fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
			showNotification={showNotification}
			bodySection={
				<PartyCompanyInfoFormProperties.Fields
					disabled={true}
					showNotification={showNotification}
					id={id}
				/>
			}
			formikProps={PartyCompanyInfoFormProperties.formikProps}
			toClientDataFormat={PartyCompanyInfoFormProperties.toClientDataFormat}
			toServerDataFormat={PartyCompanyInfoFormProperties.toServerDataFormat}
			fetch={{
				id: id,
				url: CalistaPartyCompanyInfoApiUrls.GET_COMPANY_DETAILS,
				onPreSuccess: onFetchPreSuccess
			}}
		/>
	)

}

export default ViewForm
