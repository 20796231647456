import React, {useEffect, useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TcalAsUserGroupMasterKeys from 'src/constants/locale/key/TcalAsUserGroupMaster'
import { Card, CardContent, Grid } from '@material-ui/core'
import { components, constants, DataFlattener, DateTimeFormatter, useTranslation, useServices} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import CodeMaintainanceApiUrls from "../../../apiUrls/CodeMaintainanceApiUrls";

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const {
  CodeMaintenanceType,
  EQUAL
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  userGroupMasterId: 0,
  orgCode: "",
  customerGroupCode: "",
  customerGroupName: "",
  typeCode: 0,
  companyType: 0,
  subscriptionScheme: [],
  subscriptionType: "",
  dataRange: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.TCAL_AS_USER_GROUP_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { fetchRecords } = useServices();
  const [subscriptionSchemeOptions, setSubscriptionSchemeOptions] = useState()
  const [companyTypeOptions, setCompanyTypeOptions] = useState();

  useEffect(() => { updateOperations(); fetchFromCodeMaster(); }, []);

  function fetchFromCodeMaster() {

    const onSuccess = (response) => {
      let options = response.content.map((record) => ({
        text: record['descriptionEn'],
        value: record["code"]
      }));
      setCompanyTypeOptions(options);
    }

    const onError = (error) => {
      console.log("Error:[" + JSON.stringify(error) + "]")
    }

    const onComplete = () => { console.log("on complete..") }

    fetchRecords.execute(
        CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
        {
          customData: {codeMType: CodeMaintenanceType.CODE_MASTER},
          filters: [
            {
              field: 'codeType',
              operator: 'EQUAL',
              value: 'COMPANY_TYPES'
            }
          ]
        },
        onSuccess,
        onError,
        onComplete
    )

  }

  function updateOperations() {
    const onSuccess = (response) => {
      const options = response.content.map((record) => {
        return { text: record.subscriptionName, value: '' + record.id };
      });
      setSubscriptionSchemeOptions(options);
    }

    const onError = (error) => { console.log("Error on fetching subscriptionSchemeOptions:[" + JSON.stringify(error) + "]") }

    const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

    fetchRecords.execute(
        `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/subscription/hdr/get`,
        { customData: {} },
        onSuccess,
        onError,
        onComplete
    );
  }

  function makeTranslatedTextsObject() {
    let tcalAsUserGroupMaster = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.TITLE
    )
    let createdBy = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.UPDATED_DATE
    )
    let userGroupMasterId = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.USER_GROUP_MASTER_ID
    )
    let orgCode = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.ORG_CODE
    )
    let customerGroupCode = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CUSTOMER_GROUP_CODE
    )
    let customerGroupName = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CUSTOMER_GROUP_NAME
    )
    let typeCode = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.TYPE_CODE
    )
    let companyType = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.COMPANY_TYPE
    )
    let subscriptionScheme = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.SUBSCRIPTION_SCHEME
    )
    let subscriptionType = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.SUBSCRIPTION_TYPE
    )
    let dataRange = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.DATA_RANGE
    )

    return {
      tcalAsUserGroupMaster,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      userGroupMasterId,
      orgCode,
      customerGroupCode,
      customerGroupName,
      typeCode,
      companyType,
      subscriptionScheme,
      subscriptionType,
      dataRange
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tcalAsUserGroupMaster} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orgCode}>
            <CngTextField
              name="orgCode"
              label={translatedTextsObject.orgCode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customerGroupCode}>
            <CngTextField
              name="customerGroupCode"
              label={translatedTextsObject.customerGroupCode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customerGroupName}>
            <CngTextField
              name="customerGroupName"
              label={translatedTextsObject.customerGroupName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyType}>
            <CngSelectField
              name="companyType"
              label={translatedTextsObject.companyType}
              disabled={disabled}
              items={companyTypeOptions}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subscriptionScheme}>
            <CngSelectField
              name="subscriptionScheme"
              type="number"
              label={translatedTextsObject.subscriptionScheme + " *"}
              disabled={disabled}
              multiple
              items={subscriptionSchemeOptions}
              //fetch={{ url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/subscription/hdr/get`, textAccessor: "subscriptionName" }}
            />
          </CngGridItem>

          {/*set drop down*/}
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subscriptionType}>
            <CngSelectField
              name="subscriptionType"
              label={translatedTextsObject.subscriptionType}
              disabled={disabled}
              items={
                [
                  { text: 'Standard', value: 'Standard' },
                  { text: 'Premium', value: 'Premium' }
                ]
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.dataRange}>
            <CngSelectField
              name="dataRange"
              label={translatedTextsObject.dataRange}
              disabled={disabled}
              items={
                [
                  { text: '2', value: '2' },
                  { text: '3', value: '3' },
                  { text: '4', value: '4' },
                  { text: '5', value: '5' },
                  { text: '6', value: '6' }
                ]
              }
            />
          </CngGridItem>

        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
