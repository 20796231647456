import React from 'react'
import { components } from 'cng-web-lib'
import CompanyMicrositeFormProperties from './CompanyMicrosite_FormProperties'
import CalistaPartyCompanyInfoApiUrls from 'src/apiUrls/CalistaPartyCompanyInfoApiUrls'

const { form: { CngViewForm } } = components

function ViewForm({ showNotification, id, onFetchPreSuccess = () => {
  //do nothing
} }) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <CompanyMicrositeFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
          id={id}
        />
      }
      formikProps={CompanyMicrositeFormProperties.formikProps}
      toClientDataFormat={CompanyMicrositeFormProperties.toClientDataFormat}
      toServerDataFormat={CompanyMicrositeFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: CalistaPartyCompanyInfoApiUrls.GET_MICROSITE,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )

}

export default ViewForm
