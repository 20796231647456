import React from 'react'
import { BasePage as CngBasePage, useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaAdmin'
import CalistaPartyConfigTablePage from './CalistaPartyConfig_TablePage.js'
import CalistaPartyConfigViewPage from './CalistaPartyConfig_ViewPage.js'
import CalistaPartyConfigEditPage from './CalistaPartyConfig_EditPage.js'

//* Module Title ---------------------------------------------------------------
function ModuleTitle() {
	const { translate } = useTranslation(Namespace.CALISTA_PARTY_CONFIG)
	let title = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TITLE)

	return (title);
}

//* Wrapped Table Page ---------------------------------------------------------
function WrappedTablePage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyConfigTablePage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped View Page ----------------------------------------------------------
function WrappedViewPage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyConfigViewPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

//* Wrapped Edit Page ----------------------------------------------------------
function WrappedEditPage(props) {
	const breadcrumbNameMap = useBreadcrumbNameMap()
	return (
		<CngBasePage renderPage={(showSnackbar) => (
			<CalistaPartyConfigEditPage showNotification={showSnackbar} {...props} />
		)}
			extraCngBreadcrumbNameMap={breadcrumbNameMap}
			moduleTitle={ModuleTitle()}
		/>
	)
}

export {
	WrappedTablePage as TablePage,
	WrappedViewPage as ViewPage,
	WrappedEditPage as EditPage
}
