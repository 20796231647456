import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import AccessLevelMasterApiUrls from 'src/apiUrls/AccessLevelMasterApiUrls'
import React from 'react'
import AccessLevelMasterKeys from 'src/constants/locale/key/AccessLevelMaster'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACCESS_LEVEL_MASTER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelMaster = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: accessLevelMaster
      }
    )
    let moduleId = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.MODULE_ID
    )
    let menuId = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.MENU_ID
    )
    let name = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.NAME
    )
    let code = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.CODE
    )
    let parentId = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.PARENT_ID
    )
    let active = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.ACTIVE
    )

    return {
      accessLevelMaster,
      tableTitle,
      moduleId,
      menuId,
      name,
      code,
      parentId,
      active
    }
  }

  const columns = [
    {
      field: "moduleId",
      title: translatedTextsObject.moduleId,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.MODULE,
          (error) => { notification.error(error.message) }
        )
      }
    },
    {
      field: "menuId",
      title: translatedTextsObject.menuId,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.MENU,
          (error) => { notification.error(error.message) }
        )
      }
    },
    {
      field: "name",
      title: translatedTextsObject.name,
    },
    {
      field: "code",
      title: translatedTextsObject.code,
    },
    {
      field: "parentId",
      title: translatedTextsObject.parentId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-master/hdr/get`,
          { },
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "active",
      title: translatedTextsObject.active,
      type: "boolean",
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: AccessLevelMasterApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: AccessLevelMasterApiUrls.EXPORT }}
              fetch={{ url: AccessLevelMasterApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
