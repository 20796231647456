import React from 'react'
import { Box } from '@material-ui/core'

const IconBox = ({ icon, label }) => {
  const iconBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#8996af1a',
    width: '100%',
    height: '56px',
    borderRadius: '11px',
    justifyContent: 'center'
  }

  return (
    <Box style={iconBoxStyle}>
      <Box
        style={{
          color: '#8996af',
          font: 'normal normal normal 24px',
          marginRight: '10px'
        }}
      >
        {icon}
      </Box>
      <Box
        style={{
          color: '#8996af',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >
        {label}
      </Box>
    </Box>
  )
}

export default IconBox
