import React from 'react'
import { Card, CardContent, Divider, Grid } from '@material-ui/core'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaPartyConfigApiUrls from 'src/apiUrls/CalistaPartyConfigApiUrls'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo'
import { getPartyID } from 'src/store/intelligent-advisory'
const {
    card: { CngSimpleCardHeader },
    table: { CngCrudTable, useDefaultNotification }
} = components
const { locale: { key: { UiComponentKeys } }, filter: {
    EQUAL
} } = constants

function TablePage(props) {

    const { location: { pathname }, showNotification } = props
    const partyId = getPartyID();
    const notification = useDefaultNotification(showNotification)
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT, Namespace.CALISTA_PARTY_COMPANY_INFO
    ])
    const translatedTextsObject = makeTranslatedTextsObject()

    //* Translation function ---------------------------------------------------
    function makeTranslatedTextsObject() {
        let calistaPartyCompanyInfo = translate(
            Namespace.CALISTA_PARTY_COMPANY_INFO,
            CalistaPartyCompanyInfoKeys.TITLE
        )

        let tableTitle = translate(
            Namespace.UI_COMPONENT,
            UiComponentKeys.Table.TITLE,
            { nameTitleised: calistaPartyCompanyInfo }
        )

        // columns
        let partyName = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.PARTY_NAME)

        return {
            calistaPartyCompanyInfo,
            tableTitle,
            partyName
        }
    }

    //* Table Columns ----------------------------------------------------------
    const columns = [
        { field: 'name', title: translatedTextsObject.partyName }
    ];

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX --------------------------------------------------------------
        <Card>
            <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
            <Divider />

            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CngCrudTable {...props}
                            notification={notification}
                            // table fetch and data details
                            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                            fetch={{ url: CalistaPartyConfigApiUrls.GET_BY_PARTY_SERVICE }}
                            fetchFilters={[{ field: 'id', operator: EQUAL, value: partyId }]}
                            idAccessor='id'
                            columns={columns}
                            // view route
                            viewRoute={`${pathname}/view`}

                            // edit route
                            editRoute={`${pathname}/edit`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX ----------------------------------------------------------------

    );

    //* End of function --------------------------------------------------------
}

export default TablePage
