import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import WarningDialog from 'src/components/dialog/WarningDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { Awards, ContactDetails, Interests, ManagementTeam, Media, MoreAbout, Specialisations } from './company-info-components'
import { makeValidationSchema } from './CompanyInfo_MakeValidationSchema'
import PartyCompanyInfoTranslationText from './PartyCompanyInfoTranslationText'

const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        }, 
    },
    button: { CngPrimaryButton },
    CngGridItem,
} = components

//* Field default values -------------------------------------------------------
const DEFAULT_INITIAL_VALUES = Object.freeze({
    presentInIndustryList: [],
    presentInServiceList: [],
    presentInCountryList: [],
    interestedInIndustryList: [],
    interestedInServiceList: [],
    interestedInCountryList: [],
    partyDetails: {
        micrositePath: "",
        partyIntroduction: "",
        partyVision: "",
        contactPhone: "",
        contactEmail: ""
    },
    partyMediaDTOList: [],
    partyManagementUserDTOList: [],
    partyAwardDTOList: []
})

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled, showNotification, id, loading, mediaFiles, setMediaFiles } = props;
    const { submitForm, errors, setFieldValue } = useFormikContext();

    const [warningDialogOpen, setWarningDialogOpen] = useState(false)
    const fieldsTranslatedTextObject = PartyCompanyInfoTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0
    }

    const onUpdate = () => {
        submitForm()

        if (!isEmpty(errors)) {
            setWarningDialogOpen(true)
        }
    }

    useEffect(() => {
        if (loading) {
            setWarningDialogOpen(false)
        }
    }, [loading])

    return (
        <>
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>

            <Grid container spacing={3}>
                {/* Contact Details Card */}
                <ContactDetails
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled} />

                {/* Specialisations Card */}
                <Specialisations
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled} />

                {/* Interests Card */}
                <Interests
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled} />

                {/* More About Company Card */}
                <MoreAbout
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled} />

                {/* Company Media Card */}
                <Media
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    showNotification={showNotification}
                    mediaFiles={mediaFiles}
                    setMediaFiles={setMediaFiles}
                    id={id}
                />

                {/* Management Team Card */}
                <ManagementTeam
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    showNotification={showNotification}
                    id={id}
                />

                {/* Awards Card */}
                <Awards
                    fieldsTranslatedTextObject={fieldsTranslatedTextObject}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    showNotification={showNotification}
                    id={id}
                />
                <CngGridItem xs={12} sm={12}>
                    <CngPrimaryButton
                        disabled={disabled}
                        onClick={() => onUpdate()}>
                        {fieldsTranslatedTextObject.update}
                    </CngPrimaryButton>
                </CngGridItem>
            </Grid>

            <WarningDialog
                isDialogOpen={warningDialogOpen}
                closeDialog={() => setWarningDialogOpen(false)}
                okDialog={() => setWarningDialogOpen(false)}
                content={fieldsTranslatedTextObject.formError}
                okMsg={uiTranslatedTextsObject.ok}
            />
        </>

    )
    //* End of function --------------------------------------------------------
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
