import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import UserForUserGroupViewForm from './UserForUserGroupViewForm'
import UserForUserGroupAddForm from './UserForUserGroupAddForm'
import UserForUserGroupEditForm from './UserForUserGroupEditForm'
import UserSubscribedUserGroupCrudTable from './UserSubscribedUserGroupCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import UserForUserGroupKeys from 'src/constants/locale/key/UserForUserGroup'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [userForUserGroupId, setUserForUserGroupId] = useState('')
  const { translate } = useTranslation([
    Namespace.USER_FOR_USER_GROUP,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userForUserGroup = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.TITLE
    )
    let userSubscribedUserGroup = translate(
      Namespace.USER_FOR_USER_GROUP,
      UserForUserGroupKeys.UserSubscribedUserGroup.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: userForUserGroup
      }
    )

    return {
      userForUserGroup,
      userSubscribedUserGroup,
      tabLockedMessage
    }
  }

  function makeUserForUserGroupForm() {
    if (userForUserGroupId === '') {
      return (
        <UserForUserGroupAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setUserForUserGroupId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <UserForUserGroupEditForm
          showNotification={showNotification}
          id={userForUserGroupId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <UserForUserGroupViewForm
              showNotification={showNotification}
              id={userForUserGroupId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.userForUserGroup,
              tabContent: makeUserForUserGroupForm()
            },
            {
              tabName: translatedTextsObject.userSubscribedUserGroup,
              tabContent: (
                <UserSubscribedUserGroupCrudTable
                  showNotification={showNotification}
                  userForUserGroupId={userForUserGroupId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage