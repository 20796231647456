import makeValidationSchema from './CompanyShipperRequestTypeMakeValidationSchema'
import {components, useTranslation} from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForShipperRequestKeys from 'src/constants/locale/key/CompanyForShipperRequest'
import {Card, CardContent, Grid} from '@material-ui/core'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shpReqTypeId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.COMPANY_FOR_SHIPPER_REQUEST)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [companyForShipperRequestId] = useField('companyForShipperRequestId')

  function makeTranslatedTextsObject() {
    let companyShipperRequestType = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.CompanyShipperRequestType.TITLE
    )
    let shpReqTypeId = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.CompanyShipperRequestType.SHP_REQ_TYPE_ID
    )

    return {
      companyShipperRequestType,
      shpReqTypeId
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.companyShipperRequestType} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shpReqTypeId}>
            <CngSelectField
              name="shpReqTypeId"
              type="number"
              label={translatedTextsObject.shpReqTypeId + " *"}
              disabled={disabled}
              fetch={
                {
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/company-for-shipper-request/company-shipper-request-type/getCustom`,
                  textAccessor: "requestType",
                  valueAccessor: "id",
                  body: {
                    partyId: companyForShipperRequestId.value
                  }
                }
              }
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const CompanyShipperRequestTypeFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CompanyShipperRequestTypeFormProperties
