import { FormControl } from '@material-ui/core'
import React, { Fragment } from 'react'
import { useServices } from 'cng-web-lib'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormContext } from 'react-hook-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import { transformData } from '../ultls'

const SelectAutoComplete = ({
  name,
  label,
  url,
  bodyRequest,
  dataOptions,
  showChip = false
}) => {
  const [options, setOptions] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const {
    register,
    formState: { errors },
    getValues,
    setValue: setValueForm
  } = useFormContext()
  const { fetchPageableRecords } = useServices()
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    fetchPageableRecords.execute(
      url,
      {
        filters: bodyRequest,
        sorts: []
      },
      ({ content }) => {
        setLoading(false)
        const result = dataOptions(content)
        setOptions(result)
        setValue(result.find((item) => item.code == getValues(name)))
      }
    )
  }, [url])
  register(name)

  const onChange = (ev, valuePor) => {
    setValue(valuePor)
    setValueForm(name, valuePor?.code)
  }
  return (
    <Fragment>
      <FormControl error={!!errors[name]} fullWidth>
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          options={options}
          autoHighlight
          value={value}
          loading={loading}
          getOptionLabel={(option) => option?.label ?? ''}
          onChange={onChange}
          renderOption={(option) => {
            const viewOption = transformData(option?.descriptionEn)
            return (
              <div className={'cfc-view-option'}>
                <div className='fl fl-sb al-c full-w'>
                  <div>{viewOption[0]}</div>
                  <div className='font-12 text-l cfc-search-des'>
                    <div className='cfc-search-des'></div>
                    <div></div>
                  </div>
                  <div className='fl'>
                    <div className='mr-10'>{viewOption[1]}</div>
                    <div className='cfc-chip'>{option?.code}</div>
                  </div>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            return (
              <div className='cfc-view-box'>
                <TextField
                  {...params}
                  error={errors[name]}
                  variant='filled'
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <div className='fl fl-c'>
                        {loading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {value?.code && showChip && (
                          <>
                            <div className='mr-10'>{value?.rightLabel}</div>
                            <div className='cfc-chip'>{value?.code}</div>
                          </>
                        )}
                        {params.InputProps.endAdornment}
                      </div>
                    ),
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              </div>
            )
          }}
        />
        <div className='error'>{errors[name]?.message}</div>
      </FormControl>
    </Fragment>
  )
}

export default SelectAutoComplete
