import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react';

const {
    CngGridItem,
    form: { field: { CngTextField } },
} = components

const ContactDetailsComponent = ({ fieldsTranslatedTextObject, disabled }) => {
    return (
        <CngGridItem xs={12}>

        <Card>
            <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.companyContactPersonDetailsHeader}</Typography>} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={12}>
                        <CngTextField
                            name='partyDetails.contactEmail'
                            label={`${fieldsTranslatedTextObject.contactEmail}*`}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngTextField
                            name='partyDetails.contactPhone'
                            label={fieldsTranslatedTextObject.contactPhone}
                            disabled={disabled}
                        />
                    </CngGridItem>
                </Grid></CardContent>
        </Card>
    </CngGridItem>
    );
}

export default ContactDetailsComponent