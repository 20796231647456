import AccessLevelRuleFormProperties from './AccessLevelRuleFormProperties'
import AccessLevelRuleApiUrls from 'src/apiUrls/AccessLevelRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function AccessLevelRuleAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <AccessLevelRuleFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelRuleFormProperties.toServerDataFormat}
      create={{
        url: AccessLevelRuleApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default AccessLevelRuleAddForm
