import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components, constants,
  useTranslation
} from 'cng-web-lib'

import TcalPartyCarrierCodeApiUrls from 'src/apiUrls/TcalPartyCarrierCodeApiUrls'
import React from 'react'
import TcalPartyCarrierCodeKeys from 'src/constants/locale/key/TcalPartyCarrierCode'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_PARTY_CARRIER_CODE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalPartyCarrierCode = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: tcalPartyCarrierCode
      }
    )
    let createdBy = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.UPDATED_DATE
    )
    let partyCcId = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.PARTY_CC_ID
    )
    let carrierCode2 = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.CARRIER_CODE2
    )
    let carrierCode3 = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.CARRIER_CODE3
    )
    let partyId = translate(
      Namespace.TCAL_PARTY_CARRIER_CODE,
      TcalPartyCarrierCodeKeys.PARTY_ID
    )

    return {
      tcalPartyCarrierCode,
      tableTitle,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      partyCcId,
      carrierCode2,
      carrierCode3,
      partyId
    }
  }

  const columns = [
    /*{
      field: "createdBy",
      title: translatedTextsObject.createdBy,
      type: "numeric",
    },*/
    /*{
      field: "createdDate",
      title: translatedTextsObject.createdDate,
    },*/
    /*{
      field: "updatedBy",
      title: translatedTextsObject.updatedBy,
      type: "numeric",
    },*/
    /*{
      field: "updatedDate",
      title: translatedTextsObject.updatedDate,
    },*/
    /*{
      field: "partyCcId",
      title: translatedTextsObject.partyCcId,
      type: "numeric",
    },*/
    {
      field: "partyId",
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
            `${TcalPartyCarrierCodeApiUrls.GET_FILTERED_PARTIES}`,
            { },
            'content',
            'name',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: "carrierCode2",
      title: translatedTextsObject.carrierCode2,
    },
    {
      field: "carrierCode3",
      title: translatedTextsObject.carrierCode3,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: TcalPartyCarrierCodeApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: TcalPartyCarrierCodeApiUrls.EXPORT }}
              fetch={{ url: TcalPartyCarrierCodeApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
