import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react';

const {
    CngGridItem,
    form: { field: { CngCodeMasterAutocompleteField, CngCountryAutocompleteField } },
} = components

const InterestsComponent = ({ fieldsTranslatedTextObject, disabled }) => {
    return (
        <CngGridItem xs={12}><Card>
            <CardHeader title={<Typography variant="h5" className='font-bolder'>{fieldsTranslatedTextObject.companyBusinessInterestsHeader}</Typography>} />

            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <CngGridItem xs={12} sm={12}>
                        <CngCodeMasterAutocompleteField name='interestedInIndustryList'
                            label={fieldsTranslatedTextObject.interestedIndustries}
                            disabled={disabled}
                            codeType='CAL_INDUSTRY'
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngCodeMasterAutocompleteField name='interestedInServiceList'
                            label={fieldsTranslatedTextObject.interestedServices}
                            disabled={disabled}
                            codeType='CAL_SERVICE'
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12}>
                        <CngCountryAutocompleteField name='interestedInCountryList'
                            label={fieldsTranslatedTextObject.interestedCountries}
                            disabled={disabled}
                            multiple disableCloseOnSelect
                        />
                    </CngGridItem>
                </Grid></CardContent>
        </Card></CngGridItem>

    );
}

export default InterestsComponent
