import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import AccessLevelCompanyCustomRuleApiUrls from 'src/apiUrls/AccessLevelCompanyCustomRuleApiUrls'
import React from 'react'
import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelCompanyCustomRule = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: accessLevelCompanyCustomRule
      }
    )
    let name = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.NAME
    )

    return {
      accessLevelCompanyCustomRule,
      tableTitle,
      name
    }
  }

  const columns = [
    {
      field: 'name',
      title: translatedTextsObject.name,
    }
  ];

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: AccessLevelCompanyCustomRuleApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: AccessLevelCompanyCustomRuleApiUrls.EXPORT }}
              fetch={{ url: AccessLevelCompanyCustomRuleApiUrls.GET }}
              idAccessor='id'
              notification={notification}
              tableConfigurationCode='ACCESS_LEVEL_COMPANY_CUSTOM'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
