import { constants, Yup } from 'cng-web-lib'
import CalistaPartyCompanyInfoKeys from 'src/constants/locale/key/CalistaPartyCompanyInfo'
import Namespace from 'src/constants/locale/Namespace'

const {
	locale: {
		key: {
			CommonValidationMessageKeys,
		},
	},
} = constants

const websiteRegex = /^[\w-]*$/

export const FIELDS_MAX_LENGTH = {
	PATH: 50
}

export function makeValidationSchema(translate) {
	let requiredMessage = translate(Namespace.COMMON_VALIDATION_MSG, CommonValidationMessageKeys.REQUIRED)
	let alphaNumericMessage = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.MICROSITE_PATH_FORMAT_MESSAGE)
	let exceedCharMsg = translate(Namespace.CALISTA_PARTY_COMPANY_INFO, CalistaPartyCompanyInfoKeys.EXCEED_CHARLIMIT_MSG)

	return Yup.object({
		micrositePath: Yup.string().matches(websiteRegex, alphaNumericMessage).max(FIELDS_MAX_LENGTH.PATH, exceedCharMsg).required(requiredMessage),
		agreeTo: Yup.boolean().oneOf([true], (requiredMessage))
	})
}
