import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getError } from './PortSelectAutoComplete'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
const noOp = () => {
  console.log('defeault function')
}
const SelectComponent = ({
  label,
  xs,
  name = '',
  options = [{ label: '', value: '' }]
}) => {
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])

  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext()

  return (
    <Grid item xs={xs}>
      <FormControl
        className={'cfc-select'}
        fullWidth
        sx={{ m: 2, minWidth: 120 }}
      >
        <InputLabel style={{ marginLeft: '12px' }}>{label}</InputLabel>
        <Select
          displayEmpty
          error={!!getError(errors, name)}
          variant='outlined'
          fullWidth
          {...register(name, { required: t('label_location_type_valid') })}
          defaultValue={getValues(name)}
        >
          {options.map((element, key) => {
            return (
              <MenuItem
                key={key}
                value={element?.value}
                style={{
                  paddingTop: '28px',
                  paddingBottom: '9px',
                  paddingLeft: '12px'
                }}
              >
                {element?.label}
              </MenuItem>
            )
          })}
        </Select>
        {getError(errors, name) && (
          <div className='error'>{getError(errors, name)?.message}</div>
        )}
      </FormControl>
    </Grid>
  )
}

export default SelectComponent
export const SelectAdvanComponent = ({
  label,
  xs,
  name = '',
  options = [{ label: '', value: '', icon: [], rightLabel: '', chip: '' }],
  rules,
  onChangeSelect = noOp
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const [optionRender, setOptionRender] = useState([])
  useEffect(() => {
    if (options) {
      setOptionRender(options)
    }
  }, [options, name])
  return (
    <Grid item xs={xs}>
      <FormControl
        className={'cfc-select'}
        fullWidth
        sx={{ m: 2, minWidth: 120 }}
      >
        <InputLabel style={{ marginLeft: '12px' }}>{label}</InputLabel>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => {
            return (
              <Select
                variant='outlined'
                error={!!getError(errors, name)}
                fullWidth
                {...field}
                onChange={(ev) => {
                  onChangeSelect(ev.target.value)
                  field.onChange(ev.target.value)
                }}
              >
                {optionRender.map((element, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={element?.value}
                      style={{
                        paddingTop: '28px',
                        paddingBottom: '9px',
                        paddingLeft: '12px',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div> {element?.label}</div>
                      <div className='fl'>
                        {element?.rightLabel && (
                          <div>
                            <div>{element?.rightLabel}</div>
                          </div>
                        )}
                        {element?.chip && (
                          <div>
                            <div className='cfc-chip'>{element?.chip}</div>
                          </div>
                        )}
                        {element?.icon && (
                          <div>
                            {typeof element?.icon === 'string' ? (
                              <img
                                className='mr-10'
                                style={{ width: '20px' }}
                                src={element.icon}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='mr-10'
                                icon={element?.icon}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </MenuItem>
                  )
                })}
              </Select>
            )
          }}
        />

        {getError(errors, name) && (
          <div className='error'>{getError(errors, name)?.message}</div>
        )}
      </FormControl>
    </Grid>
  )
}
