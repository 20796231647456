import {
  components, constants,
  useTranslation
} from 'cng-web-lib'

import AccessLevelRulePermissionFormProperties from './AccessLevelRulePermissionFormProperties'
import AccessLevelRulePermissionApiUrls from 'src/apiUrls/AccessLevelRulePermissionApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'


const {
  table: {
    CngServerModeDialogFormTable,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup,
    useDefaultNotification
  }
} = components


function AccessLevelRulePermissionCrudTable({
  showNotification,
  accessLevelRuleId,
  isViewOnly = false
}) {

  console.log("AccessLevelRulePermissionCrudTable Rule id:[" + accessLevelRuleId + "]");
  const { translate } = useTranslation([Namespace.ACCESS_LEVEL_RULE])
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const notification = useDefaultNotification(showNotification)

  const fetchCustomLookup = useFetchCustomLookup()

  let create = { url: AccessLevelRulePermissionApiUrls.POST }
  let update = { url: AccessLevelRulePermissionApiUrls.PUT }
  let del = { url: AccessLevelRulePermissionApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let accessLevelMasterId = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.ACCESS_LEVEL_MASTER_ID
    )

    let moduleCode = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.MODULE_CODE
    )

    let menuCode = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.MENU_CODE
    )

    let accessLevelMasterChildList = translate(
      Namespace.ACCESS_LEVEL_RULE,
      AccessLevelRuleKeys.AccessLevelRulePermission.ACCESS_LEVEL_MASTER_CHILD_LIST
    )

    return {
      accessLevelMasterId,
      moduleCode,
      menuCode,
      accessLevelMasterChildList
    }
  }

  const columns = [
    {
      field: 'moduleCode',
      title: translatedTextsObject.moduleCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.MODULE,
          (error) => { notification.error(error.message) }
        )
      }
    },
    {
      field: 'menuCode',
      title: translatedTextsObject.menuCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.MENU,
          (error) => { notification.error(error.message) }
        )
      }
    },
    {
      field: 'accessLevelMasterId',
      title: translatedTextsObject.accessLevelMasterId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-master/hdr/get`,
          { },
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: 'accessLevelMasterChildCodeList',
      title: translatedTextsObject.accessLevelMasterChildList
    }
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...AccessLevelRulePermissionFormProperties,
        formikProps: {
          ...AccessLevelRulePermissionFormProperties.formikProps,
          initialValues: {
            ...AccessLevelRulePermissionFormProperties.formikProps.initialValues,
            accessLevelRuleId: accessLevelRuleId
          }
        },
        customFieldsProps: { accessLevelRuleId: accessLevelRuleId }
      }}
      toClientDataFormat={(serverData) => {
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum;
      }}
      fetch={{
        url: AccessLevelRulePermissionApiUrls.GET_BY_ID,
        body: {
          accessLevelRuleId: accessLevelRuleId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default AccessLevelRulePermissionCrudTable
