import React from 'react'

const MethodologyPage = () => {
  React.useEffect(() => {
    window.open('https://calistalogistics.com/carbon-footprint-calculator')
  }, [])
  return null
}

export default MethodologyPage
