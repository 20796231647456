import makeValidationSchema from './CompanyUserGroupMakeValidationSchema'
import {components, useServices, useTranslation} from 'cng-web-lib'
import React, {useEffect, useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import {Card, CardContent, Grid} from '@material-ui/core'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userGroupMasterId: 0,
  shipperRequestTypeList: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.COMPANY_FOR_USER_GROUP)
  const translatedTextsObject = makeTranslatedTextsObject()
  const [shipperRequestTypeOptions, setShipperRequestTypeOptions] = useState([]);
  const { fetchRecords } = useServices();

  useEffect(() => {
    updateFetchOperationForSRT()
  }, [])

  function updateFetchOperationForSRT() {
    const onSuccess = (response) => {
      const options = response.content.map((record) => {
        return { text: record.requestType, value: '' + record.id };
      });
      setShipperRequestTypeOptions(options);
      console.log("shipperRequestTypeOptions:"+ shipperRequestTypeOptions)
    }

    const onError = (error) => { console.log("Error:[" + JSON.stringify(error) + "]") }

    const onComplete = (done) => { console.log("Finally:[" + JSON.stringify(done) + "]") }

    fetchRecords.execute(
        `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/shipper-request-type/hdr/get`,
        { customData: { } },
        onSuccess,
        onError,
        onComplete
    );
  }

  function makeTranslatedTextsObject() {
    let companyUserGroup = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.TITLE
    )
    let userGroupMasterId = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.USER_GROUP_MASTER_ID
    )
    let shipperRequestTypeList = translate(
      Namespace.COMPANY_FOR_USER_GROUP,
      CompanyForUserGroupKeys.CompanyUserGroup.SHIPPER_REQUEST_TYPE_ID
    )

    return {
      companyUserGroup,
      userGroupMasterId,
      shipperRequestTypeList
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.companyUserGroup} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userGroupMasterId}>
            <CngSelectField
              name="userGroupMasterId"
              type="number"
              label={translatedTextsObject.userGroupMasterId + " *"}
              disabled={disabled}
              fetch={
                {
                  //commented url is for get available user group master list
                  // url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/getById`
                  url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/tcal-as-user-group-master/hdr/get`,
                  textAccessor: "customerGroupName"
                }
              }
            />
          </CngGridItem>

          {/*<CngGridItem xs={12} sm={6} shouldHide={false}>
            <CngSelectField
              name="shipperRequestTypeList"
              label={translatedTextsObject.shipperRequestTypeList}
              disabled={disabled}
              multiple
              items={shipperRequestTypeOptions}
            />
          </CngGridItem>*/}

        </Grid>
      </CardContent>
    </Card>
  )
}

const CompanyUserGroupFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CompanyUserGroupFormProperties
