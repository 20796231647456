import React from 'react'
import { Grid, Typography, Box, Chip, Tooltip } from '@material-ui/core'
import CalculationReturnData from './CalculationResult'

const ListPage = ({ data, history, typeShow, advanceMode, t, country }) => {
  return (
    <>
      <Grid
        item
        style={{
          height: '50px',
          border: '1px solid #8996AF34',
          borderRadius: '14px',
          marginLeft: '8px',
          width: '100%'
        }}
      >
        <Grid container spacing={2}>
          <Box
            style={{
              background: '#8996AF0F',
              mixBlendMode: 'darken',
              borderRadius: '12px 1px 0px 12px',
              opacity: '1',
              height: '50px',
              width: '214px',
              textAlign: 'center'
            }}
          >
            <Typography
              variant='h6'
              style={{
                fontWeight: 'bold',
                fontSize: '17px',
                padding: '10px 0'
              }}
            >
              {t('label_cargo')}
            </Typography>
          </Box>
          <Tooltip
            title={`Cargo volume - ${
              history.location?.state?.data?.weight ?? ''
            }  ${history.location?.state?.data?.unit ?? ''}`}
          >
            <Chip
              label={`Cargo volume - ${
                history.location?.state?.data?.weight ?? ''
              }  ${history.location?.state?.data?.unit ?? ''}`}
              style={{
                width: '177px',
                height: '33px',
                margin: 'auto 10px',
                padding: '0',
                fontWeight: 'bold',
                background: '#8996af14',
                border: '1px solid #EEEEEE99',
                borderRadius: '17px'
              }}
            />
          </Tooltip>
          <Tooltip
            title={`Tonne per TEU - ${
              history.location?.state?.data?.tonsPerTeu ?? ''
            }`}
          >
            <Chip
              label={`Tonne per TEU - ${
                history.location?.state?.data?.tonsPerTeu ?? ''
              }`}
              style={{
                width: '177px',
                height: '33px',
                margin: 'auto 10px',
                padding: '0',
                fontWeight: 'bold',
                background: '#8996af14',
                border: '1px solid #EEEEEE99',
                borderRadius: '17px'
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      {data.length !== 0 &&
        data.map((item, key) => {
          return (
            <CalculationReturnData
              key={key}
              location={history.location?.state?.locations}
              data={item}
              index={key + 1}
              typeShow={typeShow}
              advanceMode={advanceMode}
              country={country}
            />
          )
        })}
    </>
  )
}
export default ListPage
