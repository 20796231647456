import React, { useEffect } from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const TonPerTEUComponent = (props) => {
    const {
        register,
        formState: { errors }
    } = useFormContext()

    useEffect(() => {
        console.log('Eco-cal mode is advanced ', props.isAdvan)
    }, [props.isAdvan])

    const validateInput = function (e) {
        let t = e.target.value
        e.target.value = t.indexOf(".") >= 0 ? t.slice(0, t.indexOf(".") + 2) : t
    }

    return (
        <div className='cfc-input-number'>
            <FormControl fullWidth error={errors['tonsPerTeu']}>
                {props.isAdvan ?
                    <TextField
                        error={errors['tonsPerTeu']}
                        fullWidth
                        type='text'
                        label={'Tonne per TEU*'}
                        variant='filled'
                        onInput={validateInput}
                        {...register('tonsPerTeu', {
                            required: 'Value is required',
                            pattern: {
                                value: /^(([1-9]|1\d|2[0-4])(\.\d)?$)|^(25|25.0)$/,
                                message: 'Value of tonnes per TEU must be a positive value between 1 and 25'
                            }
                        })}
                    />
                    : <TextField
                        fullWidth
                        type='text'
                        label={'Tonne per TEU*'}
                        variant='filled'
                        value='10'
                        disabled
                    />}
                <div className='error'>{errors['tonsPerTeu']?.message}</div>
            </FormControl>
        </div>
    )
}

export default TonPerTEUComponent
