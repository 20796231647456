import React, { useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelMasterKeys from 'src/constants/locale/key/AccessLevelMaster'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  useTranslation
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngSelectField,
      CngSwitchField,
      CngModuleAutocompleteField,
      CngMenuAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  moduleId: "",
  menuId: "",
  name: "",
  code: "",
  parentId: 0,
  active: true,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [, , { setValue: setMenuIdValue }] = useField('menuId')
  const [moduleId, setModuleId] = useState()

  function makeTranslatedTextsObject() {
    let accessLevelMaster = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.TITLE
    )
    let moduleIdLabel = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.MODULE_ID
    )
    let menuId = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.MENU_ID
    )
    let name = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.NAME
    )
    let code = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.CODE
    )
    let parentId = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.PARENT_ID
    )
    let active = translate(
      Namespace.ACCESS_LEVEL_MASTER,
      AccessLevelMasterKeys.ACTIVE
    )

    return {
      accessLevelMaster,
      moduleIdLabel,
      menuId,
      name,
      code,
      parentId,
      active
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.accessLevelMaster} />
      <CardContent>
        <Grid container spacing={3}>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.moduleId}>
            <CngModuleAutocompleteField
              name='moduleId'
              label={translatedTextsObject.moduleIdLabel + " *"}
              disabled={disabled}
              onChange={(value) => {
                if (value !== moduleId) {
                  setMenuIdValue('')
                }
                setModuleId(value)
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.menuId}>
            <CngMenuAutocompleteField
              name='menuId'
              label={translatedTextsObject.menuId + " *"}
              key={`menu-${moduleId}`}
              disabled={disabled}
              lookupProps={{
                filters: [
                  {
                    field: 'moduleCode',
                    operator: 'EQUAL',
                    value: moduleId
                  }
                ]
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
            <CngTextField
              name="name"
              label={translatedTextsObject.name + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.code}>
            <CngTextField
              name="code"
              label={translatedTextsObject.code}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.parentId}>
            <CngSelectField
              name="parentId"
              label={translatedTextsObject.parentId}
              disabled={disabled}
              fetch={{ url: `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-master/hdr/get`, textAccessor: "name" }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.active}>
            <CngSwitchField
              name="active"
              label={translatedTextsObject.active}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  return  DataFlattener.parse(serverData);
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
