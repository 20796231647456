import { Yup, constants } from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {


  return Yup.object({
  })
}

export default makeValidationSchema
