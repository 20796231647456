import AccessLevelCompanyCustomRuleFormProperties from './AccessLevelCompanyCustomRuleFormProperties'
import AccessLevelCompanyCustomRuleApiUrls from 'src/apiUrls/AccessLevelCompanyCustomRuleApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function AccessLevelCompanyCustomRuleViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {console.log("AccessLevelCompanyCustomRule View Form")}
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <AccessLevelCompanyCustomRuleFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={AccessLevelCompanyCustomRuleFormProperties.formikProps}
      toClientDataFormat={AccessLevelCompanyCustomRuleFormProperties.toClientDataFormat}
      toServerDataFormat={AccessLevelCompanyCustomRuleFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: AccessLevelCompanyCustomRuleApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default AccessLevelCompanyCustomRuleViewForm
