import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import CompanyForShipperRequestViewForm from './CompanyForShipperRequestViewForm'
import CompanyForShipperRequestAddForm from './CompanyForShipperRequestAddForm'
import CompanyForShipperRequestEditForm from './CompanyForShipperRequestEditForm'
import CompanyShipperRequestTypeCrudTable from './CompanyShipperRequestTypeCrudTable'
import Namespace from 'src/constants/locale/Namespace'
import CompanyForShipperRequestKeys from 'src/constants/locale/key/CompanyForShipperRequest'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [companyForShipperRequestId, setCompanyForShipperRequestId] = useState('')
  const { translate } = useTranslation([
    Namespace.COMPANY_FOR_SHIPPER_REQUEST,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let companyForShipperRequest = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.TITLE
    )
    let companyShipperRequestType = translate(
      Namespace.COMPANY_FOR_SHIPPER_REQUEST,
      CompanyForShipperRequestKeys.CompanyShipperRequestType.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: companyForShipperRequest
      }
    )

    return {
      companyForShipperRequest,
      companyShipperRequestType,
      tabLockedMessage
    }
  }

  function makeCompanyForShipperRequestForm() {
    if (companyForShipperRequestId === '') {
      return (
        <CompanyForShipperRequestAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setCompanyForShipperRequestId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <CompanyForShipperRequestEditForm
          showNotification={showNotification}
          id={companyForShipperRequestId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <CompanyForShipperRequestViewForm
              showNotification={showNotification}
              id={companyForShipperRequestId}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.companyForShipperRequest,
              tabContent: makeCompanyForShipperRequestForm()
            },
            {
              tabName: translatedTextsObject.companyShipperRequestType,
              tabContent: (
                <CompanyShipperRequestTypeCrudTable
                  showNotification={showNotification}
                  companyForShipperRequestId={companyForShipperRequestId}
                />
              )
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage