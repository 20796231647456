import {
  components,
  useTranslation
} from 'cng-web-lib'

import UserShipperRequestTypeFormProperties from './UserShipperRequestTypeFormProperties'
import UserShipperRequestTypeApiUrls from 'src/apiUrls/UserShipperRequestTypeApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'


const {
  table: {
    CngServerModeDialogFormTable,
    useFetchCustomLookup,
  }
} = components


function UserShipperRequestTypeCrudTable({
  showNotification,
  userForShipperRequestId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.USER_FOR_SHIPPER_REQUEST])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCustomLookup = useFetchCustomLookup();

  let create = { url: UserShipperRequestTypeApiUrls.POST }
  let update = { url: UserShipperRequestTypeApiUrls.PUT }
  let del = { url: UserShipperRequestTypeApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let shipperRequestTypeId = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.UserShipperRequestType.SHIPPER_REQUEST_TYPE_ID
    )

    return {
      shipperRequestTypeId
    }
  }

  const columns = [
    {
      field: 'shipperRequestTypeId',
      title: translatedTextsObject.shipperRequestTypeId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/shipper-request-type/hdr/get`,
          { },
          'content',
          'requestType',
          'id',
          (error) => console.error(error)
        )
      }
    }
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...UserShipperRequestTypeFormProperties,
        formikProps: {
          ...UserShipperRequestTypeFormProperties.formikProps,
          initialValues: {
            ...UserShipperRequestTypeFormProperties.formikProps.initialValues,
            userForShipperRequestId: userForShipperRequestId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum;
      }}
      fetch={{
        url: UserShipperRequestTypeApiUrls.GET,
        body: {
          userForShipperRequestId: userForShipperRequestId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default UserShipperRequestTypeCrudTable
