import React from 'react'
import CheckBoxComponent from './check-box'

import { Box, FormControl } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
const transportModeStyle = {
  height: '82px',
  display: 'flex',
  border: '1px solid #EEEEEE',
  borderRadius: '8px',
  opacity: '1',
  justifyContent: 'space-evenly',
  alignItems: 'center'
}

const TransportMode = () => {
  const {
    formState: { errors }
  } = useFormContext()
  const { t } = useTranslation([Namespace.CARBON_FOOTPRINT_CALCULATOR])

  return (
    <FormControl fullWidth sx={{ m: 2, minWidth: 120 }}>
      <Box
        style={{ ...transportModeStyle }}
        className={errors['transportMode'] ? ' border-error' : ''}
      >
        <CheckBoxComponent
          icon={<FontAwesomeIcon className='mr-10' icon={['fal', 'ship']} />}
          label={t('label_sea_ship')}
          value='ocean'
        />
        <CheckBoxComponent
          icon={<FontAwesomeIcon className='mr-10' icon={['fal', 'plane']} />}
          label={t('label_air')}
          value='air'
        />
        <CheckBoxComponent
          icon={<FontAwesomeIcon className='mr-10' icon={['fal', 'train']} />}
          value='train'
          label={t('label_train')}
        />
        <CheckBoxComponent
          icon={<FontAwesomeIcon className='mr-10' icon={['fal', 'truck']} />}
          value='truck'
          label={t('label_truck')}
        />
        <CheckBoxComponent
          icon={
            <FontAwesomeIcon
              className='mr-10'
              icon={['fal', 'conveyor-belt']}
            />
          }
          value='barge'
          label={t('label_barge')}
        />
      </Box>
      <div style={{ color: '#f44336', fontSize: '12px' }}>
        {errors['transportMode']?.message}
      </div>
    </FormControl>
  )
}

export default TransportMode
