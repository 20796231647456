import { Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'

import AccessLevelCompanyCustomRuleViewForm from './AccessLevelCompanyCustomRuleViewForm'
import CompanyCustomRulePermissionCrudTable from './CompanyCustomRulePermissionCrudTable'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'

const { CngTabs } = components


function ViewPage({ showNotification }) {
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let accessLevelCompanyCustomRule = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.TITLE
    )
    let companyCustomRulePermission = translate(
      Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
      AccessLevelCompanyCustomRuleKeys.CompanyCustomRulePermission.TITLE
    )

    return {
      accessLevelCompanyCustomRule,
      companyCustomRulePermission
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <AccessLevelCompanyCustomRuleViewForm
							showNotification={showNotification}
							id={id}
							onFetchPreSuccess={() => {console.log("AccessLevelCompanyCustomRule View Page")}}
						/>
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.accessLevelCompanyCustomRule,
              tabContent: (
                <AccessLevelCompanyCustomRuleViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.companyCustomRulePermission,
              tabContent: (
                <CompanyCustomRulePermissionCrudTable
                  showNotification={showNotification}
                  accessLevelCompanyCustomRuleId={id}
                  isViewOnly={true}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage