export function chekkeyCode(keyCode, value) {
  switch (keyCode) {
    case 'unLocode':
      return {
        unLocode: value?.code,
        iataCode: null,
        uicCode: null
      }
    case 'iataCode':
      return {
        unLocode: null,
        iataCode: value?.code,
        uicCode: null
      }
    default:
      return {
        unLocode: null,
        iataCode: null,
        uicCode: {
          country: value?.country,
          station: value?.station
        }
      }
  }
}
export function mapIcon(n) {
  return ['45%', '24%', '17%', '14%', '10%'][n]
}
export const transformData = (data, index) => {
  if (data) {
    const result = data?.replace(/\[|\]/g, '').split(',')
    return !isNaN(index) ? result[index] : result
  } else {
    return ''
  }
}
export function generateKey() {
  return (Math.random() + 1).toString(36).substring(4)
}
