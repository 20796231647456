import React from 'react'
import {Grid} from '@material-ui/core'
import { components, useTranslation} from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import makeValidationSchema from './MakeValidationSchema'
import NBSChargeCodeMappingKeys from "../../../../constants/locale/key/NBSChargeCodeMapping";

const {
    form: { field: { CngCodeMasterAutocompleteField}},
    CngGridItem
}=components

//* Field default values
const DEFAULT_INITIAL_VALUES = Object.freeze({
    chargeCode:"",
    countType:"",
    postingFreq:""
})

const FORMIK_PROPS = {
    initialValues:{...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({disabled, shouldHideMap}){

    const { translate } = useTranslation(Namespace.NBS_CHARGE_CODE_MAPPING)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject(){

        let chargeCode = translate(Namespace.NBS_CHARGE_CODE_MAPPING, NBSChargeCodeMappingKeys.FIELDS.CHARGE_CODE)
        let countType = translate(Namespace.NBS_CHARGE_CODE_MAPPING, NBSChargeCodeMappingKeys.FIELDS.COUNT_TYPE)
        let postingFreq = translate(Namespace.NBS_CHARGE_CODE_MAPPING, NBSChargeCodeMappingKeys.FIELDS.POSTING_FREQ)

        return{
            chargeCode,
            countType,
            postingFreq
        }
    }

    //* return JSX
    return(
        <Grid container spacing={3}>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCode}>
                <CngCodeMasterAutocompleteField name="chargeCode"
                              label={translatedTextsObject.chargeCode}
                              disabled={disabled}
                              codeType='CAL_NBS_CHARGECODE'
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countType}>
                <CngCodeMasterAutocompleteField name="countType"
                              label={translatedTextsObject.countType}
                              disabled={disabled}
                              codeType='CAL_NBS_COUNT_TYPE'
                              required
                />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postingFreq}>
                <CngCodeMasterAutocompleteField name="postingFreq"
                              label={translatedTextsObject.postingFreq}
                              disabled={disabled}
                              codeType='CAL_NBS_POST_FRE'
                              required
                />
            </CngGridItem>

        </Grid>
        //* End JSX
    )
    //* End of function
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties