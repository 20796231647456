import React from 'react'
import { Grid } from '@material-ui/core'
import { SCROLL_WIDTH } from '../constants'

export const ResultData = ({ dataElm, label, typeShow, t }) => {
  return (
    <>
      {label && (
        <Grid
          className='cfc-data'
          container
          style={{
            flexWrap: 'nowrap',
            overflowY: 'hidden',
            minWidth: SCROLL_WIDTH
          }}
        >
          {!typeShow ? (
            Object.keys(label).map((keyLabel) => {
              return (
                <div className='cfc-view'>
                  <div className='cfc-label'>{label[keyLabel]}</div>
                  <div className='cfc-value'>
                    {displayNumber(dataElm[keyLabel]?.value)}
                  </div>
                </div>
              )
            })
          ) : (
            <>
              <div className='cfc-chip pos-abs wtt'>{t('label_wtt')}</div>
              <div className='cfc-chip pos-abs ttw'>{t('label_ttw')}</div>
              {Object.keys(label).map((keyLabel) => {
                return (
                  <div className='cfc-view'>
                    <div className='cfc-label'>{label[keyLabel]}</div>
                    <div className='cfc-value'>
                      {displayNumber(dataElm[keyLabel]?.wellToTank) ?? '0'}
                    </div>
                    <div className='cfc-value'>
                      {displayNumber(dataElm[keyLabel]?.tankToWheel) ?? '0'}
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </Grid>
      )}
    </>
  )
}
export const ResultTotal = ({ dataElm, label }) => {
  return (
    <>
      {label && (
        <Grid
          className='cfc-total'
          container
          style={{ flexWrap: 'nowrap' }}
          spacing={2}
        >
          {Object.keys(label).map((keyLabel) => {
            return (
              <div className='cfc-view'>
                <div className='cfc-label'>{label[keyLabel]}</div>
                <div className='cfc-value'>
                  {displayNumber(dataElm[`${keyLabel}Total`])}
                </div>
              </div>
            )
          })}
        </Grid>
      )}
    </>
  )
}
export function displayNumber(number = '') {
  if (+number) {
    return (+number).toLocaleString('en-US')
  } else {
    return 0
  }
}
