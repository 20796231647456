import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import TcalAsUserGroupMasterApiUrls from 'src/apiUrls/TcalAsUserGroupMasterApiUrls'
import React from 'react'
import TcalAsUserGroupMasterKeys from 'src/constants/locale/key/TcalAsUserGroupMaster'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  EQUAL
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TCAL_AS_USER_GROUP_MASTER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tcalAsUserGroupMaster = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: tcalAsUserGroupMaster
      }
    )
    let createdBy = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.UPDATED_DATE
    )
    let userGroupMasterId = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.USER_GROUP_MASTER_ID
    )
    let orgCode = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.ORG_CODE
    )
    let customerGroupCode = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CUSTOMER_GROUP_CODE
    )
    let customerGroupName = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.CUSTOMER_GROUP_NAME
    )
    let companyType = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.COMPANY_TYPE
    )
    let subscriptionScheme = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.SUBSCRIPTION_SCHEME
    )
    let subscriptionType = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.SUBSCRIPTION_TYPE
    )
    let dataRange = translate(
      Namespace.TCAL_AS_USER_GROUP_MASTER,
      TcalAsUserGroupMasterKeys.DATA_RANGE
    )

    return {
      tcalAsUserGroupMaster,
      tableTitle,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      userGroupMasterId,
      orgCode,
      customerGroupCode,
      customerGroupName,
      companyType,
      subscriptionScheme,
      subscriptionType,
      dataRange
    }
  }

  const columns = [
    /*{
      field: "createdBy",
      title: translatedTextsObject.createdBy,
      type: "numeric",
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "updatedBy",
      title: translatedTextsObject.updatedBy,
      type: "numeric",
    },
    {
      field: "updatedDate",
      title: translatedTextsObject.updatedDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },*/
    {
      field: "orgCode",
      title: translatedTextsObject.orgCode,
    },
    {
      field: "customerGroupCode",
      title: translatedTextsObject.customerGroupCode,
    },
    {
      field: "customerGroupName",
      title: translatedTextsObject.customerGroupName,
    },
    {
      field: "companyType",
      title: translatedTextsObject.companyType,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER,
            (error) => console.error(error),
            [{ field: 'codeType', operator: EQUAL, value: 'COMPANY_TYPES' }]
        )
      }
    },
    {
      field: "subscriptionType",
      title: translatedTextsObject.subscriptionType,
    },
    {
      field: "dataRange",
      title: translatedTextsObject.dataRange,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: TcalAsUserGroupMasterApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: TcalAsUserGroupMasterApiUrls.EXPORT }}
              fetch={{ url: TcalAsUserGroupMasterApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
