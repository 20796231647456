import makeValidationSchema from './AccessLevelUserMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'
import { Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
import AccessLevelUserApiUrls from "../../../apiUrls/AccessLevelUserApiUrls";

const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  userProfileId: 0,
  loginId: 0,
  partyId: 0,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACCESS_LEVEL_USER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userProfileId = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.USER_PROFILE_ID
    )
    let loginId = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.LOGIN_ID
    )
    let partyId = translate(
      Namespace.ACCESS_LEVEL_USER,
      AccessLevelUserKeys.PARTY_ID
    )

    return {
      userProfileId,
      loginId,
      partyId
    }
  }

	return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userProfileId}>
        <CngSelectField
          name="userProfileId"
          type="number"
          label={translatedTextsObject.userProfileId}
          disabled={disabled}
          fetch={{url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`, textAccessor: "loginId"}}
        />
      </CngGridItem>
      {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.loginId}>
        <CngTextField
          name="loginId"
          type="number"
          label={translatedTextsObject.loginId}
          disabled={disabled}
        />
      </CngGridItem>*/}
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngSelectField
          name="partyId"
          type="number"
          label={translatedTextsObject.partyId}
          disabled={disabled}
          fetch={{url: `${AccessLevelUserApiUrls.GET_FILTERED_PARTIES}`, textAccessor: "name"}}
        />
      </CngGridItem>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
