import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CalistaPartyConfigKeys from 'src/constants/locale/key/CalistaPartyConfig'
import Namespace from 'src/constants/locale/Namespace'
import { getPartyID } from 'src/store/intelligent-advisory'
import CalistaPartyCompanyInfoViewForm from 'src/views/calista-admin/party-company-info/CompanyInfoViewForm'
import CalistaPartyCompanyMicrositeViewForm from 'src/views/calista-admin/party-company-info/CompanyMicrositeViewForm'

const { CngTabs } = components

function ViewPage({ showNotification }) {

	const { id } = useParams()
	const { translate } = useTranslation([Namespace.CALISTA_PARTY_CONFIG])
	const translatedTextsObject = makeTranslatedTextsObject()
	const partyId = getPartyID()
	const history = useHistory();

	useEffect(() => {
		if (partyId != id) {
			history.push("/unauthorized")
		}
	}, [id, partyId])

	//* Translation function ---------------------------------------------------
	function makeTranslatedTextsObject() {

		const title = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TITLE)
		const companyInfoTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_COMPANY_INFO)
		const micrositeTabTitle = translate(Namespace.CALISTA_PARTY_CONFIG, CalistaPartyConfigKeys.TAB_TITLE_MICROSITE)

		return {
			title,
			companyInfoTabTitle,
			micrositeTabTitle
		}
	}


	return (
			<Grid container spacing={3}>
			<Grid item xs={12}>

				<CngTabs
					headerColor='primary'
					tabs={[
						{
							tabName: translatedTextsObject.companyInfoTabTitle,
							tabContent: (
								<>
									<CalistaPartyCompanyInfoViewForm
										showNotification={showNotification}
										id={id} />
								</>
							)
						},
						{
							tabName: translatedTextsObject.micrositeTabTitle,
							tabContent: (
								<>
									<CalistaPartyCompanyMicrositeViewForm
										showNotification={showNotification}
										id={id} />
								</>
							)
						}
					]}
				/>
			</Grid>
		</Grid>
	);
}

export default ViewPage