import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import UserForShipperRequestApiUrls from 'src/apiUrls/UserForShipperRequestApiUrls'
import React from 'react'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const fetchCustomLookup = useFetchCustomLookup();
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.USER_FOR_SHIPPER_REQUEST
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let userForShipperRequest = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: userForShipperRequest
      }
    )
    let userProfileId = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.USER_PROFILE_ID
    )
    let partyId = translate(
      Namespace.USER_FOR_SHIPPER_REQUEST,
      UserForShipperRequestKeys.PARTY_ID
    )

    return {
      userForShipperRequest,
      tableTitle,
      userProfileId,
      partyId
    }
  }

  const columns = [
    {
      field: 'userProfileId',
      title: translatedTextsObject.userProfileId,
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/get`,
            { },
            'content',
            'loginId',
            'id',
            (error) => console.error(error)
        )
      }
    },
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
            `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            { },
            'content',
            'name',
            'id',
            (error) => console.error(error)
        )
      }
    }
  ];

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
							fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: UserForShipperRequestApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: UserForShipperRequestApiUrls.EXPORT }}
              fetch={{ url: UserForShipperRequestApiUrls.GET_USER_DETAIL }}
              idAccessor='id'
              notification={notification}
              tableConfigurationCode="USER_GROUP_USER"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
