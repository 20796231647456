import {
  components,
  useTranslation
} from 'cng-web-lib'

import AccessLevelCompanyRuleFormProperties from './AccessLevelCompanyRuleFormProperties'
import AccessLevelCompanyRuleApiUrls from 'src/apiUrls/AccessLevelCompanyRuleApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AccessLevelCompanyKeys from 'src/constants/locale/key/AccessLevelCompany'


const {
  table: {
    CngServerModeDialogFormTable,
    useFetchCustomLookup
  }
} = components


function AccessLevelCompanyRuleCrudTable({
  showNotification,
  accessLevelCompanyId,
  isViewOnly = false
}) {
  const { translate } = useTranslation([Namespace.ACCESS_LEVEL_COMPANY])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCustomLookup = useFetchCustomLookup();

  let create = { url: AccessLevelCompanyRuleApiUrls.POST }
  let update = { url: AccessLevelCompanyRuleApiUrls.PUT }
  let del = { url: AccessLevelCompanyRuleApiUrls.DELETE }
  if (isViewOnly) {
    create = undefined
    update = undefined
    del = undefined
  }

  function makeTranslatedTextsObject() {
    let companyRuleId = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.AccessLevelCompanyRule.COMPANY_RULE_ID
    )
    let ruleId = translate(
      Namespace.ACCESS_LEVEL_COMPANY,
      AccessLevelCompanyKeys.AccessLevelCompanyRule.RULE_ID
    )

    return {
      companyRuleId,
      ruleId
    }
  }

  const columns = [
    {
      field: 'ruleId',
      title: translatedTextsObject.ruleId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/access-level-rule/hdr/get`,
          { },
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    }
  ]

  return (
    <CngServerModeDialogFormTable
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      disabled={isViewOnly}
      showNotification={showNotification}
      tableColumns={columns}
      formProperties={{
        ...AccessLevelCompanyRuleFormProperties,
        formikProps: {
          ...AccessLevelCompanyRuleFormProperties.formikProps,
          initialValues: {
            ...AccessLevelCompanyRuleFormProperties.formikProps.initialValues,
            accessLevelCompanyId: accessLevelCompanyId
          }
        }
      }}
      toClientDataFormat={(serverData) => {
        return serverData;
      }}
      toServerDataFormat={(localDatum) => {
        return localDatum;
      }}
      fetch={{
        url: AccessLevelCompanyRuleApiUrls.GET_By_ID,
        body: {
          accessLevelCompanyId: accessLevelCompanyId
        }
      }}
      create={create}
      update={update}
      del={del}
      idAccessor='id'
    // tableConfigurationCode=''
    />
  )
}

export default AccessLevelCompanyRuleCrudTable
